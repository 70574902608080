import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { globalBilling as globalBillingSchema } from '../../../../utils/validationSchemas';

import { UPDATE_SETTINGS } from '../../graphql';

import {
  SubmitButton,
  FormikAsyncWithSnackbar as Formik,
  FormikForm as FForm,
} from '../../../lib/components';
import { GlobalBillingFields } from '..';

function GlobalBilling({ settings }) {
  const {
    billing: { manualBilling },
    elbCard: { defaultExpirationTime, billingAccount },
  } = settings;

  const initialValues = {
    manualBilling,
  };
  const [updateSettings] = useMutation(UPDATE_SETTINGS);
  const validationSchema = globalBillingSchema;
  async function handleSubmit(values) {
    await updateSettings({
      variables: {
        data: {
          elbCard: {
            defaultExpirationTime,
            billingAccount: billingAccount ? billingAccount.id : null,
          },
          billing: { ...values },
        },
      },
    });
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          Global Billing
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          successText="Billing settings have been saved!"
        >
          <FForm>
            <GlobalBillingFields />

            <Grid container direction="row" justify="flex-end" alignItems="flex-start">
              <Grid item>
                <SubmitButton>Save Changes</SubmitButton>
              </Grid>
            </Grid>
          </FForm>
        </Formik>
      </CardContent>
    </Card>
  );
}

GlobalBilling.propTypes = {
  settings: Types.settings.isRequired,
};

export default GlobalBilling;
