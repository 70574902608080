import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { EXPORT_TABLE } from '../graphql';

function transformColumnsAndDataIntoTable(columns, data) {
  const header = columns.map(({ label }) => ({ value: label }));
  const rows = data.map(({ data: row }) => row.map(cell => ({ value: toString(cell) })));
  return [header, ...rows];
}

export default function useExportTable() {
  const [mutate, result] = useMutation(EXPORT_TABLE);

  const exportTable = useCallback(
    (spreadsheetName, columns, data) =>
      mutate({
        variables: { spreadsheetName, table: transformColumnsAndDataIntoTable(columns, data) },
      }),
    [mutate],
  );

  return [exportTable, result];
}
