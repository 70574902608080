import React from 'react';
import { Grid } from '@material-ui/core';

import { login as loginSchema } from '../../../../utils/validationSchemas';

import { useLogin } from '../../hooks';

import {
  FormikAsync as Formik,
  FormikTextField as FTextField,
  SubmitButton,
  FormikFormWithError as FFormWithError,
} from '../../../lib/components';

const initialValues = {
  email: '',
  password: '',
};

function LoginForm() {
  const [login] = useLogin();

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={login}>
      <FFormWithError>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
          <Grid item>
            <FTextField
              name="email"
              type="email"
              required
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="none"
            />
          </Grid>

          <Grid item>
            <FTextField
              name="password"
              type="password"
              required
              label="Password"
              variant="outlined"
              fullWidth
              margin="none"
            />
          </Grid>

          <Grid item>
            <SubmitButton fullWidth variant="contained" color="primary" type="submit">
              Log In
            </SubmitButton>
          </Grid>
        </Grid>
      </FFormWithError>
    </Formik>
  );
}

export default LoginForm;
