import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { debounceSearchRender } from 'mui-datatables';

import * as TableTypes from '../../propTypes';
import {
  useServerSideState,
  useServerSideColumns,
  useData,
  useServerSideFilters,
} from '../../hooks';

import MuiDataTable from '../MuiDatatable';

/**
 * This will also override the hidden cell title's style,
 * but we don't use that so it's okay for now.
 * @see https://github.com/gregnb/mui-datatables/issues/992
 */
const useStyles = makeStyles(() => ({
  longText: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function TableServerSide({ state, setters, options, query, sortModel, onSortModelChange }) {
  const { page, rowsPerPage, search, columns: columnsProp, displayedColumns } = state;
  const { setPage } = setters;
  const { sortOrder } = options;

  const classes = useStyles();

  let defaultSortOrder;
  switch (state.title) {
    case 'Events List': {
      defaultSortOrder = { name: 'from', direction: 'desc' };
      break;
    }
    case 'Users List': {
      defaultSortOrder = { name: 'account.balance', direction: 'desc' };
      break;
    }
    // case 'Orders List': {
    //   defaultSortOrder = { name: 'createdAt', direction: 'desc' };
    //   break;
    // }
    // case 'Transactions List': {
    //   defaultSortOrder = { name: 'createdAt', direction: 'desc' };
    //   break;
    // }
    default:
      defaultSortOrder = sortModel || sortOrder;
  }

  let defaultFilters;
  switch (state.title) {
    // case 'Orders List': {
    //   defaultFilters = [
    //     {
    //       field: 'status',
    //       type: 'Select',
    //       values: ['Completed'],
    //     },
    //   ];
    //   break;
    // }
    case 'Users List': {
      defaultFilters = [{ field: 'isActive', type: 'Boolean', values: ['true'] }];
      break;
    }
    default:
      defaultFilters = [];
  }

  const [sort, setSort] = useState(sortModel || sortOrder || defaultSortOrder);
  const [filtersConfirmed, setFiltersConfirmed] = useState(false);
  const [filters, addFilter, resetFilters, removeFilter] = useServerSideFilters(defaultFilters);
  const handleFilterSubmit = useCallback(
    applyFilters => {
      applyFilters();
      //setFilters(getFilters(filterList, columnsProp));
      setFiltersConfirmed(true);
    },
    [setFiltersConfirmed],
  );

  const serverSideState = useServerSideState(
    query,
    page,
    sort,
    search,
    filters,
    filtersConfirmed,
    setFiltersConfirmed,
    setPage,
    rowsPerPage,
  );

  const { data: dataProp } = serverSideState;
  const [data, normalizedData] = useData(dataProp);

  const columns = useServerSideColumns(
    columnsProp,
    data,
    normalizedData,
    classes,
    sort,
    displayedColumns,
  );

  const mergedState = useMemo(
    () => ({
      ...state,
      columns,
      ...serverSideState,
    }),
    [serverSideState, columns, state],
  );

  const mergedOptions = useMemo(
    () => ({
      ...options,
      customSearchRender: debounceSearchRender(100),
      confirmFilters: true,

      // Calling the applyNewFilters parameter applies the selected filters to the table
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
              Apply Filters
            </Button>
          </div>
        );
      },

      onFilterChange: (column, filterList, type, idx) => {
        if (type === 'chip') {
          resetFilters();
          const newFilters = () => filterList.fill([]);
          handleFilterSubmit(newFilters);
        }

        if (filterList.every(f => f.length === 0)) {
          resetFilters();
        }

        const filterValues = filterList[idx];
        if (column && filterValues.length > 0) {
          if (column.name === 'isInactive') {
            removeFilter({
              field: column.name,
            });
            if (filterValues.every(value => value === true)) {
              addFilter({
                field: 'isActive',
                type: column.type || (column.filterType === 'dropdown' ? 'Select' : 'Text'),
                values: ['false'],
              });
            }
          } else {
            addFilter({
              field: column.name,
              type: column.type || (column.filterType === 'dropdown' ? 'Select' : 'Text'),
              values: filterValues.map(f => f?.toString()),
            });
          }
        }
      },
      onColumnSortChange: (name, direction) => {
        setSort({ name, direction });
        onSortModelChange && onSortModelChange({ field: name, direction });
      },
      count: serverSideState.count,
    }),
    [
      options,
      serverSideState.count,
      handleFilterSubmit,
      addFilter,
      resetFilters,
      removeFilter,
      onSortModelChange,
    ],
  );

  return <MuiDataTable state={mergedState} setters={setters} options={mergedOptions} />;
}

TableServerSide.propTypes = {
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setters: PropTypes.objectOf(PropTypes.func).isRequired,
  query: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  options: TableTypes.muiDataTableOptions.isRequired,
};

export default TableServerSide;
