import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { wrapFormHandler } from '../../../../utils/helpers';

import {
  SubmitButton,
  FormikAsyncWithSnackbar as Formik,
  FormikForm as FForm,
} from '../../../lib/components';
import { Dialog } from '..';

function DialogForm({
  open,
  onClose,
  formikProps: { onSuccess, ...formikProps },
  fields,
  title = 'Title',
  closeOnSuccess = true,
  subtitle = '',
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  snackbarProps = {},
  ...rest
}) {
  const handleSuccess = closeOnSuccess ? wrapFormHandler(onSuccess, onClose) : onSuccess;
  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <Formik {...snackbarProps} {...formikProps} onSuccess={handleSuccess}>
        <FForm>
          <DialogContent>
            {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
            {fields}
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose} color="primary">
              {cancelButtonText}
            </Button>
            <SubmitButton>{submitButtonText}</SubmitButton>
          </DialogActions>
        </FForm>
      </Formik>
    </Dialog>
  );
}

/* eslint-disable react/forbid-prop-types */
DialogForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formikProps: Types.formik.isRequired,
  fields: PropTypes.element.isRequired,
  title: PropTypes.string,
  closeOnSuccess: PropTypes.bool,
  subtitle: PropTypes.string,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  snackbarProps: Types.snackbar,
};

export default DialogForm;
