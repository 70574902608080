import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dashboard: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(1),
  },
}));

function Dashboard() {
  const classes = useStyles();

  return <div className={classes.dashboard} />;
}

export default Dashboard;
