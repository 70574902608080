import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { account as accountSchema } from '../../../../utils/validationSchemas';

import { useCreateAccount } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { AccountFormFields } from '..';
import { AccountType } from '../AccountFormFields/AccountFormFields';

function CreateAccount({ refetchQueries }) {
  const dialogProps = {
    title: 'Add Account',
    submitButtonText: 'Add Account',
    fields: <AccountFormFields />,
  };

  const [createAccount] = useCreateAccount(refetchQueries ? { refetchQueries } : undefined);

  const initialValues = {
    name: '',
    type: AccountType.Cash,
  };
  const formikProps = {
    initialValues,
    validationSchema: accountSchema,
    onSubmit: async values => createAccount(values),
  };

  const snackbarProps = {
    successText: 'Account was successfully created',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Add account"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

CreateAccount.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateAccount;
