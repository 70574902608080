import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import * as Types from '../../../../utils/propTypes';
import { formatDate } from '../../../../utils/formatters';
import { differenceInDays, isAfter, isSameDay } from 'date-fns';
import ProductStatus from '../../../elb-cards/components/ELBCardsTable/ProductStatus';

function AutocompleteActiveOnlineCardInfo({ customer }) {
  // Find active online ELB card (if there is such card)
  const activeOnlineCard = useMemo(() => {
    const customerElbCards = customer?.elbCards || [];
    const activeOnlineCards = customerElbCards.filter(card => {
      const { expirationDate, status, cardNumber, onlineCardNumber } = card;
      // Checking for expiration date
      if (!expirationDate) {
        return false;
      }
      // Checking for isActive based on existing expiration date
      const today = new Date();
      const isActive = isAfter(new Date(expirationDate), today) || isSameDay(new Date(expirationDate), today);
      if (!isActive) {
        return false;
      }
      // Checking for isActiveStatus based on card status
      const isActiveStatus = status === ProductStatus.Active;
      if (!isActiveStatus) {
        return false;
      }
      // Checking for online cards only
      const isOnline = cardNumber === "ELB" && onlineCardNumber?.length > 3;
      if (!isOnline) {
        return false;
      }

      // Everythins is OK, it is an active online card
      return true;
    });

    if (activeOnlineCards?.length > 1) {
      console.warn(`There are too many active online cards! Return first card from these:`, activeOnlineCards);
    }
    console.log('activeOnlineCards[0]', activeOnlineCards[0]);
    return activeOnlineCards[0];
  }, [customer])

  // Calculate days until expiration from expiration date
  const daysUntilExpiration = useMemo(() => {
    if (!activeOnlineCard) {
      return null;
    }
    return differenceInDays(new Date(activeOnlineCard.expirationDate), new Date());
  }, [activeOnlineCard]);

  if (!activeOnlineCard) {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid container item style={{ marginTop: 8 }}>
          <Grid item xs={4}>
            <Typography>No active online ELB card</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  else {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
        <Grid container item style={{ marginTop: 8 }}>
          <Grid item xs={12}>
            <Typography>Active online ELB card</Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item justify="flex-start" alignItems="center" xs={4}>
            <Typography>Card number:</Typography>
          </Grid>
          <Grid container item justify="flex-start" alignItems="center" xs={4}>
            <Typography>
              {activeOnlineCard.onlineCardNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item justify="flex-start" alignItems="center" xs={4}>
            <Typography>Expiration Date:</Typography>
          </Grid>
          <Grid container item justify="flex-start" alignItems="center" xs={4}>
            <Typography>
              {formatDate(activeOnlineCard.expirationDate)}
              {daysUntilExpiration && ` (${daysUntilExpiration} days until expiration)`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AutocompleteActiveOnlineCardInfo.propTypes = {
  customer: Types.customer
};

export default AutocompleteActiveOnlineCardInfo;
