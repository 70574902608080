import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './modules/lib/components';
import { routeItems } from './utils/routes';

function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      {routeItems.map(({ key, isProtected, privilege, exact, path, layout, component }) => (
        <RouteWithLayout
          key={key}
          isProtected={isProtected}
          privilege={privilege}
          path={path}
          exact={exact}
          layout={layout}
          component={component}
        />
      ))}
      <Redirect to="/404b" />
    </Switch>
  );
}

export default Routes;
