import React from 'react';
import * as PropTypes from 'prop-types';
import { Field } from 'formik';
import { get } from 'lodash';

function FormikFieldWithError({ name, children, ...rest }) {
  return (
    <Field name={name} validate={rest?.validate}>
      {({ field, form }) => {
        const { touched: touchedFields, errors } = form;

        const touched = get(touchedFields, field.name);
        const error = get(errors, field.name);

        const hasError = touched && error !== undefined;
        const errorMessage = touched ? error : '';

        return children({ field, form, hasError, errorMessage, ...rest });
      }}
    </Field>
  );
}

FormikFieldWithError.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default FormikFieldWithError;
