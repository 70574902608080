import React from 'react';
import * as PropTypes from 'prop-types';

import { useActivateAccount } from '../../hooks';

import { ActionButton } from '../../../common/components';

function ActivateAccount({ id }) {
  const [activate, result] = useActivateAccount(id);
  return (
    <ActionButton action={activate} result={result} successMessage="Account has been activated!">
      Activate
    </ActionButton>
  );
}

ActivateAccount.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ActivateAccount;
