import React from 'react';

import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';

function ReadElbCard({ cardNumber, setCardNumber, mutate }) {
  const handleSubmit = e => {
    e.preventDefault();
    mutate(cardNumber);
  };

  console.log('cardNumber: ', cardNumber);

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true,
        }}
        name="cardNumber"
        label="ELB Card Number"
        type="text"
        value={cardNumber}
        onChange={e => setCardNumber(e.target.value)}
      />

      <Button variant="contained" color="primary" type="submit">
        Read ELB Card
      </Button>
    </form>
  );
}

export default ReadElbCard;
