import { useEffect, useState, useCallback } from 'react';

import { transformApiError } from '../../../apollo/errors';
import useSnackbar from './useSnackbar';

export default function useActionWithSnackbar(action, successMessage, errorMessage) {
  const [called, setCalled] = useState(false);
  function call() {
    setCalled(true);
  }

  const { enqueueSnackbar } = useSnackbar();

  const handleAction = useCallback(async () => {
    try {
      await action();
      enqueueSnackbar(successMessage, 'success');
    } catch (error) {
      const { global, fields } = transformApiError(error);
      const messages = [errorMessage, global, ...Object.values(fields || {})].filter(m => m);
      messages.forEach(message => enqueueSnackbar(message, 'error'));
    }
  }, [action, successMessage, errorMessage, enqueueSnackbar]);

  useEffect(() => {
    if (called) {
      handleAction();
      setCalled(false);
    }
  }, [called, handleAction]);

  return call;
}
