import React from 'react';

import routes from '../../../../utils/routes';
import { Table, CollectionTable } from '../../../common/components';
import { GET_BILLING_ACCOUNTS } from '../../graphql';

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      renderCell: (value, { id }) => routes.billingAccount.createLink(id, value),
      filterType: 'textField',
    },
  },
  {
    name: 'publicKey',
    label: 'Public Key',
    type: 'longText',
    options: {
      filter: false,
    },
  },
  {
    name: 'privateKey',
    label: 'Private Key',
    type: 'longText',
    options: {
      filter: false,
    },
  },
  {
    name: 'createdAt',
    label: 'Created at',
    type: 'dateTime',
  },
  {
    name: 'createdBy.fullName',
    label: 'Created by',
    options: {
      renderCell: (value, { createdBy }) => routes.user.createLink(createdBy, value),
      filterType: 'textField',
    },
  },
  {
    name: 'active',
    label: 'Status',
    options: {
      renderCell: value => (value ? 'Active' : 'Inactive'),
      // filterList: ['Active'],
      display: false,
      filter: false,
    },
  },
];

function BillingAccountsTable() {
  return (
    <CollectionTable>
      <Table
        title="Billing Account List"
        collectionName="billingAccounts"
        query={GET_BILLING_ACCOUNTS}
        columns={columns}
      />
    </CollectionTable>
  );
}

export default BillingAccountsTable;
