import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useSnackbar } from '../../../lib/hooks';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../../products.constants';

function ProductImageUploader({ onImagePreviewLoaded, setBase64FileToSave, onImageUploaded }) {
  // Store the selected file as base64 for preview and upload
  const [base64File, setBase64File] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFileSelect = event => {
    if (event.target.files?.[0]) {
      const file = event.target.files[0];
      // Convert the selected file to base64 for preview and API upload
      const reader = new FileReader();
      // Store loaded image in local state
      reader.addEventListener('load', () => {
        // Local state for uploading
        setBase64File(reader.result);
        setBase64FileToSave(reader.result);
        // Parent callback for previewing
        onImagePreviewLoaded(reader.result);
      });
      reader.addEventListener('error', () => {
        console.error('Cannot convert selected file to base64!');
        enqueueSnackbar('Cannot upload image!', 'error', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      });
      // Read the following file
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async () => {
    try {
      // Upload the image to the Products API
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const imageUploadResponse = await PRODUCTS_API_AXIOS_INSTANCE.post(
        `/${PRODUCTS_API_ENDPOINTS.UPLOAD_PRODUCT_IMAGE_ENDPOINT}`,
        {
          base64Image: base64File,
        },
        { headers: { Authorization: token } },
      );
      // Handle image upload response
      if (imageUploadResponse?.data?.url) {
        onImageUploaded(imageUploadResponse.data.url);
        enqueueSnackbar('Image uploaded!', 'success', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    } catch (error) {
      console.error('uploadImage error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box marginBottom={'8px'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        <input
          accept="image/*"
          id="product-image-file-input"
          multiple={false}
          type="file"
          onChange={e => onFileSelect(e)}
          style={{ display: 'none' }}
        />
        <label htmlFor="product-image-file-input">
          <Button variant="contained" color="primary" component="span" disabled={isLoading}>
            Select image{''}
            {isLoading && <CircularProgress size={18} style={{ marginLeft: '8px' }} />}
          </Button>
        </label>
      </Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => uploadImage()}
            disabled={isLoading || !base64File}
          >
            Upload image{''}
            {isLoading && <CircularProgress size={18} style={{ marginLeft: '8px' }} />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ProductImageUploader;
