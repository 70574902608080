import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Content } from '../../../../common/components';

import EventCheckingToolbar from '../EventCheckingToolbar/EventCheckingToolbar';
import { isAfter } from 'date-fns';
import { useCreateNewCardReading } from '../hooks/useCreateNewCardReading';
import EventCheckingInfoCard from '../EventCheckingInfoCard/EventCheckingInfoCard';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useDebounce } from '../../../../common/hooks';

function EventChecking() {
  const [cardNumber, setCardNumber] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [approvedIcon, setApprovedIcon] = useState(false);
  const [lastApiRequestTimestamp, setLastApiRequestTimestamp] = useState(null);
  const [lastInputChangeTimestamp, setInputChangeTimestamp] = useState(null);
  const debouncedCardNumber = useDebounce(cardNumber, 500);
  const { isError, isLoading, isSuccess, data, mutate } = useCreateNewCardReading(
    debouncedCardNumber,
  );

  useEffect(() => {
    setInputChangeTimestamp(new Date());
  }, [cardNumber]);

  useEffect(() => {
    if (isSuccess && data) {
      setLastApiRequestTimestamp(new Date());
    }
  }, [isSuccess, data]);

  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get('eventId');

  const isApiResponseOutdated = isAfter(lastInputChangeTimestamp, lastApiRequestTimestamp);

  return (
    <Content
      toolbar={
        <>
          <EventCheckingToolbar
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            mutate={mutate}
            debouncedCardNumber={debouncedCardNumber}
            eventId={eventId}
          />
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isSuccess &&
            (data?.length > 0
              ? data.map((cardReading, index) => (
                  <EventCheckingInfoCard
                    key={index}
                    data={cardReading}
                    isError={isError}
                    isLoading={isLoading}
                    className={index === 0 ? 'specialClass' : 'none'}
                    showButtons={showButtons}
                    setShowButtons={setShowButtons}
                    approvedClass={
                      !showButtons && approvedIcon
                        ? 'approved'
                        : !showButtons && !approvedIcon
                        ? 'declined'
                        : ''
                    }
                    setApprovedIcon={setApprovedIcon}
                  />
                ))
              : !isApiResponseOutdated && (
                  <Alert severity="info">
                    <AlertTitle>ELB Card Reading</AlertTitle>
                    <p style={{ fontSize: '16px' }}>
                      There is <strong>no active</strong> ELB card with the search text:
                      <strong>{cardNumber}</strong>!
                    </p>
                  </Alert>
                ))}
        </Grid>
      </Grid>
    </Content>
  );
}

export default EventChecking;
