import React from 'react';

import { Collection } from '../../../common/components';
import { OrdersToolbar, OrdersTable } from '..';

function Orders() {
  return <Collection toolbar={<OrdersToolbar />} table={<OrdersTable />} />;
}

export default Orders;
