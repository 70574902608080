import React from 'react';
import { Grid } from '@material-ui/core';

import { useGetOpenOrder } from '../../hooks';

import { FillerSpinner } from '../../../lib/components';
import { Content } from '../../../common/components';
import { OrderToolbar, OrderTickets, OrderELBCards, AddBillingCustomer, OrderTimer } from '..';

function OpenOrder() {
  const { loading, data } = useGetOpenOrder();

  if (loading) return <FillerSpinner />;

  const { getOpenOrder: order } = data;
  const { billingCustomer, elbCards, tickets, expiresAt } = order;

  return (
    <Content toolbar={<OrderToolbar order={order} />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OrderTimer expiresAt={expiresAt} />
        </Grid>
        <Grid item xs={12}>
          <AddBillingCustomer customer={billingCustomer} />
        </Grid>
        <Grid item xs={12}>
          <OrderTickets tickets={tickets.filter(t => t.status === 'Pending')} />
        </Grid>
        <Grid item xs={12}>
          <OrderELBCards elbCards={elbCards.filter(e => e.status === 'Pending')} />
        </Grid>
      </Grid>
    </Content>
  );
}

export default OpenOrder;
