import React from 'react';
import PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import { useActionWithSnackbar } from '../../../lib/hooks';
import { ButtonWithLoading } from '../../../lib/components';

function ActionButton({
  action,
  result,
  successMessage = 'Success!',
  errorMessage = 'Something has went wrong.',
  children = 'Action',
  ...rest
}) {
  const { loading } = result;

  const handleAction = useActionWithSnackbar(action, successMessage, errorMessage);

  return (
    <ButtonWithLoading loading={loading} onClick={handleAction} {...rest}>
      {children}
    </ButtonWithLoading>
  );
}

ActionButton.propTypes = {
  action: PropTypes.func.isRequired,
  result: Types.result.isRequired,
  successMessage: PropTypes.node,
  errorMessage: PropTypes.node,
  children: PropTypes.node,
};

export default ActionButton;
