import React from 'react';
import * as PropTypes from 'prop-types';

import { useInactivateTicket } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function InactivateTicket({ id, number }) {
  const [inactivateTicket, result] = useInactivateTicket(id);
  return (
    <ActionButtonWithConfirmation
      action={inactivateTicket}
      result={result}
      title={`Are you sure you want to inactivate ticket "${number}"?`}
      successMessage="Ticket has been inactivated!"
      buttonText="Inactivate Ticket"
      buttonProps={{
        variant: 'contained',
        color: 'primary',
      }}
    />
  );
}

InactivateTicket.propTypes = {
  id: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};

export default InactivateTicket;
