import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { MutateBillingAccount } from '..';

function CreateBillingAccount({ refetchQueries }) {
  return <MutateBillingAccount refetchQueries={refetchQueries} />;
}

CreateBillingAccount.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateBillingAccount;
