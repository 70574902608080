import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, useState } from 'react';
import { useClientSideColumns, useData, useClientSideFilters } from '../../hooks';
import * as TableTypes from '../../propTypes';
import MuiDataTable from '../MuiDatatable';
import { Button } from '@material-ui/core';

/**
 * This will also override the hidden cell title's style,
 * but we don't use that so it's okay for now.
 * @see https://github.com/gregnb/mui-datatables/issues/992
 */
const useStyles = makeStyles(() => ({
  longText: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

function TableClientSide({ state, options, setters }) {
  const { columns: columnsProp, data: dataProp, sort, displayedColumns } = state;

  const classes = useStyles();

  const [, setFiltersConfirmed] = useState(false);
  const [filters, addFilter, resetFilter] = useClientSideFilters(columnsProp);
  const handleFilterSubmit = useCallback(
    applyFilters => {
      applyFilters();
      //setFilters(getFilters(filterList, columnsProp));
      setFiltersConfirmed(true);
    },
    [setFiltersConfirmed],
  );
  const onFilterChange = useCallback(
    (columnName, filterList) => {
      if (columnName) {
        const newFilter = filterList[columnsProp.findIndex(({ name }) => columnName === name)];
        return addFilter(columnName, newFilter);
      }
      return resetFilter();
    },
    [addFilter, columnsProp, resetFilter],
  );

  const [data, normalizedData] = useData(dataProp);
  const columns = useClientSideColumns(
    columnsProp,
    data,
    normalizedData,
    classes,
    sort,
    filters,
    displayedColumns,
  );

  const mergedState = useMemo(
    () => ({
      ...state,
      columns,
    }),
    [columns, state],
  );

  const mergedOptions = useMemo(
    () => ({
      ...options,
      onFilterChange,
      confirmFilters: true,

      // Calling the applyNewFilters parameter applies the selected filters to the table
      customFilterDialogFooter: (_, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
              Apply Filters
            </Button>
          </div>
        );
      },
    }),
    [options, onFilterChange, handleFilterSubmit],
  );

  return <MuiDataTable state={mergedState} options={mergedOptions} setters={setters} />;
}

TableClientSide.propTypes = {
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setters: PropTypes.objectOf(PropTypes.func).isRequired,
  options: TableTypes.muiDataTableOptions.isRequired,
};

export default TableClientSide;
