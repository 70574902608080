import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FormikForm from '../FormikForm';
import FormikFormError from '../FormikFormError';

const useStyles = makeStyles(theme => ({
  error: {
    marginTop: ({ spacing }) => theme.spacing(spacing),
  },
}));

function FormikFormWithError({ className, children, spacing = 2 }) {
  const classes = useStyles({ spacing });
  return (
    <FormikForm className={className}>
      {children}
      <FormikFormError className={classes.error} />
    </FormikForm>
  );
}

FormikFormWithError.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
};

export default FormikFormWithError;
