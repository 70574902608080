import React from 'react';

import { InfoCard } from '../../../common/components';
import { formatForint, formatDateTime } from '../../../../utils/formatters';
import * as Types from '../../../../utils/propTypes';

function AccountInfoCard({ account }) {
  const rows = [
    {
      label: 'Created by',
      value: account.createdBy !== null ? account.createdBy.fullName : '',
    },
    {
      label: 'Created at',
      value: formatDateTime(account.createdAt),
    },
    {
      label: 'Balance',
      value: formatForint(account.balance),
    },
    {
      label: 'Status',
      value: account.active ? 'Active' : 'Inactive',
    },
    {
      label: 'Total Sale',
      value: formatForint(account.sales.total),
    },
    {
      label: 'Sales of Today',
      value: formatForint(account.sales.today),
    },
    {
      label: 'Total Error',
      value: 'TBD',
    },
  ];
  return <InfoCard title={account.name} rows={rows} />;
}

AccountInfoCard.propTypes = {
  account: Types.account.isRequired,
};

export default AccountInfoCard;
