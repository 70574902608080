import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { EXPORT_COLLECTION } from '../graphql';

export default function useExportCollection(collectionName) {
  const [mutate, result] = useMutation(EXPORT_COLLECTION);

  const exportCollection = useCallback(
    spreadsheetName =>
      mutate({
        variables: { spreadsheetName, collectionName },
      }),
    [collectionName, mutate],
  );

  return [exportCollection, result];
}
