import React, { useState } from 'react';
import { Card, CardContent, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { useGetCustomerProfilePictures } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FillerSpinner } from '../../../lib/components';

function CustomerProfilePictureHistory({ customer }) {
  const { id } = customer;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data, isError, isSuccess, isLoading } = useGetCustomerProfilePictures(id);

  const handleClose = () => {
    setOpen(false);
  };

  const originalUrl = data?.customersProfilePictureOriginal[selectedIndex];

  return (
    <Grid>
      {isError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <Typography>Something went wrong!</Typography>
        </Alert>
      )}
      {isLoading && <FillerSpinner />}
      {isSuccess && (
        <Card>
          <CardContent>
            <Typography variant="h3">Uploaded pictures</Typography>
            <div>
              {data?.customersProfilePictureResized?.length === 0 ? (
                <Typography variant="h4" style={{ marginTop: '4px' }}>
                  No history yet.
                </Typography>
              ) : (
                data?.customersProfilePictureResized?.map((url, index) => (
                  <img
                    key={index}
                    style={{ margin: '4px', cursor: 'pointer' }}
                    src={`https://storage.googleapis.com/online-elb-card-gcs-bucket/${url}`}
                    width="200px"
                    alt={url}
                    onClick={() => {
                      setSelectedIndex(index);
                      setOpen(true);
                    }}
                  />
                ))
              )}
            </div>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent style={{ fontSize: '20px', textAlign: 'center' }}>
                <img
                  src={`https://storage.googleapis.com/online-elb-card-gcs-bucket/${originalUrl}`}
                  alt=""
                  width="100%"
                />
              </DialogContent>
            </Dialog>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
}

CustomerProfilePictureHistory.propTypes = {
  customer: Types.customer.isRequired,
};

export default CustomerProfilePictureHistory;
