import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { useVerifyPasswordResetToken } from '../../hooks';

import { FillerSpinner } from '../../../lib/components';
import { PasswordResetForm } from '..';

const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

function ResetPassword({
  match: {
    params: { resetPasswordToken },
  },
}) {
  const classes = useStyles();

  const { loading, data, error } = useVerifyPasswordResetToken(resetPasswordToken);

  if (loading) {
    return <FillerSpinner />;
  }

  const {
    verifyPasswordResetToken: { verified },
  } = data;

  if (error || !verified) {
    return (
      <Typography variant="body1">
        Sorry, your password reset token is invalid or has been expired.
      </Typography>
    );
  }

  const {
    verifyPasswordResetToken: {
      user: { email },
    },
  } = data;

  return (
    <>
      <Typography variant="h3" component="h1" className={classes.margin}>
        Reset password for {email}
      </Typography>

      <PasswordResetForm resetPasswordToken={resetPasswordToken} email={email} />
    </>
  );
}

ResetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ resetPasswordToken: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default ResetPassword;
