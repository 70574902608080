import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PAYMENTS_API_BASE_URL, PAYMENTS_API_ENDPOINTS } from '../payments.constants';

export const useStartPaymentRefund = publicPaymentId => {
    return useMutation({
        mutationKey: ['refund-payment', publicPaymentId],
        mutationFn: async () => {
            const response = await axios.post(
                `${PAYMENTS_API_BASE_URL}/${PAYMENTS_API_ENDPOINTS.PAYMENTS_CONTROLLER_BASE_URL}/${publicPaymentId}/refund`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
