import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { Content } from '../../../common/components';

import ElbCardReadingToolBar from '../ElbCardReadingToolBar/ElbCardReadingToolBar';
import ElbCardReadingInfoCard from '../ElbCardReadingInfoCard/ElbCardReadingInfoCard';
import { useNewElbCardReading } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';

import { isAfter } from 'date-fns';
import { useDebounce } from '../../../common/hooks';

function ElbCardReading() {
  const [cardNumber, setCardNumber] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [approvedIcon, setApprovedIcon] = useState(false);
  const [lastApiRequestTimestamp, setLastApiRequestTimestamp] = useState(null);
  const [lastInputChangeTimestamp, setInputChangeTimestamp] = useState(null);
  const debouncedCardNumber = useDebounce(cardNumber, 500);
  const { isError, isLoading, isSuccess, data: cardReadings, mutate } = useNewElbCardReading(
    debouncedCardNumber,
    setShowButtons,
    setApprovedIcon,
  );

  useEffect(() => {
    setInputChangeTimestamp(new Date());
  }, [cardNumber]);

  useEffect(() => {
    if (isSuccess && cardReadings) {
      setLastApiRequestTimestamp(new Date());
    }
  }, [isSuccess, cardReadings]);

  const isApiResponseOutdated = isAfter(lastInputChangeTimestamp, lastApiRequestTimestamp);

  return (
    <Content
      toolbar={
        <>
          <ElbCardReadingToolBar
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            mutate={mutate}
            debouncedCardNumber={debouncedCardNumber}
          />
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isSuccess &&
            (cardReadings?.length > 0
              ? cardReadings.map((cardReading, index) => (
                  <ElbCardReadingInfoCard
                    key={index}
                    data={cardReading}
                    isError={isError}
                    isLoading={isLoading}
                    className={index === 0 ? 'specialClass' : 'none'}
                    showButtons={showButtons}
                    setShowButtons={setShowButtons}
                    approvedClass={
                      !showButtons && approvedIcon
                        ? 'approved'
                        : !showButtons && !approvedIcon
                        ? 'declined'
                        : ''
                    }
                    approvedIcon={approvedIcon}
                    setApprovedIcon={setApprovedIcon}
                  />
                ))
              : !isApiResponseOutdated && (
                  <Alert severity="info">
                    <AlertTitle>ELB Card Reading</AlertTitle>
                    <p style={{ fontSize: '16px' }}>
                      There is <strong>no active</strong> ELB card with the search text:
                      <strong>{cardNumber}</strong>!
                    </p>
                  </Alert>
                ))}
        </Grid>
      </Grid>
    </Content>
  );
}

export default ElbCardReading;
