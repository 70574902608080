import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_CUSTOMERS } from '../../graphql';

import { Collection } from '../../../common/components';
import { CustomersToolbar, CustomersTable } from '..';

import { FormikAsyncWithSnackbar as Formik, FormikForm } from '../../../lib/components';
import { FormikCustomerAutocomplete } from '../../../customers/components';

function Customers() {
  const initialValues = {
    customer: null,
  };
  const handleSubmit = () => {};
  return (
    <Collection
      toolbar={
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            //successText="Billing customer has been added!"
          >
            <FormikForm>
              <FormikCustomerAutocomplete
                name="customer"
                InputProps={{
                  required: true,
                  variant: 'outlined',
                  fullWidth: true,
                  margin: 'normal',
                }}
                isCustomersTable={true}
              />
            </FormikForm>
          </Formik>
          <CustomersToolbar refetchQueries={[getOperationName(GET_CUSTOMERS)]} />
        </>
      }
      table={<CustomersTable />}
    />
  );
}

export default Customers;
