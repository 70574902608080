import React from 'react';
import { isEmpty } from 'lodash';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { validateElbCard as validateElbCardSchema } from '../../../../utils/validationSchemas';

import { useAddElbCardToOrder, useUpdateOrderElbCard } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { ELBCardFields } from '..';

function MutateOrderELBCard({ elbCard = {} }) {
  const isNew = isEmpty(elbCard);
  const { id, cardNumber, customer, type } = elbCard;

  const [add] = useAddElbCardToOrder();
  const [edit] = useUpdateOrderElbCard(id);

  const dialogProps = {
    title: `${isNew ? 'Add' : 'Edit'} ELB Card`,
    submitButtonText: `${isNew ? 'Add' : 'Edit'} ELB Card`,
    fields: <ELBCardFields isNew={isNew} />,
    fullWidth: true,
    maxWidth: 'md',
  };

  const formikProps = {
    initialValues: {
      elbCardType: type || null,
      customer: customer || null,
      elbCardNumber: cardNumber || '',
      isWithoutPhysicalCardToggle: false,
      isAddPhysicalCardToOnlineCard: false,
    },
    validationSchema: validateElbCardSchema,
    onSubmit: async ({
      elbCardType: { id: elbCardTypeId },
      customer: { id: customerId },
      elbCardNumber,
      ...otherValues
    }) => {
      const variables = {
        elbCardType: elbCardTypeId,
        customer: customerId,
        elbCardNumber: `ELB${elbCardNumber}`,
        ...otherValues,
      };
      // Remove isWithoutPhysicalCardToggle from variables
      const {
        isWithoutPhysicalCardToggle,
        ...restVariables
      } = variables;
      if (isNew) {
        return add(restVariables);
      }
      return edit(restVariables);
    },
  };

  const snackbarProps = {
    successText: 'Valid ELB Card!',
  };

  const actionButtonProps = {
    variant: 'outlined',
    color: isNew ? 'primary' : 'default',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={isNew ? 'Add ELB Card' : <EditIcon />}
      actionButtonProps={actionButtonProps}
      buttonComponent={isNew ? undefined : IconButton}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

MutateOrderELBCard.propTypes = {
  elbCard: Types.elbCard,
};

export default MutateOrderELBCard;
