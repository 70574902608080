import React, { useMemo } from 'react';

import { createCustomer as createCustomerSchema } from '../../../utils/validationSchemas';

import useCreateCustomerMutation from './useCreateCustomerMutation';

import { CustomerFields } from '../components';

export default function useCreateCustomer(options) {
  const [create, createResult] = useCreateCustomerMutation(options);

  const dialogProps = useMemo(
    () => ({
      title: 'Add Customer',
      submitButtonText: 'Add Customer',
      fields: <CustomerFields />,
    }),
    [],
  );

  const initialValues = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
    }),
    [],
  );

  const formikProps = useMemo(
    () => ({
      initialValues,
      validationSchema: createCustomerSchema,
      onSubmit: create,
    }),
    [create, initialValues],
  );

  const snackbarProps = useMemo(
    () => ({
      successText: 'Customer has been created!',
    }),
    [],
  );

  const mutation = useMemo(() => [create, createResult], [create, createResult]);

  return [mutation, formikProps, dialogProps, snackbarProps];
}
