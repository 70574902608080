import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  spinner: {},
}));

function FillerSpinner() {
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
      <CircularProgress className={classes.spinner} />
    </Grid>
  );
}

export default FillerSpinner;
