import { pickBy } from 'lodash';

export function removeFalsy(object) {
  return pickBy(object);
}

export function wrapFormHandler(handler, func) {
  return function wrappedHandler(...args) {
    if (handler) handler(...args);
    func(...args);
  };
}

export function insert(array, index, ...items) {
  if (index <= 0) return [...items, ...array];
  if (index >= array.length) return [...array, ...items];
  return [...array.slice(0, index), ...items, ...array.slice(index)];
}

export function insertBy(array, item, predicate, after = true) {
  const index = array.findIndex(predicate);
  return insert(array, after ? index + 1 : index, item);
}

export function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

// Only for top level IDs, nested objects are not normalized
export function normalize(array, key = 'id') {
  return array.reduce((normalized, item) => {
    // eslint-disable-next-line no-param-reassign
    normalized[item[key]] = item;
    return normalized;
  }, {});
}

export function msToDuration(ms) {
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const seconds = Math.floor((ms / 1000) % 60);
  const milliseconds = Number.parseInt((ms % 1000) / 100, 10);
  return [hours, minutes, seconds, milliseconds];
}
