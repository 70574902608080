import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_USERS } from '../../graphql';

import { Collection } from '../../../common/components';
import { UsersToolbar, UsersTable } from '..';

function Users() {
  return (
    <Collection
      toolbar={<UsersToolbar refetchQueries={[getOperationName(GET_USERS)]} />}
      table={<UsersTable />}
    />
  );
}

export default Users;
