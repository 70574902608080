export default Object.freeze({
  PROPS_UPDATE: 'propsUpdate',
  COLUMN_VIEW_CHANGE: 'columnViewChange',
  SORT: 'sort',
  CHANGE_ROWS_PER_PEAGE: 'changeRowsPerPage',
  CHANGE_PAGE: 'changePage',
  SEARCH: 'search',
  RESET_FILTERS: 'resetFilters',
  FILTER_CHANGE: 'filterChange',
  ROW_DELETE: 'rowDelete',
  EXPAND_ROW: 'expandRow',
  ROWS_SELECT: 'rowsSelect',
});
