import React from 'react';

import routes from '../../../../utils/routes';
import { Table, CollectionTable } from '../../../common/components';
import { GET_TERMINALS } from '../../graphql';

const renderAccountCell = account => {
  return account ? routes.account.createLink(account.id, account.name) : 'No assigned account';
};

const renderAccountBalanceCell = account => {
  return account ? account.balance : 'No assigned account';
};

const columns = [
  {
    name: 'name',
    label: 'Name',
    options: {
      renderCell: (value, { id }) => routes.terminal.createLink(id, value),
      filterType: 'textField',
    },
  },
  {
    name: 'account',
    label: 'Assigned account',
    options: {
      renderCell: account => renderAccountCell(account),
    },
  },
  {
    name: 'account',
    label: 'Assigned account balance',
    options: {
      renderCell: account => renderAccountBalanceCell(account),
    },
  },
];

function TerminalsTable() {
  return (
    <CollectionTable>
      <Table
        title="Terminals List"
        collectionName="terminals"
        query={GET_TERMINALS}
        columns={columns}
      />
    </CollectionTable>
  );
}

export default TerminalsTable;
