import React from 'react';
import PropTypes from 'prop-types';

import { MutateOrderTicket, MutateOrderELBCard } from '..';

function MutateOrderItem({ data: { ticket, elbCard } = {} }) {
  if (ticket) return <MutateOrderTicket ticket={ticket} />;
  if (elbCard) return <MutateOrderELBCard elbCard={elbCard} />;
  return null;
}

MutateOrderItem.propTypes = {
  data: PropTypes.exact({
    ticket: PropTypes.object,
    elbCard: PropTypes.object,
  }),
};

export default MutateOrderItem;
