import React from 'react';
import * as PropTypes from 'prop-types';

import { useStopSaleForElbCardType } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function StopSaleForELBCardType({ id, name }) {
  const [stopSale, result] = useStopSaleForElbCardType(id);
  return (
    <ActionButtonWithConfirmation
      action={stopSale}
      result={result}
      title={`Are you sure you want to stop sales for "${name}"?`}
      successMessage="Sale has been stopped for ELB Card type!"
      buttonText="Stop Sales"
    />
  );
}

StopSaleForELBCardType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default StopSaleForELBCardType;
