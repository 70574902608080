import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useGetAccountOptions } from '../../../common/hooks';

import { FormikAutocomplete as FAutocomplete } from '../../../lib/components';
import { formatForint } from '../../../../utils/formatters';

function FormikAccountAutocomplete({ name, InputProps = {}, ...rest }) {
  const { loading, data } = useGetAccountOptions();
  const options = useMemo(
    () =>
      data
        ? [
            ...data.users.entries.filter(({ isActive }) => isActive).map(({ account }) => account),
            ...data.accounts.entries.filter(({ active }) => active),
          ]
        : [],
    [data],
  );
  const getOptionLabel = useCallback(
    ({ name: accountName, balance: accountBalance }) =>
      `${accountName} (${formatForint(accountBalance)})`,
    [],
  );

  return (
    <FAutocomplete
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      loading={loading}
      InputProps={{
        label: 'Account',
        ...InputProps,
      }}
      {...rest}
    />
  );
}

FormikAccountAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
};

export default FormikAccountAutocomplete;
