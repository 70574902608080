import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { MutateUser } from '..';

function UpdateUser({ user = {} }) {
  return <MutateUser user={user} />;
}

UpdateUser.propTypes = {
  user: Types.user,
};

export default UpdateUser;
