import * as dotenv from 'dotenv';

dotenv.config();

const globalApplicationConfiguration = {
  featureFlags: {
    products: {
      displayProductsMenu:
        process.env.REACT_APP_FEATURE_FLAGS_PRODUCTS_DISPLAY_PRODUCTS_MENU === 'true',
      displayCreateTicketProductsOnEventPage:
        process.env.REACT_APP_FEATURE_FLAGS_PRODUCTS_DISPLAY_CREATE_TICKET_PRODUCT_ON_EVENT_PAGE ===
        'true',
      displayTicketProductsTableOnEventPage:
        process.env.REACT_APP_FEATURE_FLAGS_PRODUCTS_DISPLAY_TICKET_PRODUCTS_TABLE_ON_EVENT_PAGE ===
        'true',
      displayOrderWithProductsButton:
        process.env.REACT_APP_FEATURE_FLAGS_PRODUCTS_DISPLAY_ORDER_WITH_PRODUCTS_BUTTON === 'true',
    },
    qrCodeReading: {
      displayQrCodeReadingMenu: process.env.REACT_APP_FEATURE_FLAGS_DISPLAY_QR_READING_MENU === 'true'
    }
  },
};

export default globalApplicationConfiguration;
