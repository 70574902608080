import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { ButtonLink } from '../../../lib/components';
import { Toolbar } from '../../../common/components';
import { FinalizeOrder } from '..';

function OrderSummaryToolbar({ order, paidAmounts, terminal, paymentMode }) {
  return (
    <Toolbar>
      <ButtonLink to="/order-edit">Back to edit</ButtonLink>
      <FinalizeOrder
        order={order}
        paidAmounts={paidAmounts}
        terminal={terminal}
        paymentMode={paymentMode}
      />
    </Toolbar>
  );
}

OrderSummaryToolbar.propTypes = {
  order: Types.order.isRequired,
};

export default OrderSummaryToolbar;
