import React from 'react';

import * as Types from '../../../../utils/propTypes';
import MutateTerminal from '../MutateTerminal/MutateTerminal';

function UpdateTerminal({ terminal }) {
  return <MutateTerminal terminal={terminal} />;
}

UpdateTerminal.propTypes = {
    terminal: Types.terminal.isRequired,
};

export default UpdateTerminal;
