import { GET_OPEN_ORDER, ORDER_FRAGMENT } from '../../modules/orders/graphql';
import { TICKET_FRAGMENT } from '../../modules/tickets/graphql';
import { ELB_CARD_FRAGMENT } from '../../modules/elb-cards/graphql';

export default {
  Query: {
    orderTicket: async (root, { id }, { client, cache }) => {
      try {
        const orderTicket = cache.readFragment({
          id,
          fragment: TICKET_FRAGMENT,
        });
        return orderTicket;
      } catch (e) {
        const {
          data: {
            order: { tickets },
          },
        } = await client.query({ query: GET_OPEN_ORDER });
        return tickets.find(({ id: ticketId }) => id === ticketId);
      }
    },
    orderElbCard: async (root, { id }, { client, cache }) => {
      try {
        const orderElbCard = cache.readFragment({
          id,
          fragment: ELB_CARD_FRAGMENT,
        });
        return orderElbCard;
      } catch (e) {
        const {
          data: {
            order: { elbCards },
          },
        } = await client.query({ query: GET_OPEN_ORDER });
        return elbCards.find(({ id: elbCardId }) => id === elbCardId);
      }
    },
  },

  Mutation: {
    clearOrder: async (root, args, { client }) => {
      const { order } = client.readQuery({ query: GET_OPEN_ORDER });
      return client.writeFragment({
        id: order.id,
        fragment: ORDER_FRAGMENT,
        fragmentName: 'OrderFragment', // @see: https://github.com/apollographql/apollo-client/issues/2902#issuecomment-360330063
        data: {
          ...order,
          customer: null,
          elbCards: [],
          tickets: [],
          expiresAt: null,
          isEmpty: true,
        },
      });
    },
  },
};
