import { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useEffectOnSuccess, usePrevious } from '../../../../lib/hooks';

/** Return only one of the top level fields from the query response */
function parsePagingQueryResultData(gqlData = {}) {
  const values = Object.values(gqlData);
  return values.length > 0 ? values[0] : {};
}

function usePagingQuery(query, page, sort, search, filters, limit, transformData) {
  const result = useQuery(query, {
    variables: {
      options: {
        pagination: { cursor: null, limit },
        sort: sort && { field: sort.name, direction: sort?.sort || sort.direction },
        search,
        filters,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const { data: rawData, loading, refetch } = result;
  const data = parsePagingQueryResultData(rawData);
  const [cursors, setCursors] = useState({ 0: null }); // Keep track of received cursors

  /** Set the next page's cursor after every successful request */
  useEffectOnSuccess(result, () => {
    const {
      pagination: { nextCursor },
    } = data;
    setCursors({
      ...cursors,
      [page + 1]: nextCursor,
    });
  });

  /** Refresh cursors if limit has changed */
  useEffect(() => {
    setCursors({ 0: null });
  }, [limit]);

  /** Note: requesting an arbitrary page won't work, but `Table` only allows iterative pagination for now */
  const fetchPage = useCallback(
    newPage => {
      refetch({
        options: {
          pagination: { cursor: cursors[newPage], limit },
          sort: sort && { field: sort.name, direction: sort.direction },
          search,
          filters,
        },
      });
    },
    [cursors, limit, filters, sort, search, refetch],
  );

  return {
    data: data.entries ? data.entries.map(transformData) : [],
    // count: data.page?.count,
    count: -1 /** Enable pagination with unknown number of items, @see https://material-ui.com/api/table-pagination/#props */,
    hasMore: Boolean(data.pagination?.hasMore),
    loading,
    fetchPage,
  };
}

export default function useServerSideState(
  query,
  page,
  sort,
  search,
  filters,
  filtersConfirmed,
  setFiltersConfirmed,
  setPage,
  rowsPerPage,
  transformData = entry => entry,
) {
  const { data, count, loading, hasMore, fetchPage } = usePagingQuery(
    query,
    page,
    sort,
    search,
    filters,
    rowsPerPage,
    transformData,
  );

  const [, hasSortChanged] = usePrevious(sort);
  const [, hasSearchChanged] = usePrevious(search);
  const [, hasPageChanged] = usePrevious(page);
  const [, hasRowsPerPageChanged] = usePrevious(rowsPerPage);
  useEffect(() => {
    if (hasPageChanged || hasSortChanged || hasSearchChanged || filtersConfirmed) {
      fetchPage(page);

      if (filtersConfirmed) {
        setFiltersConfirmed(false);
      }
    }
    if (hasRowsPerPageChanged) {
      // Go back to first page if limit has changed
      setPage(0);
    }
  }, [
    hasPageChanged,
    hasSortChanged,
    hasSearchChanged,
    filtersConfirmed,
    setFiltersConfirmed,
    hasRowsPerPageChanged,
    fetchPage,
    page,
    setPage,
  ]);

  return {
    data,
    count,
    loading,
    hasMore,
  };
}
