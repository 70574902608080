import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import * as Types from '../../../../../../utils/propTypes';
import { useGetTicketTypes } from '../../../../../events/hooks';
import TableEntityAutoCompleteFilter from '../TableEntityAutoCompleteFilter';

function TableTicketTypeAutocompleteFilter({ column, filters, index, onChange }) {
  const [fieldText, setFieldText] = useState();
  const debouncedSetFieldText = debounce(setFieldText, 300);
  const onInputChange = useCallback(
    (_, value) => {
      debouncedSetFieldText(value);
    },
    [debouncedSetFieldText],
  );
  const { loading, data } = useGetTicketTypes({
    variables: {
      options: {
        ...(fieldText ? { filters: [{ field: 'name', type: 'Text', values: [fieldText] }] } : null),
        pagination: { limit: 10 },
      },
    },
  });
  const ticketTypes = data ? data.ticketTypes.entries : [];
  const getOptionLabel = useCallback(
    ({ name, event: { name: eventName } }) => `${name} (${eventName})`,
    [],
  );

  return (
    <TableEntityAutoCompleteFilter
      column={column}
      filters={filters}
      index={index}
      onChange={onChange}
      label="Ticket type"
      property="name"
      options={ticketTypes}
      loading={loading}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
    />
  );
}

TableTicketTypeAutocompleteFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(PropTypes.number).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TableTicketTypeAutocompleteFilter;
