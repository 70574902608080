import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

function Tiles({ primary, secondary, tertiary, quaternary }) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={secondary ? 4 : 12} xs={12}>
        {primary}
      </Grid>

      {secondary && (
        <Grid item sm={8} xs={12}>
          {secondary}
        </Grid>
      )}

      <Grid item xs={12}>
        {tertiary}
      </Grid>

      {quaternary && (
        <Grid item xs={12}>
          {quaternary}
        </Grid>
      )}
    </Grid>
  );
}

Tiles.propTypes = {
  primary: PropTypes.node,
  secondary: PropTypes.node,
  tertiary: PropTypes.node,
  quaternary: PropTypes.node,
};

export default Tiles;
