import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { values } from 'lodash';
import { Redirect } from 'react-router';

import * as Types from '../../../../utils/propTypes';
import { FillerSpinner } from '../../../lib/components';

function buildQueryOptions(id, queryOptions = {}) {
  return {
    ...queryOptions,
    variables: {
      id,
      ...(queryOptions.variables ? queryOptions.variables : {}),
    },
  };
}

function WithDocumentQuery({ id, query, children, queryOptions = {} }) {
  const { loading, data, error } = useQuery(query, buildQueryOptions(id, queryOptions));

  if (loading) return <FillerSpinner />;
  if (error) return <Redirect to="/404a" />;

  return children(...values(data));
}

WithDocumentQuery.propTypes = {
  id: PropTypes.string.isRequired,
  query: Types.query.isRequired,
  children: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryOptions: Types.queryOptions,
};

export default WithDocumentQuery;
