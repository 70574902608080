import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import {
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  InputBase,
  OutlinedInput,
  FilledInput,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as Types from '../../../../utils/propTypes';

import { FormikFieldWithError } from '..';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
  },
}));

const variants = {
  standard: props => <InputBase {...props} />,
  outlined: props => <OutlinedInput {...props} />,
  filled: props => <FilledInput {...props} />,
};

function FormikSelect({
  name,
  options,
  onChange,
  loading = false,
  label = '',
  variant = 'standard',
  ...rest
}) {
  const classes = useStyles();

  const hasEmpty = useMemo(() => options.some(({ value }) => value === ''), [options]);

  return (
    <FormikFieldWithError name={name}>
      {({ field, hasError, errorMessage }) => (
        <FormControl {...rest} error={hasError} className={classes.margin}>
          {label && (
            <InputLabel shrink={hasEmpty || undefined} variant={variant}>
              {label}
            </InputLabel>
          )}

          <Select
            {...field}
            value={loading || field.value}
            onChange={onChange || field.onChange}
            input={variants[variant]()}
            displayEmpty={hasEmpty}
          >
            {options.map(({ value, label: optionLabel }) => (
              <MenuItem key={value} value={value}>
                {optionLabel}
              </MenuItem>
            ))}
          </Select>

          {hasError && errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      )}
    </FormikFieldWithError>
  );
}

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: Types.selectOptions.isRequired,
  responseErrors: Types.responseErrors,
  loading: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variants)),
  onChange: PropTypes.func,
};

export default FormikSelect;
