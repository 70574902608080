export default Object.freeze({
  DROPDOWN: 'dropdown',
  CHECKBOX: 'checkbox',
  MULTISELECT: 'multiselect',
  TEXTFIELD: 'textField',
  CUSTOM: 'custom',

  // Custom Types
  BOOLEAN: 'boolean',
  AUTOCOMPLETE: 'autocomplete',
  DATE_RANGE: 'dateRange',
  DATE_TIME_RANGE: 'dateTimeRange',
  NUMBER_RANGE: 'numberRange',
});
