import React from 'react';
import * as PropTypes from 'prop-types';

import { useActivateTicket } from '../../hooks';

import { ActionButton } from '../../../common/components';

function ActivateTicket({ id }) {
  const [activateTicket, result] = useActivateTicket(id);
  return (
    <ActionButton
      action={activateTicket}
      result={result}
      successMessage="Ticket has been activated!"
      color="primary"
      variant="contained"
    >
      Activate Ticket
    </ActionButton>
  );
}

ActivateTicket.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ActivateTicket;
