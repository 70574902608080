import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { MutateTicketType } from '..';

function EditTicketType({ ticketType }) {
  return <MutateTicketType ticketType={ticketType} />;
}

EditTicketType.propTypes = {
  ticketType: Types.ticketType.isRequired,
};

export default EditTicketType;
