import { useMutation } from '@tanstack/react-query';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../products.constants';

export default function useCreateProduct() {
  return useMutation({
    mutationKey: ['create-product'],
    mutationFn: async payload => {
      const token = localStorage.getItem('token')
      const response = await PRODUCTS_API_AXIOS_INSTANCE.post(
        `/${PRODUCTS_API_ENDPOINTS.PRODUCTS_CONTROLLER_BASE_URL}`,
        payload,
        { headers: { Authorization: token } }
      );
      const data = await response.data;
      return data;
    },
    refetchOnWindowFocus: false,
  });
}
