import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useEventBrowser(eventSearch) {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['event-browser', eventSearch],
    queryFn: async eventSearch => {
      // TODO: remove this log before merging pull request to master
      console.log({ eventSearch });
      const { searchName, searchFromDate, searchUntilDate, searchLimit } = eventSearch.queryKey[1];
      // TODO: remove this log before merging pull request to master
      console.log({ searchName, searchFromDate, searchUntilDate, searchLimit });
      const searchParams = eventSearch.queryKey[1];
      let response;
      if (Object.keys(eventSearch.queryKey[1]).length === 0) {
        response = await axios.get(`${REACT_APP_SERVER_URL}events-browser`);
      } else {
        const apiUrl = new URL(`${REACT_APP_SERVER_URL}events-browser`);
        const apiParams = new URLSearchParams();
        for (const [key, value] of Object.entries(searchParams)) {
          if (value) {
            apiParams.set(key, value);
          }
        }
        response = await axios.get(`${apiUrl}?${apiParams.toString()}`);
      }
      const data = await response.data;
      // TODO: remove this log before merging pull request to master
      console.log(data);
      return data;
    },
    refetchOnWindowFocus: false,
  });
}
