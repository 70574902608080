import React from 'react';

import { ActionButtonWithConfirmation } from '../../../../common/components';
import { useClearProductOrder } from '../../../hooks';

function ClearProductOrder({ order: { customer, tickets, elbCards } }) {
  const [clearProductOrder, res] = useClearProductOrder();

  const buttonProps = {
    variant: 'text',
    color: 'default',
    disabled: !customer && tickets.length === 0 && elbCards.length === 0,
  };

  return (
    <ActionButtonWithConfirmation
      action={clearProductOrder}
      result={res}
      title="Are you sure you want to clear the order?"
      buttonText="Clear Product Order"
      buttonProps={buttonProps}
      successMessage="Order has been cleared!"
    />
  );
}

export default ClearProductOrder;
