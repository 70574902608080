import { ACTIVATE_USER } from '../graphql';

import useAuthMutation from './useAuthMutation';

export default function useActivateUser(activationToken) {
  const [activate, res, stored] = useAuthMutation(ACTIVATE_USER);

  async function simpleActivate(values) {
    await activate({ data: { activationToken, ...values } });
  }

  return [simpleActivate, res, stored];
}
