import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';

function Link({ to, children, ...rest }, ref) {
  return (
    <MaterialLink component={RouterLink} to={to} ref={ref} {...rest}>
      {children}
    </MaterialLink>
  );
}

const LinkWithRef = forwardRef(Link);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LinkWithRef;
