import React from 'react';
import * as PropTypes from 'prop-types';

import { useInactivateUser } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function InactivateUserWithZeroBalance({ id, fullName }) {
  const [inactivate, result] = useInactivateUser(id);
  return (
    <ActionButtonWithConfirmation
      action={inactivate}
      result={result}
      title={`Are you sure you want to inactivate "${fullName}"?`}
      description={`${fullName} won't be able to access the Backoffice.`}
      successMessage="User has been inactivated!"
      buttonText="Inactivate"
    />
  );
}

InactivateUserWithZeroBalance.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default InactivateUserWithZeroBalance;
