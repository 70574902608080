import {
  string,
  number,
  bool,
  func,
  arrayOf,
  instanceOf,
  shape,
  object,
  exact,
  oneOf,
  oneOfType,
  // objectOf,
  // node,
} from 'prop-types';

export const id = string.isRequired;

export const date = instanceOf(Date);

/** GraphQL Query */
export const query = object;
export const queryOptions = object;
export const mutation = object;
export const pollingRate = oneOfType([number, oneOf(['slow', 'default', 'fast'])]);

/** Apollo result types */
export const responseErrors = shape({
  graphQLErrors: arrayOf(
    oneOfType([
      string,
      shape({
        message: shape({
          global: string,
          fields: arrayOf(
            exact({
              message: string,
              path: string,
            }),
          ),
        }),
      }),
    ]),
  ),
  message: string,
});
export const result = shape({
  called: bool,
  loading: bool,
  data: object,
  error: responseErrors,
  networkStatus: object,
  stale: bool,
});

const creator = shape({
  fullName: string,
});

export const account = shape({
  id,
  name: string,
  balance: number,
  active: bool,
  createdAt: string,
  createdBy: creator,
  sales: exact({
    total: number,
    today: number,
    __typename: string,
  }),
});

export const user = shape({
  id,
  account,
  firstName: string,
  lastName: string,
  fullName: string,
  createdAt: string,
  role: shape({
    name: string,
  }),
  email: string,
  isActive: bool,
});

export const customer = shape({
  id,
  firstName: string,
  lastName: string,
  fullName: string,
  email: string,
  totalSales: number,
  createdAt: string,
  // activeElbCard: elbCard,
  // elbCards,
  vid: string,
  contactUrl: string,
});
export const customers = arrayOf(customer);

export const elbCardType = shape({
  id,
  name: string,
  price: number,
  forSale: bool,
  createdAt: string,
  createdBy: creator,
});
export const elbCardTypes = arrayOf(elbCardType);

export const elbCard = shape({
  id,
  cardNumber: string,
  expirationDate: string,
  type: elbCardType,
  customer,
  status: string,
  createdAt: string,
  soldBy: creator,
});
export const elbCards = arrayOf(elbCard);

export const tableColumn = shape({
  name: string.isRequired,
  label: string.isRequired,
  options: object,
});

/** Ticket Type */
export const ticketType = shape({
  id,
  name: string,
  forSale: bool,
  normalPrice: number,
  elbCardPrice: number,
  expirationDate: string,
  limit: number,
  ticketStats: shape({
    active: number,
    reserved: number,
    sold: number,
  }),
});
export const ticketTypes = arrayOf(ticketType);

/** Billing Account */
export const billingAccount = shape({
  id,
  name: string,
  publicKey: string,
  privateKey: string,
  active: bool,
  createdAt: string,
  createdBy: creator,
});
export const billingAccounts = arrayOf(billingAccount);

/** Event */
export const event = shape({
  id,
  name: string,
  forSale: bool,
  from: string,
  until: string,
  ticketTypes: arrayOf(ticketType),
  location: string,
  ticketLimit: number,
  createdAt: string,
  createdBy: creator,
  totalSales: number,
  ticketCount: number,
  billingAccount,
  form: shape({
    id,
    submissionsUrl: string,
  }),
  ticketStats: shape({
    active: number,
    reserved: number,
    sold: number,
    limit: number,
  }),
  isExternal: bool,
});
export const events = arrayOf(event);

export const settings = shape({
  billing: shape({
    manualBilling: bool,
  }),
  elbCard: shape({
    defaultExpirationTime: number,
    billingAccount,
  }),
});

/** Formik */
export const initialValues = object;
export const validationSchema = object;
export const onSubmit = func;
export const onSuccess = func;
export const onFailure = func;
export const formik = shape({
  initialValues,
  validationSchema,
  onSubmit: onSubmit.isRequired,
  onSuccess,
  onFailure,
});

/** Snackbar */
export const successText = string;
export const errorText = string;
export const snackbar = shape({
  successText,
  errorText,
});

/** Ticket */
export const ticket = shape({
  id,
  elbCard,
  event,
  ticketType,
  ticketNumber: string,
  active: bool,
  soldAt: string,
  soldBy: user,
  customer,
});
export const tickets = arrayOf(ticket);

/** Order */
export const order = shape({
  id,
  prettyId: string,
  createdBy: creator,
  customer,
  elbCards: arrayOf(elbCard),
  tickets: arrayOf(ticket),
  createdAt: string,
  amount: number,
  items: number,
});
export const orders = arrayOf(order);

/** Audit Logs */
export const auditLog = shape({
  id,
  prettyId: string,
  account,
  balance: exact({
    expected: number.isRequired,
    reported: number.isRequired,
    difference: number.isRequired,
    __typename: string,
  }),
  createdBy: creator,
  createdAt: string,
  handledBy: user,
  handledAt: string,
  status: oneOf(['Pending', 'Denied', 'Accepted', 'OK']),
});
export const auditLogs = arrayOf(auditLog);

/** Route Match ID */
export const idMatch = shape({
  params: shape({ id: string.isRequired }).isRequired,
});

/** Select field items */
export const selectOptions = arrayOf(
  exact({
    value: oneOfType([string, number, bool]).isRequired,
    label: string.isRequired,
  }),
);

/** Transaction */
export const transaction = shape({
  id,
  source: account,
  target: account,
  type: oneOf(['Income', 'Expense', 'Transfer']),
  amount: number,
  description: string,
  createdBy: creator,
  createdAt: string,
});
export const transactions = arrayOf(transaction);

/** Forms */
export const form = shape({
  id,
  name: string,
});
export const forms = arrayOf(form);

/** Terminal */
export const terminal = shape({
  id,
  name: string,
  account: account,
});
export const terminals = arrayOf(terminal);

/** Payment */
export const payment = shape({
  cashSubtotal: number,
  cardSubtotal: number,
});
export const payments = arrayOf(payment);

export const refetchQueries = arrayOf(oneOfType([string, object]));
