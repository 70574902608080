import { useManualQuery } from '../../lib/hooks';

import { SEARCH_CUSTOMERS } from '../graphql';

export default function useSearchCustomers(search, withStats) {
  return useManualQuery(SEARCH_CUSTOMERS, {
    variables: {
      search,
      withStats
    }
  });
}