import { useState, useRef, useMemo, useCallback } from 'react';

import { useDialog } from '../../../../lib/hooks';
import { useExportCollection, useExportTable } from '../../../hooks';

export default function useExport(access, collectionName, serverSide) {
  /**
   * This ref stores the currently available columns and data.
   * It is needed if a column's visibility is changed or the rows are filtered on the client side.
   */
  const available = useRef({});
  const setDisplayedRef = useCallback((availableColumns, availableData) => {
    available.current = {
      columns: availableColumns,
      data: availableData,
    };
  }, []);

  const [exportCollection, collectionExportResult] = useExportCollection(collectionName);
  const [exportTable, collectionTableResult] = useExportTable();

  const [isExporting, setIsExporting] = useState(false);

  const handleSubmit = useCallback(
    async ({ title }) => {
      setIsExporting(true);
      if (serverSide) {
        return exportCollection(title);
      }
      const {
        current: { columns, data },
      } = available;
      return exportTable(title, columns, data);
    },
    [exportCollection, exportTable, serverSide],
  );

  // Modal state
  const [isOpen, handleOpen, close] = useDialog(false);
  function handleClose() {
    close();
    setIsExporting(false);
  }
  const modal = { isOpen, handleClose };

  const options = useMemo(
    () =>
      access
        ? {
            /**
             * @TODO Add extensible labels
             */
            textLabels: {
              // ...optionsProp.textLabels,
              toolbar: {
                // ...optionsProp.textLabels.toolbar,
                downloadCsv: 'Export to Google Sheets',
              },
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
            onDownload: (_, __, columnsToExport, dataToExport) => {
              handleOpen();
              setDisplayedRef(columnsToExport, dataToExport);
              // Always returns false to disable CSV download
              return false;
            },
          }
        : { download: false },
    [access, handleOpen, setDisplayedRef],
  );

  return [
    options,
    modal,
    {
      isExporting,
      handleSubmit,
      result: {
        ...(serverSide ? collectionExportResult : undefined),
        ...(!serverSide ? collectionTableResult : undefined),
        data: serverSide
          ? collectionExportResult?.data?.exportCollection
          : collectionTableResult?.data?.exportTable,
      },
    },
  ];
}
