import React from 'react';
import PropTypes from 'prop-types';

import { GET_TICKET } from '../../../tickets/graphql';

import { UpdateOrderItem } from '..';

function UpdateOrderTicket({ id }) {
  return <UpdateOrderItem id={id} query={GET_TICKET} />;
}

UpdateOrderTicket.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UpdateOrderTicket;
