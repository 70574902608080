import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetCustomerProfilePictures(customerId) {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['get-customer-profile-picture-history', customerId],
    queryFn: async () => {
      const response = await axios.get(`${REACT_APP_SERVER_URL}customers/${customerId}/pictures`);
      const data = await response.data;
      return data;
    },
    refetchOnWindowFocus: false,
  });
}
