import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Button, Typography } from '@material-ui/core';

const CAMERA_TYPES = Object.freeze({
    FRONT_FACING: 'user',
    BACK_CAMERA: 'environment'
});

const QrCodeReading = () => {

    const [data, setData] = useState(null);
    const [isReading, setIsReading] = useState(false);

    const toggleQrReading = () => {
        setIsReading((previous) => !previous);
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px',
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={toggleQrReading}
                >
                    {isReading ? 'STOP' : 'START'}
                </Button>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '8px',
                marginBottom: '8px'
            }}>
                <div>
                    <Typography variant='h4' align='center'>QR code content</Typography>
                    <Typography
                        variant='body1'
                        align='center'
                        style={{
                            marginTop: '8px'
                        }}
                    >
                        {data || 'No QR code content yet'}
                    </Typography>
                </div>
            </div>
            {isReading && (
                <QrReader
                    delay={500}
                    onResult={(result, error) => {
                        if (!!result) {
                            console.log('onResult result', result);
                            setData(result?.text);
                        }

                        if (!!error) {
                            if (error?.message) {
                                console.log('QrReader error', error);
                                console.log('QrReader error?.message', error?.message);
                            }
                        }
                    }}
                    style={{ width: '100%' }}
                    constraints={{
                        facingMode: CAMERA_TYPES.BACK_CAMERA
                    }}
                />
            )}
        </div>
    );
}

export default QrCodeReading;