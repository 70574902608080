import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  contentRoot: {
    [theme.breakpoints.up('md')]: {
      padding: [[theme.spacing(1), theme.spacing(4), theme.spacing(4)]],
    },
    padding: theme.spacing(1),
  },
  contentChildren: {
    marginTop: theme.spacing(2),
  },
}));

function Content({ toolbar, children }) {
  const classes = useStyles();
  return (
    <div className={classes.contentRoot}>
      {toolbar}
      <div className={classes.contentChildren}>{children}</div>
    </div>
  );
}

Content.propTypes = {
  toolbar: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Content;
