import React from 'react';
import * as PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import FormikFieldWithError from '../FormikFieldWithError';

function FormikTextField({ name, ...rest }) {
  return (
    <FormikFieldWithError name={name} validate={rest?.validate}>
      {({ field, hasError, errorMessage }) => (
        <TextField {...field} error={hasError} helperText={errorMessage} {...rest} />
      )}
    </FormikFieldWithError>
  );
}

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikTextField;
