import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { useRemoveElbCardFromOrder, useRemoveTicketFromOrder } from '../../hooks';

function RemoveItemFromOrder({ type, id }) {
  const [removeTicket] = useRemoveTicketFromOrder(id);
  const [removeElbCard] = useRemoveElbCardFromOrder(id);

  function handleClick() {
    if (type === 'ticket') {
      removeTicket();
    }
    if (type === 'elbCard') {
      removeElbCard();
    }
  }

  return (
    <IconButton onClick={handleClick}>
      <DeleteIcon />
    </IconButton>
  );
}

RemoveItemFromOrder.propTypes = {
  type: PropTypes.oneOf(['elbCard', 'ticket']).isRequired,
  id: PropTypes.string.isRequired,
};

export default RemoveItemFromOrder;
