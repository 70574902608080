import { CircularProgress, FormLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { dropRightWhile, lowerCase, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import * as Types from '../../../../../../utils/propTypes';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
  },
}));

function TableEntityAutoCompleteFilter({
  column,
  filters,
  index,
  onChange,
  options,
  loading,
  property,
  label,
  onInputChange,
  getOptionLabel,
}) {
  const classes = useStyles();
  const handleChange = useCallback(
    filterIndex => (_, value) => {
      const newFilters = [...filters];
      newFilters[filterIndex] = get(value, property);

      const sanitizedFilters = dropRightWhile(newFilters, filter => filter === undefined);
      onChange(sanitizedFilters, index, column);
    },
    [column, filters, index, onChange, property],
  );

  const renderInput = useCallback(
    params => {
      return (
        <TextField
          className={classes.textField}
          {...params}
          InputProps={{
            ...params.InputProps,
            label,
            variant: 'outlined',
            fullWidth: true,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [loading, classes, label],
  );
  return (
    <div>
      <FormLabel>{column.label}</FormLabel>
      <Autocomplete
        name={lowerCase(column.label)}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={handleChange(0)}
        onInputChange={onInputChange}
        noOptionsText="No options found for the given query!"
        renderInput={params => renderInput(params)}
        loading={loading}
        autoHighlight
        disableOpenOnFocus
      />
    </div>
  );
}

TableEntityAutoCompleteFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(PropTypes.number).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};

export default TableEntityAutoCompleteFilter;
