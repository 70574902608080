import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';

function BillingAccountFields() {
  return (
    <>
      <FTextField
        name="name"
        required
        type="text"
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <FTextField
        name="publicKey"
        required
        type="text"
        label="Public Key"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <FTextField
        name="privateKey"
        required
        type="text"
        label="Private Key"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FFormError />
    </>
  );
}

BillingAccountFields.propTypes = {};

export default BillingAccountFields;
