import React from 'react';

import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { ButtonLink } from '../../../lib/components';
import { Toolbar } from '../../../common/components';
import globalApplicationConfiguration from '../../../../config/config';

function OrdersToolbar() {
  const access = useCheckPrivilege(Privileges.CREATE_ORDER);
  const featureFlagProductsDisplayOrderWithProductsButton =
    globalApplicationConfiguration.featureFlags.products.displayOrderWithProductsButton;
  if (access)
    return (
      <Toolbar>
        <ButtonLink to="/order-edit" variant="contained" color="primary">
          New Order
        </ButtonLink>
        {featureFlagProductsDisplayOrderWithProductsButton && (
          <ButtonLink to="/open-order-with-products" variant="outlined" color="primary">
            New Order (Product)
          </ButtonLink>
        )}
      </Toolbar>
    );
  return null;
}

export default OrdersToolbar;
