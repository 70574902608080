import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Hidden } from '@material-ui/core';
import { ExitToApp as LogoutIcon } from '@material-ui/icons';

import routes from '../../../../utils/routes';

import { useGetCurrentUser, useLogout } from '../../../auth/hooks';

import { Link } from '../../../lib/components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  logoutButton: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
}));

function Profile({ className, ...rest }) {
  const classes = useStyles();

  const {
    data: { currentUser },
  } = useGetCurrentUser();

  const logout = useLogout();

  return (
    <>
      <Link to={routes.user.createUrl(currentUser)}>
        <div {...rest} className={clsx(classes.root, className)}>
          <Typography className={classes.name} variant="h4">
            {currentUser.fullName}
          </Typography>
          <Typography variant="body2">{currentUser.role.name}</Typography>
        </div>
      </Link>
      <Hidden lgUp>
        <Button
          color="inherit"
          size="small"
          onClick={logout}
          startIcon={<LogoutIcon />}
          className={classes.logoutButton}
        >
          Logout
        </Button>
      </Hidden>
    </>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
