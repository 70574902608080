import React from 'react';
import * as PropTypes from 'prop-types';

import { GET_TICKET_TYPE } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import TicketTypeToolbar from '../TicketTypeToolbar';
import TicketTypeInfoCard from '../TicketTypeInfoCard';

function TicketType({
  match: {
    params: { eventId, ticketTypeId: id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_TICKET_TYPE}>
      {ticketType => (
        <Document
          toolbar={<TicketTypeToolbar eventId={eventId} ticketType={ticketType} />}
          infoCard={<TicketTypeInfoCard ticketType={ticketType} />}
        />
      )}
    </WithDocumentQuery>
  );
}

TicketType.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string.isRequired,
      ticketTypeId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TicketType;
