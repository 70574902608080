import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { UpdateELBCardType, StartSaleForELBCardType, StopSaleForELBCardType } from '..';

function ELBCardTypeToolbar({ elbCardType }) {
  const access = useCheckPrivilege(Privileges.UPDATE_ELB_CARD_TYPE);
  if (!access) return null;

  const { id, name, forSale } = elbCardType;
  return (
    <Toolbar>
      {forSale ? (
        <StopSaleForELBCardType id={id} name={name} />
      ) : (
        <StartSaleForELBCardType id={id} />
      )}
      <UpdateELBCardType id={id} name={name} />
    </Toolbar>
  );
}

ELBCardTypeToolbar.propTypes = {
  elbCardType: Types.elbCardType.isRequired,
};

export default ELBCardTypeToolbar;
