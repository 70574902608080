import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_CUSTOMER } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import {
  /* CustomerToolbar, */ CustomerInfoCard,
  CustomerELBCardsTable,
  CustomerProfilePictureHistory,
} from '..';

function Customer({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_CUSTOMER}>
      {customer => (
        <Document
          // toolbar={<CustomerToolbar customer={customer} />}
          infoCard={<CustomerInfoCard customer={customer} />}
          table={<CustomerELBCardsTable elbCards={customer.elbCards} />}
          secondTable={<CustomerProfilePictureHistory customer={customer} />}
        />
      )}
    </WithDocumentQuery>
  );
}

Customer.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Customer;
