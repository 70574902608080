import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ButtonWithLoading({ loading, disabled = false, children, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button {...rest} disabled={loading || disabled}>
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

ButtonWithLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ButtonWithLoading;
