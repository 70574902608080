export function isStaging() {
  const appEnv = process.env.REACT_APP_ENV_CONTEXT;
  return appEnv === 'staging';
}

export function isProduction() {
  const appEnv = process.env.REACT_APP_ENV_CONTEXT;
  return appEnv === 'production';
}

export function isDevelopment() {
  const appEnv = process.env.REACT_APP_ENV_CONTEXT;
  return appEnv === 'development';
}