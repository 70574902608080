import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from '../../../lib/components';
import { LoginForm } from '..';

const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

function Login() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" component="h1" className={classes.margin}>
        Log in to ELB Backoffice
      </Typography>

      <LoginForm />

      <Link to="/request-password-reset">Forgotten password</Link>
    </>
  );
}

export default Login;
