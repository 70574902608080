import React from 'react';
import { SnackbarProvider as NSSnackbarProvider } from 'notistack';
import * as PropTypes from 'prop-types';

function SnackbarProvider({ children }) {
  return (
    <NSSnackbarProvider
      preventDuplicate
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={3000}
    >
      {children}
    </NSSnackbarProvider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
