import gql from 'graphql-tag';

import { ELB_CARD_TYPE_FRAGMENT } from '../elb-card-types/graphql';
import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const ELB_CARD_FRAGMENT = gql`
  fragment ELBCardFragment on ELBCard {
    id
    customer {
      id
      fullName
      email
    }
    type {
      ...ELBCardTypeFragment
    }
    soldBy {
      id
      fullName
    }
    expirationDate
    status
    cardNumber
    physicalCardNumber
    onlineCardNumber
    isDelivered
    createdAt
    creationType
  }
  ${ELB_CARD_TYPE_FRAGMENT}
`;

export const GET_ELB_CARDS = gql`
  query GetELBCards($options: OptionsInput) {
    elbCards(options: $options) @connection(key: "elbCards") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...ELBCardFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${ELB_CARD_FRAGMENT}
`;

export const GET_ELB_CARD = gql`
  query GetELBCard($id: String!) {
    elbCard(id: $id) {
      ...ELBCardFragment
    }
  }
  ${ELB_CARD_FRAGMENT}
`;

export const UPDATE_ELB_CARD = gql`
  mutation UpdateELBCard($data: UpdateELBCardInput) {
    updateELBCard(data: $data) {
      id
      expirationDate
      physicalCardNumber
      isDelivered
    }
  }
`;

export const ACTIVATE_ELB_CARD = gql`
  mutation ActivateELBCard($data: ActivateELBCardInput) {
    activateELBCard(data: $data) {
      id
      status
      expirationDate
    }
  }
`;

export const INACTIVATE_ELB_CARD = gql`
  mutation InactivateELBCard($id: String!) {
    inactivateELBCard(id: $id) {
      id
      status
    }
  }
`;
