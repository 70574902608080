import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const CUSTOMER_PROFILE_PICTURE_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    id
    email
    firstName
    lastName
    profilePicture {
      url
      approved
      timeOfApproval
      originalUrl
      history
    }
    elbCards {
      id
      status
      cardNumber
      expirationDate
    }
  }
`;

export const GET_CUSTOMER_PROFILE_PICTURES = gql`
  query GetCustomersWithUnapprovedProfilePictures($options: OptionsInput) {
    customersWithUnapprovedPicture(options: $options) {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...CustomerFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${CUSTOMER_PROFILE_PICTURE_FRAGMENT}
`;

export const EVALUATE_PROFILE_PICTURE = gql`
  mutation ApproveProfilePicture($id: String!, $approved: Boolean!, $overrideAccess: Boolean) {
    approveProfilePicture(data: { id: $id, approved: $approved, overrideAccess: $overrideAccess }) {
      url
      approved
      timeOfApproval
    }
  }
`;
