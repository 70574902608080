import React from 'react';
import { Button } from '@material-ui/core';

import { useSnackbar } from '../../../lib/hooks';

function InactivateAccountWithBalance() {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      onClick={() =>
        enqueueSnackbar("Account with non-zero balance can't be inactivated!", 'error')
      }
    >
      Inactivate Account
    </Button>
  );
}

export default InactivateAccountWithBalance;
