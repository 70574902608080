import React from 'react';

import * as Types from '../../../../utils/propTypes';
import {
  transaction as transactionSchema,
  TRANSACTION_TYPES,
} from '../../../../utils/validationSchemas';

import { useCreateTransaction } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { TransactionFields } from '..';

function CreateTransaction({ refetchQueries, isTopToolbar = false }) {
  const dialogProps = {
    title: 'Add Transaction',
    submitButtonText: 'Add Transaction',
    fields: <TransactionFields />,
  };

  const [createTransaction] = useCreateTransaction(refetchQueries ? { refetchQueries } : undefined);
  const initialValues = {
    source: '',
    target: '',
    type: TRANSACTION_TYPES.Income,
    amount: 0,
    description: '',
  };
  const formikProps = {
    initialValues,
    validationSchema: transactionSchema,
    onSubmit: async ({ source: { id: sourceId }, target: { id: targetId }, ...otherValues }) =>
      createTransaction({
        source: sourceId,
        target: targetId,
        ...otherValues,
      }),
  };

  const snackbarProps = {
    successText: 'Transaction has been created!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Add Transaction"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
      isTopToolbar={isTopToolbar}
    />
  );
}

CreateTransaction.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateTransaction;
