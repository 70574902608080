import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

import { Version } from '../../../lib/components';

const useStyles = makeStyles(theme => ({
  footer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(1),
  },
}));

function Footer({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.footer, className)}>
      <Typography variant="body1">
        <Link component="a" href="https://uxjam.hu/" target="_blank">
          UX Jam
        </Link>{' '}
        2020-2023 -{' '}
        <Link component={'a'} href="/changelog">
          CHANGELOG
        </Link>
      </Typography>
      <Version />
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
