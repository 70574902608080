import React from 'react';

import { Box, Card, CardContent, Chip, Grid, Link, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { format } from 'date-fns';
import { useGetChangeLogs } from '../../hooks/useGetChangeLogs';
import { FillerSpinner } from '../../../lib/components';

function Changelog() {
  const { isError, isLoading, isSuccess, data } = useGetChangeLogs();

  return (
    <div>
      {isLoading && <FillerSpinner />}
      {isError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <p>Something went wrong!</p>
        </Alert>
      )}
      {isSuccess &&
        (data?.length > 0 ? (
          <Box>
            {data.map(log => (
              <Card variant="elevation" square style={{ margin: '16px 8px' }}>
                <CardContent>
                  <Typography variant="h3" component="h1" gutterBottom>
                    {`CHANGELOG-  ${format(new Date(log.deploymentTimestamp), 'yyyy-MM-dd')}`}
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }} variant="body1">
                    Deployment Time: {format(new Date(log.deploymentTimestamp), 'yyyy-MM-dd HH:ss')}
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ marginTop: "8px" }}
                  >
                    {log.changes.map(change => {
                      const { _id, topic, item, application, commit } = change;
                      const isBackend = application === "Backend";
                      const chipColor = isBackend ? "primary" : "secondary";
                      return (
                        <Grid
                          item
                          key={_id}
                          style={{
                            padding: "8px",
                            margin: '8px',
                            boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)'
                          }}
                          spacing={2}
                        >
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">Topic: <strong>{topic}</strong></Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">Modification: <strong>{item}</strong></Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">
                              Commit hash:{' '}
                              <Link
                                component={'a'}
                                href={`https://github.com/bence-sebok/elb-backoffice/commit/${commit}`}
                                target="_blank"
                              >
                                {commit}
                              </Link>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Chip color={chipColor} label={application} />
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <Alert severity="warn">
            <AlertTitle>CHANGELOG</AlertTitle>
            <p style={{ fontSize: '16px' }}>There is no new changelog to view!</p>
          </Alert>
        ))}
    </div>
  );
}

export default Changelog;
