import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useGetChangeLogs = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['changelog'],
    queryFn: async () => {
      const response = await axios.get(`${REACT_APP_SERVER_URL}changelog`);
      console.log(`${REACT_APP_SERVER_URL}changelog`);
      const data = await response.data;
      return data;
    },
    refetchOnWindowFocus: false,
  });
};
