import { COMPLETE_ORDER, GET_OPEN_ORDER } from '../graphql';

import { useCreateMutation } from '../../lib/hooks';

export default function useCompleteOrder() {
  return useCreateMutation(COMPLETE_ORDER, {
    refetchQueries: [{ query: GET_OPEN_ORDER }],
    awaitRefetchQueries: true,
  });
}

export function useCompleteOrderWithPaidAmounts(options) {
  return useCreateMutation(COMPLETE_ORDER, { variables: options });
}
