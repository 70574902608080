import React from 'react';
import * as PropTypes from 'prop-types';

import { updateELBCard as elbCardSchema } from '../../../../utils/validationSchemas';

import { useUpdateElbCard } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { ELBCardFormFields } from '..';

function UpdateELBCard({ id, expirationDate, physicalCardNumber, isDelivered }) {
  const dialogProps = {
    title: 'Update ELB Card',
    submitButtonText: 'Update ELB Card',
    fields: <ELBCardFormFields />,
  };

  const [update] = useUpdateElbCard(id);
  const initialValues = {
    expirationDate,
    physicalCardNumber: physicalCardNumber || '',
    isDelivered: isDelivered || false
  };
  const formikProps = {
    initialValues,
    validationSchema: elbCardSchema,
    onSubmit: update,
  };

  const snackbarProps = {
    successText: 'ELB card has been updated!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Update ELB Card"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

UpdateELBCard.propTypes = {
  id: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
};

export default UpdateELBCard;
