import React from 'react';

import { Collection } from '../../../common/components';
import { AuditLogsTable } from '..';

function AuditLogs() {
  return <Collection table={<AuditLogsTable />} />;
}

export default AuditLogs;
