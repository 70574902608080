import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_TICKET } from '../../graphql';

import { Document, WithDocumentQuery } from '../../../common/components';
import { TicketToolbar, TicketInfoCard } from '..';

function Ticket({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_TICKET}>
      {ticket => (
        <Document
          toolbar={<TicketToolbar ticket={ticket} />}
          infoCard={<TicketInfoCard ticket={ticket} />}
        />
      )}
    </WithDocumentQuery>
  );
}

Ticket.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Ticket;
