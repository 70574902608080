import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_TRANSACTIONS } from '../../graphql';

import { Collection } from '../../../common/components';
import { TransactionsToolbar, TransactionsTable } from '..';

function Transactions() {
  return (
    <Collection
      toolbar={<TransactionsToolbar refetchQueries={[getOperationName(GET_TRANSACTIONS)]} />}
      table={<TransactionsTable />}
    />
  );
}

export default Transactions;
