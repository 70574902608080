import React from 'react';
import { Content } from '../../../../common/components';
import { Grid } from '@material-ui/core';

function OrderDocumentWithProdcut({ rows }) {
  return (
    <Content>
      <Grid container spacing={2}>
        {rows.map(row => (
          <Grid item xs={12}>
            {row}
          </Grid>
        ))}
      </Grid>
    </Content>
  );
}

export default OrderDocumentWithProdcut;
