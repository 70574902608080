import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton, Snackbar as MaterialSnackbar, SnackbarContent } from '@material-ui/core';
import { CheckCircle, Error, Info, Close, Warning } from '@material-ui/icons';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const VARIANT_ICON = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Snackbar({ className, open: propOpen = false, message, variant, ...other }) {
  const classes = useStyles();
  const Icon = VARIANT_ICON[variant];

  const [open, setOpen] = useState(propOpen);

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <Close className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </MaterialSnackbar>
  );
}

Snackbar.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default Snackbar;
