import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import Form from '../Form';

function FormikForm({ className, children }) {
  const { handleSubmit } = useFormikContext();
  return (
    <Form className={className} onSubmit={handleSubmit}>
      {children}
    </Form>
  );
}

FormikForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FormikForm;
