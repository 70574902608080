import React, { useEffect, useState } from 'react';

import { FillerSpinner } from '../../../lib/components';
import { formatDate } from '../../../../utils/formatters';

import {
  CheckCircle as ApprovedPictureIcon,
  Cancel as DeclinedPictureIcon,
  Help as MissingApprovalIcon,
} from '@material-ui/icons';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogContent,
  Button,
} from '@material-ui/core';
import ProfileAction from '../ProfileAction';
import { useCheckPrivilege } from '../../../lib/hooks';
import Privileges from '../../../../utils/access-control/privileges.json';
import { Block as DeclinePictureIcon } from '@material-ui/icons';
import { useMutation } from '@apollo/react-hooks';
import {
  EVALUATE_PROFILE_PICTURE,
  GET_CUSTOMER_PROFILE_PICTURES,
} from '../../../online-elb-cards/graphql';
import { getOperationName } from '../../../../apollo/helpers';

function CheckElbCardInfoCard({
  data,
  isError,
  isLoading,
  showButtons,
  setShowButtons,
  approvedIcon,
  setApprovedIcon,
}) {
  const [evaluateProfilePicture] = useMutation(EVALUATE_PROFILE_PICTURE, {
    awaitRefetchQueries: true,
    onCompleted: data => {
      setShowButtons(false);
    },
    refetchQueries: [getOperationName(GET_CUSTOMER_PROFILE_PICTURES)],
  });
  const access = useCheckPrivilege(Privileges.OVERRIDE_ACCEPTED_PROFILE_PICTURE);
  const [overrideDeclineBtn, setOverrideDeclineBtn] = useState(false);
  const [overrideDecline, setOverrideDecline] = useState(false);

  const approved = data?.customer?.profilePicture?.approved || false;

  useEffect(() => {
    if (approved) {
      return setOverrideDeclineBtn(true);
    }
    if (approvedIcon) {
      return setOverrideDeclineBtn(true);
    }
  }, [approved, approvedIcon]);

  const rows = [
    {
      label: 'Type',
      value: data.type.name,
    },

    {
      label: 'Card Number',
      value: data.cardNumber,
    },
    {
      label: 'Online card number',
      value: data.onlineCardNumber ? data.onlineCardNumber : 'Not filled in yet',
    },
    {
      label: 'Expiration Date',
      value: data.expirationDate ? formatDate(data.expirationDate) : null,
    },
    {
      label: 'Status',
      value: data.status,
    },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const rows_profilePic = [
    {
      label: 'Customer',
      value: data.customer.fullName,
    },
    {
      label: 'Picture',
      value: data.customer?.profilePicture?.url ? (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box style={{ display: 'flex', justifyContent: 'center', margin: 8 }}>
            <img
              src={data?.customer?.profilePicture?.url}
              alt=""
              width="100px"
              onClick={() => handleOpen()}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          {!overrideDeclineBtn ? (
            <>
              <Typography>Click on the picture to approve or decline it</Typography>
            </>
          ) : overrideDecline ? (
            <Grid
              item
              container
              style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
            >
              <DeclinedPictureIcon htmlColor="red" />
            </Grid>
          ) : (
            <Grid
              item
              container
              style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
            >
              <ApprovedPictureIcon htmlColor="green" />
            </Grid>
          )}
          {access && (approved || approvedIcon) && !overrideDecline && (
            <>
              <Grid container direction="row" justify="center" width="100%">
                <Button
                  variant="contained"
                  startIcon={
                    <DeclinePictureIcon
                      htmlColor="white"
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    />
                  }
                  style={{
                    backgroundColor: '#d32f2f',
                    color: 'white',
                  }}
                  onClick={() => {
                    evaluateProfilePicture({
                      variables: {
                        id: data.customer.id,
                        approved: false,
                        overrideAccess: true,
                      },
                    });
                    setOverrideDecline(true);
                    setOverrideDeclineBtn(true);
                  }}
                >
                  Override Decline
                </Button>
              </Grid>
            </>
          )}
          <Dialog open={open} onClose={handleClose}>
            <DialogContent style={{ fontSize: '20px', textAlign: 'center' }}>
              <p>
                <i>{data.customer.fullName}</i>
              </p>
              <p>{data.onlineCardNumber}</p>
              <img src={data.customer?.profilePicture?.url} alt="" width="100%" />
              <Grid
                item
                container
                style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
              >
                {!data.customer?.profilePicture?.timeOfApproval && showButtons ? (
                  <MissingApprovalIcon htmlColor="orange" />
                ) : data.customer?.profilePicture.approved || approvedIcon ? (
                  <ApprovedPictureIcon htmlColor="green" />
                ) : (
                  <DeclinedPictureIcon htmlColor="red" />
                )}
              </Grid>

              {showButtons && (
                <ProfileAction
                  data={data}
                  setShowButtons={setShowButtons}
                  setApprovedIcon={setApprovedIcon}
                  setOverrideDeclineBtn={setOverrideDeclineBtn}
                />
              )}
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <p>
          <strong>No picture </strong>
        </p>
      ),
    },
  ];

  return (
    <>
      {isLoading && <FillerSpinner />}
      {isError && (
        <>
          <strong>Failed checking ELB Card</strong>
        </>
      )}
      <Card>
        <CardContent>
          <Typography variant="h3" component="h1" gutterBottom>
            {`ELB Card: ${data.cardNumber === 'ELB' ? data.onlineCardNumber : data.cardNumber} `}
          </Typography>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ padding: '8px' }}
            >
              {rows_profilePic.map(({ label, value }) => (
                <Grid item container key={label} style={{ justifyContent: 'center' }}>
                  <Grid item>
                    <Typography variant="body1">{value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ padding: '8px' }}
            >
              {rows.map(({ label, value }) => (
                <Grid item key={label}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">{label}:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <strong>{value}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CheckElbCardInfoCard;
