import React, { useState, useMemo } from 'react';

import { Table, CollectionTable } from '../../../common/components';
import { GET_CUSTOMER_PROFILE_PICTURES, EVALUATE_PROFILE_PICTURE } from '../../graphql';
import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from '../../../../apollo/helpers';

import {
  CheckCircle as ApprovedPictureIcon,
  Cancel as DeclinedPictureIcon,
  HowToReg as ApprovePictureIcon,
  Block as DeclinePictureIcon,
  Help as MissingApprovalIcon,
  History as HistoryIcon,
} from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isDevelopment } from '../../../../utils/is-env';

function OnlineELBCardsTable() {
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [originalProfilePicture, setOriginalProfilePicture] = useState(null);
  const [history, setHistory] = useState([]);

  const isMobileScreen = useMemo(() => window.innerWidth < 600, []);

  const handleClickOpen = pictureUrl => {
    setOriginalProfilePicture(pictureUrl);
    setOpen(true);
  };

  const handleClickHistoryOpen = history => {
    setHistory(history);
    setOpenHistory(true);
  };

  const handleHistoryClose = () => {
    setHistory([]);
    setOpenHistory(false);
  };

  const handleClose = () => {
    setOriginalProfilePicture(null);
    setOpen(false);
  };

  const [evaluateProfilePicture] = useMutation(EVALUATE_PROFILE_PICTURE, {
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(GET_CUSTOMER_PROFILE_PICTURES)],
  });

  const handlePictureClick = profilePicture => {
    if (profilePicture?.originalUrl) {
      handleClickOpen(profilePicture.originalUrl);
    } else {
      handleClickOpen(profilePicture.url);
    }
  };

  const columns = [
    {
      name: 'email',
      label: 'Email address',
    },
    {
      name: 'firstName',
      label: 'Name',
      options: {
        renderCell: (_, { firstName, lastName }) => {
          return `${firstName} ${lastName}`;
        },
      },
    },
    {
      name: 'picture',
      label: 'Picture',
      options: {
        renderCell: (_, { profilePicture }) => {
          return (
            <img
              src={profilePicture?.url}
              alt=""
              width="100px"
              onClick={() => handlePictureClick(profilePicture)}
              style={{ cursor: 'pointer' }}
            />
          );
        },
        empty: true,
        filter: false,
      },
    },
    {
      name: 'approval',
      label: 'Approval',
      options: {
        renderCell: (_, { profilePicture: { approved, timeOfApproval } }) => {
          const approvedIcon = <ApprovedPictureIcon htmlColor="green" />;
          const declinedIcon = <DeclinedPictureIcon htmlColor="red" />;
          const missingApprovalIcon = <MissingApprovalIcon htmlColor="orange" />;
          const missingApproval = timeOfApproval === null;
          return (
            <Box display="flex" justifycontent="flex-start" alignItems="center" padding="16px">
              {missingApproval ? missingApprovalIcon : approved ? approvedIcon : declinedIcon}
            </Box>
          );
        },
      },
    },
    {
      name: 'timeOfApproval',
      label: 'Evaluated at',
      options: {
        renderCell: (_, { profilePicture: { timeOfApproval } }) => {
          return timeOfApproval
            ? format(new Date(timeOfApproval), 'yyyy-MM-dd HH:mm:ss')
            : 'Not yet';
        },
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        renderCell: (_, { id, profilePicture }) => {
          return (
            <>
              <ApprovePictureIcon
                htmlColor="green"
                style={{
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
                onClick={() =>
                  evaluateProfilePicture({
                    variables: {
                      id: id,
                      approved: true,
                    },
                  })
                }
              />
              <DeclinePictureIcon
                htmlColor="red"
                style={{
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
                onClick={() =>
                  evaluateProfilePicture({
                    variables: {
                      id: id,
                      approved: false,
                    },
                  })
                }
              />
              <HistoryIcon
                htmlColor="black"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleClickHistoryOpen(profilePicture.history)}
              />
            </>
          );
        },
        empty: true,
        filter: false,
      },
    },
  ];

  return (
    <CollectionTable>
      <Table
        title="Online ELB cards: missing approval or declined pictures"
        collectionName="online-elb-cards"
        query={GET_CUSTOMER_PROFILE_PICTURES}
        columns={columns}
      />
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Full size profile picture</DialogTitle>
        <DialogContent
          style={{
            textAlign: 'center',
          }}
        >
          <img
            src={originalProfilePicture}
            alt=""
            style={
              isMobileScreen
                ? {
                    width: '100%',
                    objectFit: 'cover',
                  }
                : {
                    height: '700px',
                    objectFit: 'cover',
                  }
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={openHistory}
        onClose={handleHistoryClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Uploaded images history</DialogTitle>
        <DialogContent
          style={{
            textAlign: 'center',
          }}
        >
          {history?.length === 0 ? (
            <p>No history yet.</p>
          ) : (
            history?.map((url) => (
              <img src={isDevelopment() ? `https://storage.googleapis.com/online-elb-card-gcs-bucket/${url}` : `https://storage.cloud.google.com/online-elb-card-gcs-bucket/${url}`} width="200px" alt={url} />
            ))
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHistoryClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </CollectionTable>
  );
}

export default OnlineELBCardsTable;
