import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { normalize } from '../../../../utils/helpers';

import { useGetForms } from '../../hooks';

import { FormikAutocomplete as FAutocomplete } from '../../../lib/components';

function FormikFormAutocomplete({ name, InputProps = {}, ...rest }) {
  const { loading, data } = useGetForms();
  const forms = data ? data.forms : [];
  const formsMap = useMemo(() => normalize(forms), [forms]);
  const options = useMemo(() => forms.map(({ id }) => id), [forms]);

  const getOptionLabel = useCallback(value => (value ? formsMap[value]?.name : ''), [formsMap]);

  return (
    <FAutocomplete
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      loading={loading}
      InputProps={{
        label: 'Form',
        ...InputProps,
      }}
      {...rest}
    />
  );
}

FormikFormAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
};

export default FormikFormAutocomplete;
