import { useSnackbar as useNSSnackbar } from 'notistack';

export default function useSnackbar() {
  const { enqueueSnackbar: enqueueNSSnackbar, closeSnackbar } = useNSSnackbar();

  function enqueueSnackbar(message, variant, options = {}) {
    enqueueNSSnackbar(message, { variant, ...options });
  }

  return { enqueueSnackbar, closeSnackbar };
}
