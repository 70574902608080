import React from 'react';

import { Collection } from '../../../common/components';
import OnlineELBCardsTable from '../../../online-elb-cards/components/OnlineELBCardsTable/OnlineELBCardsTable';

function CustomersWithProfilePictures() {
  return (
    <Collection
      table={<OnlineELBCardsTable />}
    />
  );
}

export default CustomersWithProfilePictures;
