import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_ACCOUNT } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import { TransactionsTable } from '../../../transactions/components';
import { AccountInfoCard, AccountToolbar } from '..';

function Account({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_ACCOUNT}>
      {(account, transactions) => (
        <Document
          toolbar={<AccountToolbar account={account} />}
          infoCard={<AccountInfoCard account={account} />}
          table={<TransactionsTable transactions={transactions.entries} />}
        />
      )}
    </WithDocumentQuery>
  );
}

Account.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Account;
