import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatDateTime } from '../../../../utils/formatters';

import { InfoCard } from '../../../common/components';

function ELBCardTypeInfoCard({ elbCardType }) {
  const rows = [
    {
      label: 'Created at',
      value: formatDateTime(elbCardType.createdAt),
    },
    {
      label: 'Created by',
      value: routes.user.createLink(elbCardType.createdBy),
    },
    {
      label: 'Price',
      value: elbCardType.price,
    },
    {
      label: 'Sold amount',
      value: elbCardType.soldAmount,
    },
    {
      label: 'For Sale',
      value: elbCardType.forSale === true ? 'Yes' : 'No',
    },
  ];
  return <InfoCard title={elbCardType.name} rows={rows} />;
}

ELBCardTypeInfoCard.propTypes = {
  elbCardType: Types.elbCardType.isRequired,
};

export default ELBCardTypeInfoCard;
