import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  notFound: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    padding: theme.spacing(1),
  },
  content: {
    textAlign: 'center',
  },
}));

function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.notFound}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">404: The page you are looking for isn’t here</Typography>
            <Typography variant="subtitle2">
              You either tried some shady route or you came here by mistake. Whichever it is, try
              using the navigation
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default NotFound;
