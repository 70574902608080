import { isEmpty } from 'lodash';

function includesEveryPrivilege(userPrivileges, requiredPrivileges) {
  const rp = Array.isArray(requiredPrivileges) ? requiredPrivileges : [requiredPrivileges];
  return rp.every(p => userPrivileges.includes(p));
}

export function checkPrivilege(userPrivileges, requiredPrivilege) {
  if (!requiredPrivilege) return true;
  return userPrivileges.includes(requiredPrivilege);
}

export function checkPrivileges(userPrivileges, requiredPrivileges) {
  if (isEmpty(requiredPrivileges)) return true;
  return requiredPrivileges.map(rp => userPrivileges.includes(rp));
}

export function checkEveryPrivilege(userPrivileges, requiredPrivileges) {
  if (isEmpty(requiredPrivileges)) return true;
  return includesEveryPrivilege(requiredPrivileges, userPrivileges);
}
