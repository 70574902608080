import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useGetProducts } from '../../hooks';
import { Box, TextField } from '@material-ui/core';
import EventSummary from '../EventSummary';

function ProductsSelect({ type, onProductSelect }) {
  const values = {
    type,
  };
  const { isSuccess, data } = useGetProducts(values);
  const [value, setValue] = useState();

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box marginBottom={'8px'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        {isSuccess && (
          <Autocomplete
            id="select-type"
            options={data}
            getOptionLabel={option => option.name}
            value={value}
            onChange={async (event, newValue) => {
              setValue(newValue);
              console.log({ newValue });
              return onProductSelect(newValue);
            }}
            renderInput={param => (
              <TextField
                {...param}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '550px' }}
                label={type === 'ticket' ? 'Ticket Type' : 'ELB Card Type'}
              />
            )}
          />
        )}
      </Box>

      <Box marginBottom={'8px'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        {value?.eventId && <EventSummary eventId={value.eventId} />}
      </Box>
    </Box>
  );
}

export default ProductsSelect;
