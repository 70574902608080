import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import * as Types from '../../../../utils/propTypes';
import { ticketType as ticketTypeSchema } from '../../../../utils/validationSchemas';

import { useCreateTicketType, useUpdateTicketType } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import TicketTypeFormFields from '../TicketTypeFormFields';

function MutateTicketType({ eventId, ticketType = {} }) {
  const edit = !isEmpty(ticketType);
  const { id, name, expirationDate, limit, normalPrice, elbCardPrice, startSales } = ticketType;

  const [create] = useCreateTicketType(eventId);
  const [update] = useUpdateTicketType(id);

  const [inEventLimit, setInEventLimit] = useState(true);
  const savedLimitValue = limit?.toString() || '';
  const [ticketTypeLimit, setTicketTypeLimit] = useState(savedLimitValue);

  useEffect(() => {
    setInEventLimit(true);
    setTicketTypeLimit(savedLimitValue);
  }, []);

  useEffect(() => {
    if (inEventLimit === false) {
      setTicketTypeLimit('');
    }
  }, [inEventLimit]);

  const dialogProps = {
    title: edit ? 'Edit Ticket Type' : 'Add Ticket Type',
    submitButtonText: edit ? 'Edit Ticket Type' : 'Add Ticket Type',
    fields: (
      <TicketTypeFormFields
        create={!edit}
        inEventLimit={inEventLimit}
        setInEventLimit={checked => setInEventLimit(checked)}
        ticketTypeLimit={ticketTypeLimit}
        setTicketTypeLimit={limit => setTicketTypeLimit(limit)}
      />
    ),
  };

  const initialValues = {
    name: name || '',
    expirationDate: expirationDate || null,
    limit: limit || '',
    normalPrice: normalPrice || '',
    elbCardPrice: elbCardPrice || '',
    startSales: edit ? null : startSales || true,
  };
  const handleSubmit = edit
    ? async values => {
        const transformedValues = {
          ...values,
          limit: ticketTypeLimit === '' ? limit : parseInt(ticketTypeLimit),
        };
        update(transformedValues);
      }
    : async values => {
        const transformedValues = {
          ...values,
          limit: ticketTypeLimit === '' ? 0 : parseInt(ticketTypeLimit),
        };
        create(transformedValues);
      };
  const formikProps = {
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: ticketTypeSchema,
  };

  const snackbarProps = {
    successText: edit ? 'Ticket type has been edited!' : 'Ticket type has been created!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={edit ? 'Edit Ticket Type' : 'Add Ticket Type'}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

MutateTicketType.propTypes = {
  eventId: PropTypes.string,
  ticketType: Types.ticketType,
};

export default MutateTicketType;
