import { useEffect, useRef } from 'react';

export default function useEffectOnSuccess(
  { called, loading, error, data },
  callback,
  dependencyArray = [],
) {
  const latestCallback = useRef(callback);
  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  const success = Boolean(called && !loading && !error && data);
  useEffect(() => {
    if (success) {
      latestCallback.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, ...dependencyArray]);
}
