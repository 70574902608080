import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { account as accountSchema } from '../../../../utils/validationSchemas';

import { useUpdateAccount } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { AccountFormFields } from '..';

function UpdateAccount({ account: { id, name } }) {
  const dialogProps = {
    title: 'Edit Account',
    submitButtonText: 'Edit Account',
    fields: <AccountFormFields />,
  };
  const [updateAccount] = useUpdateAccount(id);

  const initialValues = {
    name,
  };
  const formikProps = {
    initialValues,
    onSubmit: async values => updateAccount(values),
    validationSchema: accountSchema,
  };

  const snackbarProps = {
    successText: 'Account was successfully edited!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Edit account"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

UpdateAccount.propTypes = {
  account: Types.account.isRequired,
};

export default UpdateAccount;
