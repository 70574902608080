import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { ButtonLink } from '../../../lib/components';
import { Toolbar } from '../../../common/components';
import { AddTicketToOrder, AddELBCardToOrder, ClearOrder } from '..';

function OrderToolbar({ order, defaultBillingCustomerForTickets }) {
  const { isEmpty } = order;
  return (
    <Toolbar>
      <ClearOrder order={order} />

      <AddTicketToOrder defaultBillingCustomerForTickets={defaultBillingCustomerForTickets} />
      <AddELBCardToOrder />

      <ButtonLink to="/order-summary" disabled={isEmpty} variant="contained" color="primary">
        Next
      </ButtonLink>
    </Toolbar>
  );
}

OrderToolbar.propTypes = {
  order: Types.order.isRequired,
};

export default OrderToolbar;
