import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

export default function useCreateMutation(mutation, options) {
  const [create, result] = useMutation(mutation, {
    awaitRefetchQueries: Boolean(options?.refetchQueries),
    ...options,
  });

  const simpleCreate = useCallback(
    async data =>
      create({
        variables: { data },
      }),
    [create],
  );

  return [simpleCreate, result];
}
