import React from 'react';

import { Toolbar } from '../../../../common/components';
import { ButtonLink } from '../../../../lib/components';
import FinalizeProductOrder from '../FinalizeProductOrder';

function OrderProductSummaryToolbar({ order, paidAmounts, terminal, paymentMode }) {
  return (
    <Toolbar>
      <ButtonLink to="/open-order-with-products">Back to edit</ButtonLink>
      <FinalizeProductOrder
        order={order}
        paidAmounts={paidAmounts}
        terminal={terminal}
        paymentMode={paymentMode}
      />
    </Toolbar>
  );
}

export default OrderProductSummaryToolbar;
