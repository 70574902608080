import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const TICKET_FRAGMENT = gql`
  fragment TicketFragment on Ticket {
    id
    elbCard {
      id
      cardNumber
    }
    event {
      id
      name
      ticketTypes {
        id
        name
        normalPrice
        elbCardPrice
        forSale
      }
      isExternal
    }
    ticketType {
      id
      name
      normalPrice
      elbCardPrice
    }
    ticketNumber
    ticketNumber2ndGen
    status
    soldAt
    soldBy {
      id
      fullName
    }
    customer {
      id
      fullName
      email
    }
    purchasedWithELBCard
    price
  }
`;

export const GET_TICKETS = gql`
  query GetTickets($options: OptionsInput) {
    tickets(options: $options) @connection(key: "tickets") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...TicketFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${TICKET_FRAGMENT}
`;

export const GET_TICKET = gql`
  query GetTicket($id: String!) {
    ticket(id: $id) {
      ...TicketFragment
    }
  }
  ${TICKET_FRAGMENT}
`;

export const ACTIVATE_TICKET = gql`
  mutation ActivateTicket($id: String!) {
    activateTicket(id: $id) {
      id
      status
    }
  }
`;

export const INACTIVATE_TICKET = gql`
  mutation InactivateTicket($id: String!) {
    inactivateTicket(id: $id) {
      id
      status
    }
  }
`;

export const GET_TICKET_DOWNLOAD_TOKEN = gql`
  query GET_TICKET_DOWNLOAD_TOKEN($id: String!) {
    getTicketDownloadToken(id: $id)
  }
`;
