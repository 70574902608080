import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_EVENTS } from '../../graphql';

import { Collection } from '../../../common/components';
import EventsToolbar from '../EventsToolbar';
import EventsTable from '../EventsTable';

function Events() {
  return (
    <Collection
      toolbar={<EventsToolbar refetchQueries={[getOperationName(GET_EVENTS)]} />}
      table={<EventsTable />}
    />
  );
}

export default Events;
