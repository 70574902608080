import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Grid, Button } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { globalELBCard as globalELBCardSchema } from '../../../../utils/validationSchemas';

import { UPDATE_SETTINGS } from '../../graphql';

import { useDialog, useEffectOnSuccess } from '../../../lib/hooks';

import {
  FormikForm as FForm,
  SubmitButton,
  FormikAsyncWithSnackbar as Formik,
} from '../../../lib/components';
import { ConfirmationDialog } from '../../../common/components';
import { GlobalELBCardFields } from '..';

function GlobalELBCardForm({ settings }) {
  const {
    billing: { manualBilling },
    elbCard: { defaultExpirationTime, billingAccount, formId },
  } = settings;

  const [isOpen, open, close] = useDialog();

  const [updateSettings, result] = useMutation(UPDATE_SETTINGS);
  const { loading } = result;

  const initialValues = {
    billingAccount: billingAccount ? billingAccount.id : 'manually',
    defaultExpirationTime,
    formId,
  };
  async function handleSubmit(values) {
    const elbCard = {
      ...values,
      billingAccount: values.billingAccount === 'manually' ? undefined : values.billingAccount,
    };
    await updateSettings({
      variables: {
        data: { elbCard, billing: { manualBilling } },
      },
    });
  }

  const buttonText = 'Save Changes';

  useEffectOnSuccess(result, close);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={globalELBCardSchema}
      onSubmit={handleSubmit}
      successText="ELB Card settings have been saved!"
    >
      {({ handleSubmit: handleFormikSubmit, values: { formId: newFormId } }) => (
        <FForm>
          <GlobalELBCardFields />

          <Grid container direction="row" justify="flex-end" alignItems="flex-start">
            <Grid item>
              {newFormId === formId ? (
                <SubmitButton>{buttonText}</SubmitButton>
              ) : (
                <Button color="primary" variant="contained" onClick={open}>
                  {buttonText}
                </Button>
              )}
            </Grid>
          </Grid>

          <ConfirmationDialog
            open={isOpen}
            loading={loading}
            onAccept={handleFormikSubmit}
            onClose={close}
            title="The ELB Card form has changed!"
            description="Any customer who submitted the previous form, but hasn't purchased their ELB Card yet, will have to fill out the new form! Are you sure you want to change the ELB Card form?"
            acceptText="Yes"
            declineText="No"
          />
        </FForm>
      )}
    </Formik>
  );
}

GlobalELBCardForm.propTypes = {
  settings: Types.settings.isRequired,
};

export default GlobalELBCardForm;
