import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarContent } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function FormError({ className, message }) {
  const classes = useStyles();
  return (
    <SnackbarContent
      className={clsx(classes.root, className)}
      message={
        <span className={classes.message}>
          <Error className={classes.icon} />
          {message}
        </span>
      }
    />
  );
}

FormError.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default FormError;
