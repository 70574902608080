import React from 'react';
import * as PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

import { DATE_FORMAT } from '../../../../utils/formatters';
import * as Types from '../../../../utils/propTypes';
import FormikFieldWithError from '../FormikFieldWithError';

function FormikDatePicker({ name, responseErrors = {}, placeholder, variant, ...rest }) {
  return (
    <FormikFieldWithError name={name} responseErrors={responseErrors}>
      {({ field, form, hasError, errorMessage }) => (
        <DatePicker
          {...field}
          onChange={e => form.setFieldValue(name, e)}
          error={hasError}
          helperText={errorMessage}
          placeholder={placeholder}
          format={DATE_FORMAT}
          inputVariant={variant}
          {...rest}
        />
      )}
    </FormikFieldWithError>
  );
}

FormikDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  responseErrors: Types.responseErrors,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
};

FormikDatePicker.defaultProps = {
  responseErrors: {},
  placeholder: null,
};

export default FormikDatePicker;
