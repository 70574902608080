import React, { useCallback } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';

import { useAddBillingCustomer } from '../../hooks';

import {
  FormikAsyncWithSnackbar as Formik,
  FormikForm as FForm,
  SubmitButton,
} from '../../../lib/components';
import { FormikCustomerAutocomplete as FCustomerAutocomplete } from '../../../customers/components';

function AddBillingCustomer({ customer }) {
  const [add] = useAddBillingCustomer();

  const initialValues = {
    customer: customer || null,
  };
  const handleSubmit = useCallback(async ({ customer: { id } }) => add(id), [add]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Billing Customer</Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          successText="Billing customer has been added!"
        >
          <FForm>
            <FCustomerAutocomplete
              name="customer"
              InputProps={{
                required: true,
                variant: 'outlined',
                fullWidth: true,
                margin: 'normal',
              }}
              isCustomersTable={false}
            />

            <SubmitButton>Save Billing Customer</SubmitButton>
          </FForm>
        </Formik>
      </CardContent>
    </Card>
  );
}

AddBillingCustomer.propTypes = {
  customer: Types.customer,
};

export default AddBillingCustomer;
