import React from 'react';
import { isEmpty } from 'lodash';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { validateTicket as validateTicketSchema } from '../../../../utils/validationSchemas';

import { useAddTicketToOrder, useUpdateOrderTicket } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { TicketFields } from '..';

function getTicketNumberValue({ event, ticketNumber }) {
  if (event?.isExternal) {
    return ticketNumber || '';
  }
  return '';
}

function MutateOrderTicket({ ticket = {} }) {
  const isNew = isEmpty(ticket);
  const { id, event, ticketType, customer, elbCardNumber } = ticket;

  const [add] = useAddTicketToOrder();
  const [edit] = useUpdateOrderTicket(id);

  const dialogProps = {
    title: `${isNew ? 'Add' : 'Edit'} Ticket`,
    submitButtonText: `${isNew ? 'Add' : 'Edit'} Ticket`,
    fields: <TicketFields />,
    fullWidth: true,
    maxWidth: 'md',
  };

  const formikProps = {
    initialValues: {
      event: event || null,
      ticketType: ticketType ? ticketType.id : '',
      customer: customer || null,
      withELBCard: Boolean(elbCardNumber),
      ticketNumber: getTicketNumberValue(ticket),
    },
    validationSchema: validateTicketSchema,
    onSubmit: async ({ event: { id: eventId }, customer: customerValue, ...otherValues }) => {
      const variables = {
        event: eventId,
        customer: customerValue?.id,
        ...otherValues,
      };
      if (isNew) {
        return add(variables);
      }
      return edit(variables);
    },
  };

  const snackbarProps = {
    successText: 'Valid Ticket!',
  };

  const actionButtonProps = {
    variant: 'outlined',
    color: isNew ? 'primary' : 'default',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={isNew ? 'Add Ticket' : <EditIcon />}
      actionButtonProps={actionButtonProps}
      buttonComponent={isNew ? undefined : IconButton}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

MutateOrderTicket.propTypes = {
  ticket: Types.ticket,
};

export default MutateOrderTicket;
