import { RESET_PASSWORD } from '../graphql';

import useAuthMutation from './useAuthMutation';

export default function useResetPassword(resetPasswordToken) {
  const [resetPassword, res, stored] = useAuthMutation(RESET_PASSWORD);

  async function simpleResetPassword(values) {
    await resetPassword({ data: { resetPasswordToken, ...values } });
  }

  return [simpleResetPassword, res, stored];
}
