import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_BILLING_ACCOUNTS } from '../../graphql';

import { Collection } from '../../../common/components';
import BillingAccountsToolbar from '../BillingAccountsToolbar';
import BillingAccountsTable from '../BillingAccountsTable';

function BillingAccounts() {
  return (
    <Collection
      toolbar={<BillingAccountsToolbar refetchQueries={[getOperationName(GET_BILLING_ACCOUNTS)]} />}
      table={<BillingAccountsTable />}
    />
  );
}

export default BillingAccounts;
