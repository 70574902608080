import React from 'react';

import { InfoCard } from '../../../common/components';
import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

function TerminalInfoCard({ terminal: { name, account } }) {
  const rows = [
    {
      label: 'Name',
      value: name,
    },
    {
      label: 'Account',
      value: account
        ? routes.account.createLink(account.id, account.name, account.name)
        : 'No assigned account',
    },
  ];
  return <InfoCard title={'Terminal details'} rows={rows} />;
}

TerminalInfoCard.propTypes = {
  terminal: Types.terminal.isRequired,
};

export default TerminalInfoCard;
