import { useCallback } from 'react';

import { CREATE_TICKET_TYPE, GET_EVENT } from '../graphql';

import { useCreateMutation } from '../../lib/hooks';

export default function useCreateTicketType(eventId) {
  const [create, result] = useCreateMutation(CREATE_TICKET_TYPE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_EVENT, variables: { id: eventId } }],
  });

  const updateTicketType = useCallback(async data => create({ eventId, ...data }), [
    eventId,
    create,
  ]);

  return [updateTicketType, result];
}

export function useCreateTicketTypeWithoutRefetch() {
  const [create, result] = useCreateMutation(CREATE_TICKET_TYPE);

  const createSingleTicketType = useCallback(async (data) => create({ ...data }), [
    create,
  ]);

  return [createSingleTicketType, result];
}
