import React from 'react';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import useRemoveProductFromOrder from '../../../hooks/useRemoveProductFromOrder';
//import { useRemoveElbCardFromOrder, useRemoveTicketFromOrder } from '../../../hooks';

function RemoveProductFromOrder({ type, id }) {
  //   const [removeTicket] = useRemoveTicketFromOrder(id);
  //   const [removeElbCard] = useRemoveElbCardFromOrder(id);
  const [removeProduct] = useRemoveProductFromOrder(id);

  function handleClick() {
    //TODO: When we save elbcards and tickets in the collection we have to remove this too
    // if (type === 'ticket') {
    //   removeTicket();
    // }
    // if (type === 'elb-card') {
    //   removeElbCard();
    // }
    removeProduct();
  }

  return (
    <IconButton onClick={handleClick}>
      <DeleteIcon />
    </IconButton>
  );
}

export default RemoveProductFromOrder;
