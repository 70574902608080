import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useGetBillingAccounts } from '../../hooks';

import { FormikSelect as FSelect } from '../../../lib/components';

const MANUAL_BILLING_OPTION = { value: 'manually', label: 'Manually' };

function FormikBillingAccountSelect({ name, label = 'Billing Account', ...rest }) {
  const { loading, data } = useGetBillingAccounts({ variables: { options: {} } });
  const billingAccounts = useMemo(() => (data ? data.billingAccounts.entries : []), [data]);

  const options = useMemo(
    () => [
      MANUAL_BILLING_OPTION,
      ...billingAccounts
        .filter(({ active }) => active)
        .map(({ id: value, name: baName }) => ({ value, label: baName })),
    ],
    [billingAccounts],
  );

  return <FSelect name={name} options={options} label={label} loading={loading} {...rest} />;
}

FormikBillingAccountSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FormikBillingAccountSelect;
