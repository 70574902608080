import { useCallback } from 'react';

import { useMutation } from '@apollo/react-hooks';

export default function useUpdateMutation(mutation, id) {
  const [update, result] = useMutation(mutation);

  const simpleUpdate = useCallback(async data => update({ variables: { data: { id, ...data } } }), [
    id,
    update,
  ]);

  return [simpleUpdate, result];
}
