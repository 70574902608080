import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const BILLING_ACCOUNT_FRAGMENT = gql`
  fragment BillingAccountFragment on BillingAccount {
    id
    name
    publicKey
    privateKey
    active
    createdAt
    createdBy {
      id
      fullName
    }
  }
`;

export const CREATE_BILLING_ACCOUNT = gql`
  mutation CreateBillingAccount($data: CreateBillingAccountInput!) {
    createBillingAccount(data: $data) {
      ...BillingAccountFragment
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const UPDATE_BILLING_ACCOUNT = gql`
  mutation UpdateBillingAccount($data: UpdateBillingAccountInput) {
    updateBillingAccount(data: $data) {
      ...BillingAccountFragment
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const ACTIVATE_BILLING_ACCOUNT = gql`
  mutation ActivateBillingAccount($id: String!) {
    activateBillingAccount(id: $id) {
      ...BillingAccountFragment
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const INACTIVATE_BILLING_ACCOUNT = gql`
  mutation InactivateBillingAccount($id: String!) {
    inactivateBillingAccount(id: $id) {
      ...BillingAccountFragment
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const GET_BILLING_ACCOUNTS = gql`
  query GetBillingAccounts($options: OptionsInput) {
    billingAccounts(options: $options) @connection(key: "billingAccounts") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...BillingAccountFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${BILLING_ACCOUNT_FRAGMENT}
`;

export const GET_BILLING_ACCOUNT = gql`
  query GetBillingAccount($id: String!) {
    billingAccount(id: $id) {
      ...BillingAccountFragment
    }

    getEventsByBillingAccount(id: $id, options: {}) {
      entries {
        id
        name
        forSale
        from
        until
        location
        ticketLimit
        totalSales
      }
    }
  }
  ${BILLING_ACCOUNT_FRAGMENT}
`;
