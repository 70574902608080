import React from 'react';

import { Toolbar } from '../../../common/components';
import ActivateAccount from '../ActivateAccount';
import InactivateAccountWithBalance from '../InactivateAccountWithBalance';
import InactivateAccountWithZeroBalance from '../InactivateAccountWithZeroBalance';
import UpdateAccount from '../UpdateAccount/UpdateAccount';
import * as Types from '../../../../utils/propTypes';

function AccountToolBar({ account }) {
  const { id, name, active, balance } = account;

  const inactivateAccountButton =
    balance === 0 ? (
      <InactivateAccountWithZeroBalance id={id} name={name} />
    ) : (
      <InactivateAccountWithBalance />
    );

  return (
    <Toolbar>
      {active ? inactivateAccountButton : <ActivateAccount id={id} />}
      <UpdateAccount account={account} />
    </Toolbar>
  );
}

AccountToolBar.propTypes = {
  account: Types.account.isRequired,
};

export default AccountToolBar;
