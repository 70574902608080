import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { ButtonWithLoading } from '../../../lib/components';
import { Dialog } from '..';

function ConfirmationDialog({
  open,
  onAccept,
  loading,
  onClose,
  onDecline = onClose,
  title = 'Are you sure?',
  description = '',
  acceptText = 'Accept',
  declineText = 'Cancel',
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onDecline} color="primary">
          {declineText}
        </Button>
        <ButtonWithLoading
          loading={loading}
          variant="contained"
          color="primary"
          type="submit"
          onClick={onAccept}
        >
          {acceptText}
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onDecline: PropTypes.func,
  title: PropTypes.node,
  description: PropTypes.node,
  acceptText: PropTypes.node,
  declineText: PropTypes.node,
};

export default ConfirmationDialog;
