import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { CreateEvent } from '..';

function EventsToolbar({ refetchQueries }) {
  const access = useCheckPrivilege(Privileges.CREATE_EVENT);
  if (!access) return null;
  return (
    <Toolbar>
      <CreateEvent refetchQueries={refetchQueries} />
    </Toolbar>
  );
}

EventsToolbar.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default EventsToolbar;
