import React from 'react';

import * as Types from '../../../../utils/propTypes';

import MutateTerminal from '../MutateTerminal/MutateTerminal';

function CreateTerminal({ refetchQueries }) {
  return <MutateTerminal refetchQueries={refetchQueries} />;
}

CreateTerminal.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateTerminal;
