import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import CreateOrEditProduct from '../CreateOrEditProduct/CreateOrEditProduct';
import { useCreateProduct } from '../../hooks';
import { CloseOutlined } from '@material-ui/icons';

function CreateProductDialog({ eventId, initialType }) {
  const [open, setOpen] = useState(false);
  const { isError, error, isLoading, isSuccess, mutate } = useCreateProduct();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="contained" color="primary" style={{ margin: '8px' }} onClick={handleOpen}>
        {eventId ? 'Create Ticket Product' : 'Create Product (open modal)'}
      </Button>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Grid container justify="space-between">
            <Typography>Create Product</Typography>
            <CloseOutlined onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <CreateOrEditProduct
            error={error}
            isError={isError}
            isSuccess={isSuccess}
            isLoading={isLoading}
            mutate={mutate}
            eventId={eventId}
            initialType={initialType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateProductDialog;
