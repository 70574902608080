import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import * as yup from 'yup';

import * as Types from '../../../../utils/propTypes';
import * as schemas from '../../../../utils/validationSchemas';

import {
  FormikForm as FForm,
  FormikAsync as Formik,
  FormikTextField as FTextField,
  SubmitButton,
  FormikFormError as FFormError,
} from '../../../lib/components';
import { Dialog } from '..';

const validationSchema = yup.object().shape({
  title: schemas.spreadSheetTitle.required('Title is required!'),
});

function ExportDialog({ result, modal, isExporting, title, onSubmit }) {
  const { error, data } = result;
  const { isOpen, handleClose } = modal;

  const initialValues = {
    title,
  };

  function renderDialogContent() {
    // Render error content if the request has failed
    if (error) {
      return (
        <DialogContent>
          <DialogContentText>Something went wrong! :(</DialogContentText>
        </DialogContent>
      );
    }

    // Render the new spreadsheet's URL if the request has been successful
    if (data && isExporting) {
      return (
        <DialogContent>
          <DialogContentText>
            <a href={data} target="_blank" rel="noopener noreferrer">
              Click here to open your new Spreadsheet!
            </a>
          </DialogContentText>
        </DialogContent>
      );
    }

    // Render form content if no request has been made yet
    return (
      <>
        <DialogContent>
          <DialogContentText>You can change the title of the Spreadsheet here.</DialogContentText>
          <FTextField
            name="title"
            required
            autoFocus
            label="Title of the new Spreadsheet"
            fullWidth
            margin="dense"
          />

          <FFormError />
        </DialogContent>
        <DialogActions>
          <SubmitButton>Export</SubmitButton>
        </DialogActions>
      </>
    );
  }
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Export</DialogTitle>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <FForm>{renderDialogContent()}</FForm>
      </Formik>
    </Dialog>
  );
}

ExportDialog.propTypes = {
  result: PropTypes.shape({
    error: Types.responseErrors,
    data: PropTypes.string,
  }).isRequired,
  isExporting: PropTypes.bool.isRequired,
  modal: PropTypes.exact({
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
  }).isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ExportDialog;
