import React from 'react';
import * as PropTypes from 'prop-types';

import { profile as profileSchema } from '../../../../utils/validationSchemas';
import { useUpdateUser } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { UserFormFields } from '..';

function UpdateProfile({ id, firstName, lastName }) {
  const dialogProps = {
    title: 'Edit Profile',
    submitButtonText: 'Edit Profile',
    fields: <UserFormFields profile edit />,
  };

  const [updateUser] = useUpdateUser(id);
  const initialValues = {
    firstName,
    lastName,
  };
  const formikProps = {
    initialValues,
    validationSchema: profileSchema,
    onSubmit: updateUser,
  };

  const snackbarProps = {
    successText: 'Your profile was successfully updated',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Edit Profile"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

UpdateProfile.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default UpdateProfile;
