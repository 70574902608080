import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { MutateEvent } from '..';

function CreateEvent({ isTopToolbar = false, refetchQueries }) {
  return <MutateEvent isTopToolbar={isTopToolbar} refetchQueries={refetchQueries} />;
}

CreateEvent.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateEvent;
