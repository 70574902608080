import { useMutation } from '@apollo/react-hooks';

import { useEffectOnSuccess } from '../../lib/hooks';

import useStoreCurrentUser from './useStoreCurrentUser';

export default function useAuthMutation(mutation) {
  const [storeUser, stored] = useStoreCurrentUser();

  const [mutate, result] = useMutation(mutation);
  const { data } = result;

  async function simpleMutate(variables) {
    await mutate({ variables });
  }

  useEffectOnSuccess(result, () => {
    const user = Object.values(data)[0];
    storeUser(user);
  });

  return [simpleMutate, result, stored];
}
