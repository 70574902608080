import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import UpdateTerminal from '../UpdateTerminal/UpdateTerminal';

function TerminalToolbar({ terminal }) {
  const access = useCheckPrivilege(Privileges.UPDATE_ACCOUNT);
  if (!access) return null;

  return (
    <Toolbar>
      <UpdateTerminal terminal={terminal} />
    </Toolbar>
  );
}

TerminalToolbar.propTypes = {
  terminal: Types.elbCardType.isRequired,
};

export default TerminalToolbar;
