import { useQuery } from '@tanstack/react-query';
import { PAYMENTS_API_ENDPOINTS, PAYMENT_API_AXIOS_INSTANCE } from '../payments.constants';


export const useGetPayments = values => {
  return useQuery({
    queryKey: ['payments', values],
    queryFn: async () => {
      const { search, page, status } = values;
      const response = await PAYMENT_API_AXIOS_INSTANCE.get(
        `/${PAYMENTS_API_ENDPOINTS.PAYMENTS_CONTROLLER_BASE_URL}?search=${search}&page=${page}&status=${status}`,
      );
      const data = await response.data;
      return data;
    },
    refetchOnWindowFocus: false,
  });
};
