import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel, TextField } from '@material-ui/core';
import { dropRightWhile } from 'lodash';

import * as Types from '../../../../../../utils/propTypes';

function TableNumberRangeFilter({ column, filters, index, onChange }) {
  const handleChange = useCallback(
    filterIndex => ({ target: { value } }) => {
      const newFilters = [...filters];

      const parsed = Number.parseFloat(value);
      newFilters[filterIndex] = Number.isNaN(parsed) ? undefined : parsed;

      const sanitizedFilters = dropRightWhile(newFilters, filter => filter === undefined);
      onChange(sanitizedFilters, index, column);
    },
    [column, filters, index, onChange],
  );

  return (
    <div>
      <FormLabel>{column.label}</FormLabel>
      <FormGroup row>
        <TextField
          value={filters[0] || ''}
          onChange={handleChange(0)}
          type="number"
          label="min"
          style={{ width: '45%', marginRight: '5%' }}
        />
        <TextField
          value={filters[1] || ''}
          onChange={handleChange(1)}
          type="number"
          label="max"
          style={{ width: '45%' }}
        />
      </FormGroup>
    </div>
  );
}

TableNumberRangeFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(PropTypes.number).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TableNumberRangeFilter;
