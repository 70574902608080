import React from 'react';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { START_SALE_FOR_EVENT } from '../../graphql';

import { ActionButton } from '../../../common/components';

function StartSaleForEvent({ id }) {
  const [startSaleForEvent, result] = useMutation(START_SALE_FOR_EVENT, {
    variables: { id },
  });
  return (
    <ActionButton
      action={startSaleForEvent}
      result={result}
      successMessage="Sale has been started for the event!"
    >
      Start Sales
    </ActionButton>
  );
}

StartSaleForEvent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default StartSaleForEvent;
