import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { Table, CollectionTable } from '../../../common/components';

function TicketTypesTable({ event, inline = false }) {
  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filterType: 'textField',
        renderCell: (value, { id }) =>
          routes.ticketType.createLink(id, value, event.id, event.name),
      },
    },
    {
      name: 'normalPrice',
      label: 'Price',
      type: 'currency',
    },
    {
      name: 'elbCardPrice',
      label: 'ELB Card Price',
      type: 'currency',
    },
    {
      name: 'ticketStats.active',
      label: 'Active',
      type: 'number',
    },
    {
      name: 'ticketStats.reserved',
      label: 'Reserved',
      type: 'number',
    },
    {
      name: 'limit',
      label: 'Limit',
      type: 'number',
      options: {
        renderCell: value => value || '∞',
      },
    },
    {
      name: 'ticketStats.sold',
      label: 'Sold',
      type: 'number',
    },
    {
      name: 'totalSales',
      label: 'Sales',
      type: 'currency',
    },
    {
      name: 'expirationDate',
      label: 'Expiration Date',
      type: 'dateTime',
      options: {
        display: false,
      },
    },
    {
      name: 'forSale',
      label: 'For Sale',
      options: {
        renderCell: forSale => (forSale ? 'Yes' : 'No'),
      },
    },
  ];

  return (
    <CollectionTable>
      <Table title="Ticket Types List" data={event.ticketTypes} columns={columns} inline={inline} />
    </CollectionTable>
  );
}

TicketTypesTable.propTypes = {
  event: Types.event.isRequired,
  inline: PropTypes.bool,
};

export default TicketTypesTable;
