import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';

import { GET_SETTINGS } from '../../graphql';

import { FillerSpinner } from '../../../lib/components';
import { Content } from '../../../common/components';
import { GlobalBilling, GlobalELBCard } from '..';

function Settings() {
  const { loading, data } = useQuery(GET_SETTINGS);

  if (loading) return <FillerSpinner />;

  const { settings } = data;

  return (
    <Content>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item sm={4} xs={12}>
          <GlobalELBCard settings={settings} />
        </Grid>

        <Grid item sm={4} xs={12}>
          <GlobalBilling settings={settings} />
        </Grid>
      </Grid>
    </Content>
  );
}

export default Settings;
