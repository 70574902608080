import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatDate, formatDateTime } from '../../../../utils/formatters';

import { InfoCard } from '../../../common/components';

function ELBCardInfoCard({
  elbCard: { cardNumber, createdAt, expirationDate, status, soldBy, type, customer, physicalCardNumber, isDelivered, onlineCardNumber },
}) {
  const rows = [
    {
      label: 'Type',
      value: routes.elbCardType.createLink(type.id, type.name),
    },
    {
      label: 'Customer',
      value: routes.customer.createLink(customer.id, customer.fullName),
    },
    {
      label: 'Card Number',
      value: cardNumber,
    },
    {
      label: 'Physical card number',
      value: physicalCardNumber ? physicalCardNumber : "Not filled in yet",
    },
    {
      label: 'Online card number',
      value: onlineCardNumber ? onlineCardNumber : "Not filled in yet",
    },
    {
      label: 'Delivered to the customer',
      value: isDelivered ? "Delivered" : "Not delivered yet",
    },
    {
      label: 'Created at',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Expiration Date',
      value: expirationDate ? formatDate(expirationDate) : null,
    },
    {
      label: 'Status',
      value: status,
    },
    {
      label: 'Sold by',
      value: soldBy ? routes.user.createLink(soldBy) : null,
    },
  ];
  return <InfoCard title={cardNumber} rows={rows} />;
}

ELBCardInfoCard.propTypes = {
  elbCard: Types.elbCard.isRequired,
};

export default ELBCardInfoCard;
