import React from 'react';
import * as PropTypes from 'prop-types';

import { updateELBCard as elbCardSchema } from '../../../../utils/validationSchemas';

import { useActivateElbCard } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { ELBCardFormFields } from '..';

function ActivateELBCard({ id, expirationDate }) {
  const dialogProps = {
    title: 'Activate ELB Card',
    submitButtonText: 'Save Changes and Activate',
    fields: <ELBCardFormFields />,
  };

  const [activate] = useActivateElbCard(id);

  const initialValues = {
    expirationDate: new Date(expirationDate),
  };
  const formikProps = {
    initialValues,
    validationSchema: elbCardSchema,
    onSubmit: activate,
  };

  const snackbarProps = {
    successText: 'ELB card has been activated!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Activate Card"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

ActivateELBCard.propTypes = {
  id: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
};

export default ActivateELBCard;
