import React from 'react';

import { InfoCard } from '../../../common/components';
import * as Types from '../../../../utils/propTypes';
import { formatForint, formatDate } from '../../../../utils/formatters';

function TicketTypeInfoCard({
  ticketType: {
    name,
    forSale,
    expirationDate,
    normalPrice,
    elbCardPrice,
    limit,
    ticketStats,
    totalSales,
  },
}) {
  const rows = [
    {
      label: 'For Sale',
      value: forSale ? 'Yes' : 'No',
    },
    {
      label: 'Expiration Date',
      value: expirationDate ? formatDate(expirationDate) : '',
    },
    {
      label: 'Price',
      value: normalPrice ? formatForint(normalPrice) : '',
    },
    {
      label: 'Price with ELB Card',
      value: elbCardPrice ? formatForint(elbCardPrice) : '',
    },
    {
      label: 'Active',
      value: ticketStats.active,
    },
    {
      label: 'Reserved',
      value: ticketStats.reserved,
    },
    {
      label: 'Limit',
      value: limit || '∞',
    },
    {
      label: 'Sold',
      value: ticketStats.sold,
    },
    {
      label: 'Sales',
      value: formatForint(totalSales),
    },
  ];
  return <InfoCard title={name} rows={rows} />;
}

TicketTypeInfoCard.propTypes = {
  ticketType: Types.ticketType.isRequired,
};

export default TicketTypeInfoCard;
