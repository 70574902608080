import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as Types from '../../../../utils/propTypes';

import { useEffectOnSuccess } from '../../../lib/hooks';
import { useCompleteOrderWithPaidAmounts } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';
import { PaymentModes } from '../OrderChangeCalculator/OrderChangeCalculator';

function FinalizeOrder({ order, paidAmounts, terminal, paymentMode }) {
  const history = useHistory();

  const [completeOrderWithPaidAmounts, res] = useCompleteOrderWithPaidAmounts({
    payment: {
      cashSubtotal: paidAmounts.cash,
      cardSubtotal: paidAmounts.card,
    },
    terminal,
  });

  useEffectOnSuccess(res, () => {
    history.push('/order-edit');
  });

  const buttonProps = {
    variant: 'contained',
    color: 'primary',
    disabled: order.isEmpty,
  };

  const mapAmountFromPaymentMode = () => {
    if (paymentMode === PaymentModes.CASH) {
      return {
        payment: {
          cashSubtotal: fullPrice,
          cardSubtotal: 0,
        },
        terminal,
      };
    } else if (paymentMode === PaymentModes.CARD) {
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: fullPrice,
        },
        terminal,
      };
    } else if (paymentMode === PaymentModes.MIXED) {
      return {
        payment: {
          cashSubtotal: parseInt(paidAmounts.cash),
          cardSubtotal: parseInt(paidAmounts.card),
        },
        terminal,
      };
    } else {
      // We should not be here in the else branch
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: 0,
        },
        terminal,
      };
    }
  };

  const [fullPrice, setFullPrice] = useState(0);
  useEffect(() => {
    const ticketsPrice = order.tickets
      .filter(t => t.status === 'Pending')
      .reduce((sum, { price }) => sum + price, 0);
    const elbCardsPrice = order.elbCards
      .filter(e => e.status === 'Pending')
      .reduce((sum, { type: { price } }) => sum + price, 0);
    setFullPrice(elbCardsPrice + ticketsPrice);
  }, [order, paidAmounts]);

  const calculateDisabledStateOfButton = () => {
    switch (paymentMode) {
      case PaymentModes.CASH: {
        return false;
      }
      case PaymentModes.CARD: {
        return false;
      }
      case PaymentModes.MIXED: {
        const amountsEquality =
          parseInt(paidAmounts.cash) + parseInt(paidAmounts.card) === fullPrice;
        const result = !(amountsEquality && terminal !== null);
        return result;
      }
      default:
        return false;
    }
  };

  return (
    <ActionButtonWithConfirmation
      action={() => completeOrderWithPaidAmounts(mapAmountFromPaymentMode())}
      result={res}
      buttonText="Finalize"
      buttonProps={buttonProps}
      successMessage="Order has been completed!"
      disabled={calculateDisabledStateOfButton()}
    />
  );
}

FinalizeOrder.propTypes = {
  order: Types.order.isRequired,
};

export default FinalizeOrder;
