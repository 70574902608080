export const SUPPORTED_PRODUCT_TYPES = {
    TICKET: "ticket",
    ELB_CARD: "elb-card"
};

export const SUPPORTED_PRODUCT_TYPES_ARRAY = [
    SUPPORTED_PRODUCT_TYPES.TICKET,
    SUPPORTED_PRODUCT_TYPES.ELB_CARD
];

export function getHumanReadableProductType(machineName) {
    switch (machineName) {
        case "ticket": return "Ticket";
        case "elb-card": return "ELB card";
        default: return "Unsupported product type";
    }
}