import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Privileges from '../../../../utils/access-control/privileges.json';

import { useGetCurrentUser } from '../../../auth/hooks';
import { useCheckPrivilege } from '../../hooks';

function RouteWithLayout({
  layout: Layout,
  component: Component,
  isProtected = false,
  privilege: requiredPrivilege,
  ...rest
}) {
  const { data } = useGetCurrentUser();
  const isLoggedIn = Boolean(data && data.currentUser);

  const access = useCheckPrivilege(requiredPrivilege);

  // Check if the route is protected
  if (isProtected) {
    // Redirect to login if current user is not logged in
    if (!isLoggedIn) return <Redirect to="/login" />;
    // Redirect to 404 if user is not allowed to access the route
    if (!access) return <Redirect to="/404a" />;
  }

  // Redirect to dashboard if a user is already logged in but trying to access an unprotected page
  if (!isProtected && isLoggedIn) return <Redirect to="/dashboard" />;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout {...matchProps}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}

RouteWithLayout.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func.isRequired,
  isProtected: PropTypes.bool,
  path: PropTypes.string.isRequired,
  privilege: PropTypes.oneOf(Object.values(Privileges)),
};

export default RouteWithLayout;
