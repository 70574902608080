import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export default function useReadElbCard(cardNumber, setShowButtons, setApprovedIcon) {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useMutation({
    mutationKey: ['read-elb-card', cardNumber],
    mutationFn: async searchText => {
      searchText = cardNumber;
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}checking/search?searchText=${searchText}`,
      );
      const data = await response.data;
      return data;
    },
    onSuccess: () => {
      setShowButtons(true);
      setApprovedIcon(false);
    },
    refetchOnWindowFocus: false,
  });
}
