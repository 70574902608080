import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { MutateUser } from '..';

function CreateUser({ refetchQueries }) {
  return <MutateUser refetchQueries={refetchQueries} />;
}

CreateUser.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateUser;
