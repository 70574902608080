import React from 'react';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { START_SALE_FOR_TICKET_TYPE } from '../../graphql';

import { ActionButton } from '../../../common/components';

function StartSaleForTicketType({ id }) {
  const [startSaleForTicketType, result] = useMutation(START_SALE_FOR_TICKET_TYPE, {
    variables: { id },
  });
  return (
    <ActionButton
      action={startSaleForTicketType}
      result={result}
      successMessage="Sales has been started for the ticket type!"
    >
      Start Sales
    </ActionButton>
  );
}

StartSaleForTicketType.propTypes = {
  id: PropTypes.string.isRequired,
};

export default StartSaleForTicketType;
