import { useMutation } from '@apollo/react-hooks';

import { SEND_RESET_PASSWORD_MAIL } from '../graphql';

export default function useSendPasswordResetEmail() {
  const [sendMail, res] = useMutation(SEND_RESET_PASSWORD_MAIL);

  async function simpleSendMail({ email }) {
    await sendMail({ variables: { email } });
  }

  return [simpleSendMail, res];
}
