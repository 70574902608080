import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { useClearOrder } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function ClearOrder({ order: { customer, tickets, elbCards } }) {
  const [clearOrder, res] = useClearOrder();

  const buttonProps = {
    variant: 'text',
    color: 'default',
    disabled: !customer && tickets.length === 0 && elbCards.length === 0,
  };

  return (
    <ActionButtonWithConfirmation
      action={clearOrder}
      result={res}
      title="Are you sure you want to clear the order?"
      buttonText="Clear Order"
      buttonProps={buttonProps}
      successMessage="Order has been cleared!"
    />
  );
}

ClearOrder.propTypes = {
  order: Types.order.isRequired,
};

export default ClearOrder;
