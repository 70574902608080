import { useState } from 'react';
import { isEmpty } from 'lodash';

export default function useDisplayedColumns(columns) {
  const [displayedColumns, setDisplayedColumns] = useState(() =>
    columns
      .filter(({ options }) => isEmpty(options) || options.display === undefined || options.display)
      .map(({ name }) => name),
  );

  function show(column) {
    setDisplayedColumns([...displayedColumns, column]);
  }
  function hide(column) {
    setDisplayedColumns(displayedColumns.filter(c => c !== column));
  }

  return [displayedColumns, show, hide];
}
