import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';

function CustomerFields() {
  return (
    <>
      <FTextField
        name="firstName"
        type="text"
        required
        autoFocus
        label="First Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FTextField
        name="lastName"
        type="text"
        required
        label="Last Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FTextField
        name="email"
        type="email"
        required
        label="Email Address"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FFormError />
    </>
  );
}

export default CustomerFields;
