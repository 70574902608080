import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';

import { ButtonWithLoading } from '../../../lib/components';
import { useDialog } from '../../../lib/hooks';
import { DialogForm } from '..';

function ActionButtonWithDialogForm({
  isTopToolbar = false,
  dialogProps,
  formikProps,
  loading = false,
  snackbarProps = {},
  actionButtonText = 'Action',
  actionButtonProps = {},
  buttonComponent: Button = ButtonWithLoading,
}) {
  const [open, handleOpen, handleClose] = useDialog(false);

  const color = isTopToolbar ? 'inherit' : actionButtonProps.color || 'primary';
  const variant = isTopToolbar ? 'outlined' : actionButtonProps.variant || 'contained';

  return (
    <>
      <Button
        {...actionButtonProps}
        loading={Button === ButtonWithLoading ? loading : undefined}
        color={color}
        variant={variant}
        onClick={handleOpen}
      >
        {actionButtonText}
      </Button>
      {!loading && (
        <DialogForm
          {...dialogProps}
          open={open}
          onClose={handleClose}
          formikProps={formikProps}
          snackbarProps={snackbarProps}
        />
      )}
    </>
  );
}

ActionButtonWithDialogForm.propTypes = {
  dialogProps: PropTypes.shape({
    title: PropTypes.node,
    subtitle: PropTypes.node,
    submitText: PropTypes.node,
    cancelText: PropTypes.node,
    fields: PropTypes.node.isRequired,
  }).isRequired,
  formikProps: Types.formik.isRequired,
  loading: PropTypes.bool,
  snackbarProps: Types.snackbar,
  actionButtonText: PropTypes.node,
  actionButtonProps: PropTypes.shape({
    variant: PropTypes.string,
    color: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  buttonComponent: PropTypes.object,
};

export default ActionButtonWithDialogForm;
