import React, { useState, useEffect } from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';
import routes from '../../../../utils/routes';

import { useCheckPrivilege } from '../../../lib/hooks';
import { useQuery } from '@apollo/react-hooks';

import { GET_TICKETS } from '../../graphql';

import { Table, CollectionTable } from '../../../common/components';
import AutocompleteTypes from '../../../common/components/Table/AutocompleteTypes';
import { DownloadTicket } from '..';
import ProductStatus from './ProductStatus';
import { TicketCards } from '../TicketCard/TicketCard.component';
import { Typography } from '@material-ui/core';
import { isDevelopment } from '../../../../utils/is-env';

function TicketsTable({ tickets, event }) {

  // Display tickets card only in development environment
  const isDevelopmentEnvironment = isDevelopment();
  const [ticketsArray, setTickets] = useState([])
  const { data } = useQuery(GET_TICKETS, {
    variables: {
      options: {
        pagination: { limit: 5 },
      },
    },
  })
  useEffect(() => {
    if (data?.tickets?.entries) {
      setTickets(data?.tickets?.entries)
    }
  }, [data])

  const downloadAccess = useCheckPrivilege(Privileges.DOWNLOAD_TICKET);

  const columns = [
    {
      name: 'ticketNumber',
      label: 'Number',
      options: {
        renderCell: (value, { id, ticketNumber, ticketNumber2ndGen }) => routes.ticket.createLink(id, ticketNumber2ndGen || ticketNumber),
        filterType: 'textField',
      },
    },
    {
      name: 'event.name',
      label: 'Event',
      autocompleteType: AutocompleteTypes.EVENT,
      options: {
        renderCell: (value, ticket) => routes.event.createLink(ticket.event.id, value),
        filterType: 'autocomplete',
      },
    },
    {
      name: 'ticketType.name',
      label: 'Ticket Type',
      autocompleteType: AutocompleteTypes.TICKET_TYPE,
      options: {
        renderCell: (value, { ticketType, event: ticketEvent }) =>
          routes.ticketType.createLink(ticketType.id, value, ticketEvent.id, ticketEvent.name),
        filterType: 'autocomplete',
      },
    },
    {
      name: 'purchasedWithELBCard',
      label: 'Purchased with ELB Card',
      booleanValues: ['Purchased with ELB Card'],
      options: {
        renderCell: value => (value ? 'Yes' : 'No'),
        filterType: 'boolean',
      },
    },
    {
      name: 'price',
      label: 'Price',
      type: 'currency',
      options: {},
    },
    {
      name: 'customer.fullName',
      label: 'Customer',
      options: {
        renderCell: (value, ticket) => routes.customer.createLink(ticket.customer.id, value),
        filterType: 'textField',
      },
    },
    {
      name: 'customer.email',
      label: 'Customer Email',
      options: {
        renderCell: (value, ticket) =>
          routes.customer.createLink(ticket.customer.id, ticket.customer.fullName, value),
        filterType: 'textField',
      },
    },
    {
      name: 'soldBy.fullName',
      label: 'Employee',
      options: {
        renderCell: (value, ticket) => (ticket.soldBy ? routes.user.createLink(ticket.soldBy) : ''),
        filterType: 'textField',
      },
    },
    {
      name: 'soldAt',
      label: 'Sold at',
      type: 'dateTime',
      options: {},
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filterOptions: Object.values(ProductStatus),
        filterType: 'dropdown',
      },
    },
  ];
  if (downloadAccess) {
    columns.push({
      name: 'download',
      label: 'Download',
      options: {
        renderCell: (_, { id, status, event: { isExternal } }) => {
          if (isExternal) return 'External';
          if (status !== 'Active') return 'Inactive';
          return <DownloadTicket id={id} />;
        },
        empty: true,
        filter: false,
      },
    });
  }

  const [sortModel, setSortModel] = useState({
    name: 'ticketNumber',
    sort: 'desc',
  },
  );

  return (
    <>
      <CollectionTable>
        <Table
          title="Tickets List"
          collectionName="tickets"
          query={tickets ? undefined : GET_TICKETS}
          clipboardOptions={
            event
              ? {
                filters: [{ field: 'event.name', type: 'Text', values: [event.name] }],
              }
              : {}
          }
          data={tickets}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </CollectionTable>
      {isDevelopmentEnvironment && ticketsArray.length > 0 && (
        <>
          <Typography variant='h2' style={{ margin: "8px" }}>Tickets as MUI cards</Typography>
          <TicketCards tickets={ticketsArray} />
        </>
      )}
    </>
  );
}

TicketsTable.propTypes = {
  tickets: Types.tickets,
  event: Types.event,
};

export default TicketsTable;