import React from 'react';
import * as PropTypes from 'prop-types';

import { useRejectAuditLog } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function RejectAuditLog({ id }) {
  const [acceptAuditLog, result] = useRejectAuditLog(id);
  return (
    <ActionButtonWithConfirmation
      action={acceptAuditLog}
      result={result}
      title="Are you sure you want to reject this audit log?"
      successMessage="Audit log has been rejected!"
      buttonText="Reject"
    />
  );
}

RejectAuditLog.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RejectAuditLog;
