import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_TERMINALS } from '../../graphql';

import { Collection } from '../../../common/components';
import TerminalsToolbar from '../TerminalsToolbar/TerminalsToolbar';
import TerminalsTable from '../../../terminals/components/TerminalsTable/TerminalsTable';

function Terminals() {
  return (
    <Collection
      toolbar={<TerminalsToolbar refetchQueries={[getOperationName(GET_TERMINALS)]} />}
      table={<TerminalsTable />}
    />
  );
}

export default Terminals;
