import React from 'react';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatForint, formatDateTime } from '../../../../utils/formatters';

import { InfoCard } from '../../../common/components';

function AuditLogInfoCard({
  auditLog: {
    prettyId,
    createdBy,
    handledBy,
    createdAt,
    handledAt,
    status,
    balance: { expected, reported, difference },
    auditLogSeqId
  },
}) {
  const rows = [
    {
      label: 'Sequential audit log ID',
      value: `AL${auditLogSeqId}`
    },
    {
      label: 'Employee',
      value: routes.user.createLink(createdBy),
    },
    {
      label: 'Date',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Expected Balance',
      value: formatForint(expected),
    },
    {
      label: 'Reported Balance',
      value: formatForint(reported),
    },
    {
      label: 'Balance Difference',
      value: formatForint(difference),
    },
    {
      label: 'Handled by',
      value: handledBy ? routes.user.createLink(handledBy) : '',
    },
    {
      label: 'Handled at',
      value: handledAt ? formatDateTime(handledAt) : '',
    },
    {
      label: 'Status',
      value: status,
    },
  ];
  return <InfoCard title={prettyId} rows={rows} />;
}

AuditLogInfoCard.propTypes = {
  auditLog: Types.auditLog.isRequired,
};

export default AuditLogInfoCard;
