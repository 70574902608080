import React from 'react';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { STOP_SALE_FOR_TICKET_TYPE } from '../../graphql';

import { ActionButtonWithConfirmation } from '../../../common/components';

function StopSaleForTicketType({ id, name }) {
  const [stopSaleForTicketType, result] = useMutation(STOP_SALE_FOR_TICKET_TYPE, {
    variables: { id },
  });
  return (
    <ActionButtonWithConfirmation
      action={stopSaleForTicketType}
      result={result}
      title={`Are you sure you want to stop sales for ${name}?`}
      successMessage="Sales have been stopped for the ticket type!"
      buttonText="Stop Sales"
    />
  );
}

StopSaleForTicketType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default StopSaleForTicketType;
