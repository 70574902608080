import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Timer as TimerIcon } from '@material-ui/icons';
import { parseISO, differenceInMilliseconds } from 'date-fns';

import { useClearProductOrder } from '../../../hooks';
import { formatDuration } from '../../../../../utils/formatters';

function ProductOrderTimer({ expiresAt }) {
  const [clearProductOrder] = useClearProductOrder();

  const expirationDate = useMemo(() => parseISO(expiresAt), [expiresAt]);
  const difference = useCallback(() => differenceInMilliseconds(expirationDate, new Date()), [
    expirationDate,
  ]);
  const [timeLeft, setTimeLeft] = useState(difference());

  // Reset timer on expiration date change
  useEffect(() => {
    setTimeLeft(difference());
  }, [difference]);

  useEffect(() => {
    if (timeLeft <= 0) {
      clearProductOrder();
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(difference());
    }, 100);

    // Clear interval on unmount
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [timeLeft, setTimeLeft, difference, clearProductOrder]);

  const timer =
    timeLeft > 0
      ? formatDuration(timeLeft, { hours: true, minutes: true, seconds: true })
      : '00:00:00';

  return (
    <div>
      <TimerIcon />
      <Typography component="span">{timer}</Typography>
    </div>
  );
}

ProductOrderTimer.propTypes = {
  expiresAt: PropTypes.string,
};

export default ProductOrderTimer;
