import React from 'react';
import * as PropTypes from 'prop-types';

function Form({ className, onSubmit, children }) {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Form;
