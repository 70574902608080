import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { MutateBillingAccount } from '..';

function UpdateBillingAccount({ billingAccount }) {
  return <MutateBillingAccount billingAccount={billingAccount} />;
}

UpdateBillingAccount.propTypes = {
  billingAccount: Types.billingAccount.isRequired,
};

export default UpdateBillingAccount;
