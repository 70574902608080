import React from 'react';
import * as PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import FormError from '../FormError';

function FormikFormError({ className }) {
  const { status } = useFormikContext();
  if (status) {
    return <FormError className={className} message={status} />;
  }
  return null;
}

FormikFormError.propTypes = {
  className: PropTypes.string,
};

export default FormikFormError;
