import React from 'react';
import { Grid } from '@material-ui/core';

import { useGetOpenOrder } from '../../../hooks';

import { FillerSpinner } from '../../../../lib/components';
import { Content } from '../../../../common/components';
import { AddBillingCustomer } from '../..';
import ProductOrderToolbar from '../ProductOrderToolbar';
import OrderProductsTable from '../OrderProductsTable';
import ProductOrderTimer from '../ProductOrderTimer';

function OpenOrderWithProducts() {
  const { loading, data } = useGetOpenOrder();

  if (loading) return <FillerSpinner />;

  const { getOpenOrder: order } = data;
  const { billingCustomer, products, expiresAt } = order;

  return (
    <Content toolbar={<ProductOrderToolbar order={order} />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProductOrderTimer expiresAt={expiresAt} />
        </Grid>
        <Grid item xs={12}>
          <AddBillingCustomer customer={billingCustomer} />
        </Grid>
        <Grid item xs={12}>
          <OrderProductsTable products={products} />
        </Grid>
      </Grid>
    </Content>
  );
}

export default OpenOrderWithProducts;
