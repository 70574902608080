import React from 'react';
import * as PropTypes from 'prop-types';

import FormikAsync from '../FormikAsync';
import * as Types from '../../../../utils/propTypes';
import { useSnackbar } from '../../hooks';
import { wrapFormHandler } from '../../../../utils/helpers';

function FormikAsyncWithSnackbar({
  children,
  onSuccess = () => {},
  onFailure = () => {},
  successText/* = 'Success!'*/,
  errorText = 'Failure!',
  ...rest
}) {
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccess = successText
    ? wrapFormHandler(onSuccess, () => enqueueSnackbar(successText, 'success'))
    : onSuccess;
  const handleFailure = errorText
    ? wrapFormHandler(onFailure, () => enqueueSnackbar(errorText, 'error'))
    : onFailure;

  const props = {
    ...rest,
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  };

  return <FormikAsync {...props}>{children}</FormikAsync>;
}

FormikAsyncWithSnackbar.propTypes = {
  onSuccess: Types.onSuccess,
  successText: PropTypes.string,
  onFailure: Types.onFailure,
  errorText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default FormikAsyncWithSnackbar;
