import gql from 'graphql-tag';

import { USER_FRAGMENT } from '../users/graphql';

export const AUTH_FRAGMENT = gql`
  fragment AuthFragment on User {
    ...UserFragment
    token
    role {
      id
      name
      privileges
    }
  }
  ${USER_FRAGMENT}
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on User {
    id
    email
    fullName
    role {
      id
      name
      privileges
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser @client {
      ...CurrentUserFragment
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export const STORE_CURRENT_USER_ID = gql`
  mutation StoreCurrentUser($id: String!) {
    storeCurrentUserId(id: $id) @client
  }
`;

export const VERIFY_AUTHENTICATION_TOKEN = gql`
  query VerifyAuthenticationToken($token: String!) {
    verifyAuthenticationToken(token: $token) {
      verified
      user {
        ...AuthFragment
      }
    }
  }
  ${AUTH_FRAGMENT}
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...AuthFragment
    }
  }
  ${AUTH_FRAGMENT}
`;

export const VERIFY_ACTIVATION_TOKEN = gql`
  query VerifyActivationToken($token: String!) {
    verifyActivationToken(token: $token) {
      verified
      user {
        email
      }
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($data: ActivationInput) {
    activateUser(data: $data) {
      ...AuthFragment
    }
  }
  ${AUTH_FRAGMENT}
`;

export const SEND_RESET_PASSWORD_MAIL = gql`
  mutation SendResetPasswordMail($email: String!) {
    sendResetPasswordMail(email: $email)
  }
`;

export const VERIFY_PASSWORD_RESET_TOKEN = gql`
  query VerifyPasswordResetToken($token: String!) {
    verifyPasswordResetToken(token: $token) {
      verified
      user {
        email
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($data: ResetPassword) {
    resetPassword(data: $data) {
      ...AuthFragment
    }
  }
  ${AUTH_FRAGMENT}
`;

export const GET_ROLES = gql`
  query GetRoles {
    roles {
      id
      name
    }
  }
`;
