import React, { useMemo } from 'react';

import TableFooter from '../components/TableFooter';

export default function useOptions(options, hasMore, dataLength, loading) {
  return useMemo(
    () => ({
      ...options,
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
        <TableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={options.rowsPerPageOptions}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          serverSide={options.serverSide}
          hasMore={hasMore}
          dataLength={dataLength}
          loading={loading}
        />
      ),
    }),
    [options, hasMore, dataLength, loading],
  );
}
