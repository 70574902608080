import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import routes from '../../../../../utils/routes';

const sharedTableCellStyle = { padding: "8px" };

function EventCheckingStatistics() {
  const [cardReadings, setCardReadings] = useState([]);

  // Read input parameters
  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get('eventId');

  // Fetch event checking statistics
  const fetchEventCheckingStatistics = async (eventId) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      }
    }
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}checking/events/${eventId}`, options);
    console.log('fetchEventCheckingStatistics', response.data);
    setCardReadings(response.data);
  }

  useEffect(() => {
    fetchEventCheckingStatistics(eventId);
  }, [eventId]);

  const uniqueCardNumbers = useMemo(() => {
    const cardNumbersSet = new Set();
    cardReadings.forEach(cardReading => {
      if (cardReading?.onlineCardNumber || cardReading?.cardNumber) {
        cardNumbersSet.add(cardReading?.onlineCardNumber || cardReading?.cardNumber)
      }
    })
    return Array.from(cardNumbersSet);
  }, [cardReadings])

  return (
    <Box>
      <Typography variant='h4' style={{ margin: 4 }}>Summary</Typography>
      <List dense={true}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#3f51b5' }} >
              <CreditCardIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Number of card readings"
            secondary={`${cardReadings.length} card readings`}
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: '#3f51b5' }} >
              <PeopleOutlineIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Number of unique card numbers"
            secondary={`${uniqueCardNumbers.length} unique card numbers`}
          />
        </ListItem>
      </List>
      <Typography variant='h4' style={{ margin: 4 }}>List of card readings</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={sharedTableCellStyle}>Profile picture</TableCell>
              <TableCell style={sharedTableCellStyle}>Name</TableCell>
              <TableCell style={sharedTableCellStyle}>Email</TableCell>
              <TableCell style={sharedTableCellStyle} >Card number</TableCell>
              <TableCell style={sharedTableCellStyle} >Reading timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cardReadings.length === 0 ? (
              <TableRow>
                <TableCell style={sharedTableCellStyle}>
                  No card readings.
                </TableCell>
              </TableRow>
            ) : (
              cardReadings.map(cardReading => {
                const {
                  _id: id,
                  createdDate,
                  customerEmail,
                  customerFullName,
                  customerId,
                  cardNumber,
                  onlineCardNumber,
                  profilePicture
                } = cardReading;
                return (
                  <TableRow key={id}>
                    <TableCell
                      style={sharedTableCellStyle}
                      align='left'
                    >
                      {profilePicture?.url ? (
                        <img
                          src={profilePicture.url}
                          alt={customerFullName}
                          height={50}
                        />
                      ) : (
                        "No picture"
                      )}
                    </TableCell>
                    <TableCell
                      style={sharedTableCellStyle}>
                      {routes.customer.createLink(customerId, customerFullName)}
                    </TableCell>
                    <TableCell
                      style={sharedTableCellStyle}>
                      {routes.customer.createLink(customerId, customerEmail)}
                    </TableCell>
                    <TableCell
                      style={sharedTableCellStyle}>
                      {onlineCardNumber || cardNumber}
                    </TableCell>
                    <TableCell
                      style={sharedTableCellStyle}>
                      {format(new Date(createdDate), 'yyyy-MM-dd HH:mm:ss')}
                    </TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default EventCheckingStatistics;
