import React from 'react';
import { CreateOrEditProduct } from '../components';
import { useCreateProduct } from '../hooks';

function CreateProductPage() {
  const { isError, error, isLoading, mutate } = useCreateProduct();
  return (
    <CreateOrEditProduct
      error={error}
      isError={isError}
      isLoading={isLoading}
      mutate={mutate}
      isEditing={false}
    />
  );
}

export default CreateProductPage;
