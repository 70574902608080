import gql from 'graphql-tag';

export default {
  Query: {
    currentUser: (_root, variables, { cache }) => {
      const { currentUserId } = cache.readQuery({
        query: gql`
          {
            currentUserId @client
          }
        `,
      });
      const currentUser = cache.readFragment({
        id: currentUserId, // `id` is any id that could be returned by `dataIdFromObject`.
        fragment: gql`
          fragment currentUser on User {
            id
            email
            firstName
            lastName
            fullName
            account {
              id
              balance
            }
            role {
              id
              name
              privileges
            }
            isActive
            token
          }
        `,
      });
      return currentUser;
    },
  },
  Mutation: {
    storeCurrentUserId: (_root, { id }, { cache }) => {
      cache.writeData({ data: { currentUserId: id } });
      return { id };
    },
  },
};
