import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useGetElbCardTypes } from '../../hooks';

import { FormikAutocomplete as FAutocomplete } from '../../../lib/components';

function FormikELBCardTypeAutocomplete({ name, InputProps = {}, ...rest }) {
  const { loading, data } = useGetElbCardTypes({ variables: { options: {} } });
  const elbCardTypes = useMemo(() => (data ? data.elbCardTypes.entries : []), [data]);
  const options = useMemo(() => elbCardTypes.filter(({ forSale }) => forSale), [elbCardTypes]);
  const getOptionLabel = useCallback(({ name: ectName }) => ectName, []);

  return (
    <FAutocomplete
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      loading={loading}
      InputProps={{
        label: 'ELB Card Type',
        ...InputProps,
      }}
      {...rest}
    />
  );
}

FormikELBCardTypeAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
};

export default FormikELBCardTypeAutocomplete;
