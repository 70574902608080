import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

import { Table, CollectionTable } from '../../../common/components';
import { RemoveElbCardFromOrder, UpdateOrderELBCard } from '..';

function OrderELBCards({ elbCards }) {
  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        empty: true,
        renderCell: (_, { id }) => (
          <>
            <RemoveElbCardFromOrder id={id} />
            <UpdateOrderELBCard id={id} />
          </>
        ),
      },
    },
    {
      name: 'type.name',
      label: 'ELB Card Type',
      options: {
        renderCell: (value, { type }) => routes.elbCardType.createLink(type.id, value),
        filterType: 'multiselect',
      },
    },
    {
      name: 'type.price',
      label: 'Price',
      type: 'currency',
    },
    {
      name: 'customer.fullName',
      label: 'Customer',
      options: {
        renderCell: (value, { customer }) => routes.customer.createLink(customer.id, value),
        filterType: 'textField',
      },
    },
    {
      name: 'customer.email',
      label: 'Customer Email',
      options: {
        renderCell: (value, { customer }) =>
          routes.customer.createLink(customer.id, customer.fullName, value),
        filterType: 'textField',
      },
    },
    {
      name: 'cardNumber',
      label: 'ELB Card Number',
    },
    {
      name: 'onlineCardNumber',
      label: 'Online ELB Card Number',
    },
  ];

  return (
    <CollectionTable>
      <Table title="ELB Cards" data={elbCards} columns={columns} />
    </CollectionTable>
  );
}

OrderELBCards.propTypes = {
  elbCards: PropTypes.arrayOf(Types.elbCard),
};

export default OrderELBCards;
