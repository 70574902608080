import React from 'react';
import { useHistory } from 'react-router-dom';
import { addSeconds } from 'date-fns';
import { isString } from 'lodash';

import routes from '../../../../utils/routes';
import * as Types from '../../../../utils/propTypes';
import { event as eventSchema } from '../../../../utils/validationSchemas';

import { useCreateEvent } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { EventFormFields } from '..';
import { useCreateTicketTypeWithoutRefetch } from '../../hooks/useCreateTicketType';

function CopyEvent({ event, refetchQueries }) {
  const {
    name,
    from,
    until,
    location,
    ticketLimit,
    billingAccount,
    form,
    isExternal,
    startSales,
  } = event;

  const history = useHistory();

  const [create] = useCreateEvent(refetchQueries ? { refetchQueries } : undefined);
  const [createTicket] = useCreateTicketTypeWithoutRefetch();

  const dialogProps = {
    title: 'Copy Event',
    submitButtonText: 'Copy Event',
    fields: <EventFormFields create={true} isEditing={true} form={form ? form.id : ''} />,
  };

  const initialValues = {
    name: name || '',
    from: from || new Date(),
    until: until || addSeconds(new Date(), 1),
    location: location || '',
    ticketLimit: ticketLimit || '',
    billingAccount: billingAccount ? billingAccount.id : 'manually',
    form: form ? form.id : '',
    isExternal: isExternal || false,
    startSales: startSales || false,
  };

  async function handleSubmit({
    form: formValue,
    billingAccount: billingAccountValue,
    ...otherValues
  }) {
    const args = {
      formId: isString(formValue) ? formValue : formValue.id,
      billingAccount: billingAccountValue === 'manually' ? null : billingAccountValue,
      ...otherValues,
    };
    const result = await create(args);
    
    for (const ticketType of event.ticketTypes) {
      const { ticketStats, id, forSale, totalSales, __typename, ...rest } = ticketType;
      const newTicket = {
        ...rest,
        eventId: result.data.createEvent.id,
        startSales: forSale,
        expirationDate: undefined,
      };
      await createTicket(newTicket);
    }
    history.replace(
      routes.event.createUrl(result.data.createEvent.id, result.data.createEvent.name),
    );
  }

  const formikProps = {
    initialValues,
    validationSchema: eventSchema,
    onSubmit: handleSubmit,
  };

  const snackbarProps = {
    successText: 'Event has been copied',
  };

  const actionButtonProps = {
    variant: 'text',
    color: 'default',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={'Copy Event'}
      actionButtonProps={actionButtonProps}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

CopyEvent.propTypes = {
  event: Types.event.isRequired,
};

export default CopyEvent;
