import { useState } from 'react';
import { isEmpty } from 'lodash';

export default function useClientSideFilters(columns) {
  const [filters, setFilters] = useState(() =>
    columns
      .filter(({ options }) => options && !isEmpty(options.filterList))
      .reduce(
        (filtersAccumulator, { name, options: { filterList } }) => ({
          ...filtersAccumulator,
          [name]: filterList,
        }),
        {},
      ),
  );

  function addFilter(columnName, newFilter) {
    setFilters({
      ...filters,
      [columnName]: newFilter,
    });
  }
  function resetFilter() {
    setFilters({});
  }

  return [filters, addFilter, resetFilter];
}
