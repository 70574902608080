import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';
import { formatDate } from '../../../../utils/formatters';

import { Table, CollectionTable } from '../../../common/components';

const columns = [
  {
    name: 'cardNumber',
    label: 'Card Number',
    options: {
      renderCell: (value, { id }) => routes.elbCard.createLink(id, value),
    },
  },
  {
    name: '__typename',
    label: 'Type',
  },
  {
    name: 'createdAt',
    label: 'Start date',
    options: {
      renderCell: date => formatDate(date),
      filterType: 'dateRange',
    },
  },
  {
    name: 'expirationDate',
    label: 'Expiration date',
    options: {
      renderCell: date => formatDate(date),
      filterType: 'dateRange',
    },
  },
  {
    name: 'status',
    label: 'Status',
  },
];

const getCardType = card => {
  if (card.cardNumber !== 'ELB') {
    return 'Physical';
  }
  return 'Virtual';
};

function CustomerELBCardsTable({ elbCards }) {
  const processedElbCards = elbCards.map(card => {
    return {
      id: card.id,
      createdAt: card.createdAt,
      expirationDate: card.expirationDate,
      status: card.status,
      __typename: getCardType(card),
      cardNumber:
        getCardType(card) === 'Virtual' && card.onlineCardNumber
          ? card.onlineCardNumber
          : card.cardNumber,
      //__typename: card.__typename,
    };
  });
  return (
    <CollectionTable>
      <Table title="ELB Cards List" data={processedElbCards} columns={columns} inline/>
    </CollectionTable>
  );
}

CustomerELBCardsTable.propTypes = {
  elbCards: PropTypes.arrayOf(Types.elbCard),
};

export default CustomerELBCardsTable;
