import axios from 'axios';

export const PAYMENTS_API_BASE_URL = process.env.REACT_APP_PAYMENTS_API_BASE_URL;

export const PAYMENTS_API_ENDPOINTS = {
  PAYMENTS_CONTROLLER_BASE_URL: 'payments',
};

export const PAYMENT_API_AXIOS_INSTANCE = axios.create({
  baseURL: PAYMENTS_API_BASE_URL,
});
