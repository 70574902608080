import React from 'react';
import * as PropTypes from 'prop-types';

import { useActivateBillingAccount } from '../../hooks';
import { ActionButton } from '../../../common/components';

function ActivateBillingAccount({ id }) {
  const [activate, result] = useActivateBillingAccount(id);
  return (
    <ActionButton
      action={activate}
      result={result}
      successMessage="Billing account has been activated!"
    >
      Activate
    </ActionButton>
  );
}

ActivateBillingAccount.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ActivateBillingAccount;
