import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Hidden, IconButton, Typography } from '@material-ui/core';
import {
  Menu as MenuIcon,
  ExitToApp as LogoutIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Language as WebsiteIcon,
  MailOutline as EmailIcon,
  Event as CalendarIcon,
  MenuBook as WikiIcon,
  Description as DriveIcon
} from '@material-ui/icons';
import { ButtonLink } from '../../../lib/components';
import { CreateEvent } from '../../../../modules/events/components';
import { ReportBalance } from '../../../../modules/audit/components';
import { CreateTransaction } from '../../../../modules/transactions/components';

import Logo from '../../../../assets/images/Erasmus_logo_white_png_150.png';
import TikTokIcon from './TikTokIcon';

import { useLogout, useGetCurrentUser } from '../../../auth/hooks';

import Privileges from '../../../../utils/access-control/privileges.json';
import { useCheckPrivilege } from '../../../lib/hooks';

import { isDevelopment } from '../../../../utils/is-env';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  logo: {
    height: 35,
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  navbarTitle: {
    color: 'white',
    margin: '0 16px',
  },
}));

function Topbar({ className, onSidebarOpen, ...rest }) {
  const classes = useStyles();

  const logout = useLogout();

  const {
    data: { currentUser },
  } = useGetCurrentUser();

  // Customize app bar based on app environment
  const isDevelopmentEnvironment = isDevelopment();
  const appBarColor = isDevelopmentEnvironment ? 'transparent' : 'primary';
  const appBarBackgroundColor = isDevelopmentEnvironment ? 'rgb(211, 47, 47)' : '#3f51b5';

  // Check privileges
  const canCreateEvent = useCheckPrivilege(Privileges.CREATE_EVENT);
  const canCreateTransaction = useCheckPrivilege(Privileges.CREATE_TRANSACTION);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color={appBarColor}
      style={{
        backgroundColor: appBarBackgroundColor,
      }}
    >
      <Toolbar>
        <RouterLink to="/">
          <img alt="Logo" src={Logo} className={classes.logo} />
        </RouterLink>
        {isDevelopmentEnvironment && (
          <Typography variant="h5" className={classes.navbarTitle}>
            DEVELOPMENT ENVIRONMENT
          </Typography>
        )}
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton className={classes.logoutButton} color="inherit" href='https://www.facebook.com/ErasmusLifeBudapest' target="_blank" rel="noreferrer">
            <FacebookIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='https://www.instagram.com/erasmuslifebudapest' target="_blank" rel="noreferrer">
            <InstagramIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='https://www.erasmuslifebudapest.com' target="_blank" rel="noreferrer">
            <WebsiteIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='http://elbmail.erasmuslifebudapest.com' target="_blank" rel="noreferrer">
            <EmailIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='http://gcalendar.erasmuslifebudapest.com' target="_blank" rel="noreferrer">
            <CalendarIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='http://elbwiki.slite.com' target="_blank" rel="noreferrer">
            <WikiIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='http://gdrive.erasmuslifebudapest.com' target="_blank" rel="noreferrer">
            <DriveIcon />
          </IconButton>
          <IconButton className={classes.logoutButton} color="inherit" href='https://www.tiktok.com/@erasmuslifebudapest' target="_blank" rel="noreferrer">
            <TikTokIcon color={isDevelopmentEnvironment ? "black" : "white"} />
          </IconButton>
          <ButtonLink className={classes.logoutButton} to="/order-edit" variant="outlined" color="inherit">
            New Order
          </ButtonLink>
          {currentUser && (
            <div style={{ marginLeft: '8px' }}>
              <ReportBalance id={currentUser?.id} isTopToolbar={true} />
            </div>
          )}
          {canCreateEvent && (
            <div style={{ marginLeft: '8px' }}>
              <CreateEvent isTopToolbar={true} />
            </div>
          )}
          {canCreateTransaction && (
            <div style={{ marginLeft: '8px' }}>
              <CreateTransaction isTopToolbar={true} />
            </div>
          )}
          <IconButton className={classes.logoutButton} color="inherit" onClick={logout}>
            <LogoutIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
