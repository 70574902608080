import React from 'react';

import {
  FormikDatePicker as FDatePicker,
  FormikFormError as FFormError,
  FormikSwitch as FSwitch,
  FormikTextField as FTextField,
} from '../../../lib/components';

function ELBCardFormFields() {
  return (
    <>
      <FDatePicker
        fullWidth
        variant="outlined"
        format="yyyy/MM/dd"
        margin="none"
        required
        name="expirationDate"
        label="Expiration date"
      />

      <FSwitch name="isDelivered" label="Delivered to the customer" />

      <FTextField
        name="physicalCardNumber"
        type="text"
        label="Physical card number"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FFormError />
    </>
  );
}

ELBCardFormFields.propTypes = {};

export default ELBCardFormFields;
