import React from 'react';
import PropTypes from 'prop-types';

import { GET_ELB_CARD } from '../../../elb-cards/graphql';

import { UpdateOrderItem } from '..';

function UpdateOrderELBCard({ id }) {
  return <UpdateOrderItem id={id} query={GET_ELB_CARD} />;
}

UpdateOrderELBCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UpdateOrderELBCard;
