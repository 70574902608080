import {
  string,
  number,
  bool,
  func,
  arrayOf,
  shape,
  object,
  oneOf,
  oneOfType,
  array,
  element,
} from 'prop-types';

import ColumnTypes from './ColumnTypes';
import FilterTypes from './FilterTypes';

export const data = array;

export const columns = arrayOf(
  shape({
    name: string.isRequired,
    label: string,
    type: oneOf(Object.values(ColumnTypes)),
    options: shape({
      display: oneOf([true, false, 'excluded']),
      empty: bool,
      filter: bool,
      sort: bool,
      print: bool,
      searchable: bool,
      download: bool,
      viewColumns: bool,
      filterList: array,
      filterOptions: oneOfType([
        array,
        shape({
          names: array,
          logic: func,
          display: func,
        }),
      ]),
      filterType: oneOf(Object.values(FilterTypes)),
      customHeadRender: func,
      renderCell: func,
      renderFilterValue: func,
      customFilterListOptions: shape({ render: func }),
    }),
  }),
);

const commonOptionsShape = {
  responsive: oneOf(['vertical', 'standard', 'simple']),
  filterType: oneOf(Object.values(FilterTypes)),
  sortOrder: shape({ name: string, direction: 'asc' || 'desc' }),
  textLabels: object,
  enableNestedDataAccess: string,
  pagination: bool,
  expandableRows: bool,
  expandableRowsOnClick: bool,
  renderExpandableRow: func,
  customToolbar: oneOfType([func, element]),
  customToolbarSelect: oneOfType([func, element]),
  customFooter: oneOfType([func, element]),
  customSearchRender: oneOfType([func, element]),
  customRowRender: func,
  onRowClick: func,
  onRowSelectionChange: func,
  resizableColumns: bool,
  selectableRows: oneOfType([bool, oneOf(['none', 'single', 'multiple'])]),
  selectableRowsOnClick: bool,
  isRowSelectable: func,
  serverSide: bool,
  onTableChange: func,
  onTableInit: func,
  caseSensitive: bool,
  rowHover: bool,
  fixedHeader: bool,
  page: number,
  count: number,
  rowsSelected: array,
  rowsExpanded: array,
  rowsPerPage: number,
  rowsPerPageOptions: array,

  customSort: func,
  customSearch: func,
  searchPlaceholder: string,
  viewColumns: bool,
  downloadOptions: shape({
    filename: string,
    separator: string,
    filterOptions: shape({
      useDisplayedColumnsOnly: bool,
      useDisplayedRowsOnly: bool,
    }),
  }),
};

export const muiDataTableOptions = shape({
  ...commonOptionsShape,

  search: bool,
  searchText: string,
  filter: bool,

  print: bool,

  download: bool,

  onDownload: func,

  sort: bool,
});

export const containerOptions = shape({
  ...commonOptionsShape,

  enableSearch: bool,
  search: string,
  enableFilter: bool,

  enablePrint: bool,

  enableExport: bool,

  enableSort: bool,
});
