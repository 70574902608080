import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import ButtonWithLoading from '../ButtonWithLoading';

function SubmitButton({ children = 'Submit', ...rest }) {
  const { isSubmitting, isValid } = useFormikContext();
  return (
    <ButtonWithLoading
      loading={isSubmitting}
      variant="contained"
      color="primary"
      type="submit"
      disabled={!isValid}
      {...rest}
    >
      {children}
    </ButtonWithLoading>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.node,
};

export default SubmitButton;
