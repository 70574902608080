import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';
import { FormikRoleSelect as FRoleSelect } from '../../../auth/components';

function UserFormFields({ profile, edit = false }) {
  return (
    <>
      {edit && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FTextField
              name="firstName"
              type="text"
              required
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Grid>

          <Grid item xs={6}>
            <FTextField
              name="lastName"
              type="text"
              required
              variant="outlined"
              label="Last Name"
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
      )}

      {!edit && !profile && (
        <FTextField
          fullWidth
          margin="normal"
          variant="outlined"
          required
          name="email"
          label="Email address"
          type="text"
        />
      )}

      {!profile && (
        <FRoleSelect
          name="role"
          required
          label="Role"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}

      <FFormError />
    </>
  );
}

UserFormFields.propTypes = {
  edit: PropTypes.bool,
  profile: PropTypes.bool,
};

export default UserFormFields;
