import React, { useEffect, useRef, useState } from 'react';

import { FillerSpinner } from '../../../lib/components';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { useGetProducts } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import CreateProductDialog from '../CreateProductDialog';
import { useDebounce } from '../../../common/hooks';
import { SUPPORTED_PRODUCT_TYPES_ARRAY, getHumanReadableProductType } from './products.utils';
import { Edit } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

function Products() {
  const [searchText, setSearchText] = useState('');
  const [lastSearchTextValue, setLastSearchTextValue] = useState(null);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [type, setType] = useState('');

  const {
    isError,
    isLoading,
    isSuccess,
    data: productData,
    refetch,
    isRefetching,
  } = useGetProducts({
    searchText: debouncedSearchText,
    type,
  });

  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();
    refetch();
    if (searchTextInputRef?.current) {
      searchTextInputRef.current.focus();
      searchTextInputRef.current.select();
    }
  };

  useEffect(() => {
    if (debouncedSearchText) {
      setLastSearchTextValue(debouncedSearchText);
      if (lastSearchTextValue !== debouncedSearchText) {
        refetch();
        if (searchTextInputRef?.current) {
          searchTextInputRef.current.focus();
          searchTextInputRef.current.select();
        }
      }
    }
  }, [debouncedSearchText, refetch, lastSearchTextValue]);

  const searchTextInputRef = useRef(null);

  const clearSearchForm = () => {
    setSearchText('');
    setType('');
  };

  useEffect(() => {
    if (type) {
      refetch();
    }
  }, [type, refetch]);

  return (
    <>
      {(isLoading || isRefetching) && <FillerSpinner />}
      {isError && <strong>Failed loading products</strong>}
      <Box
        style={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <TextField
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ margin: '4px', width: '350px' }}
          label="Product Search"
          type="text"
          inputRef={searchTextInputRef}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <FormControl required variant="outlined" style={{ margin: '4px', width: '150px' }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={e => {
              setType(e.target.value);
            }}
          >
            {SUPPORTED_PRODUCT_TYPES_ARRAY.map(type => {
              return (
                <MenuItem key={type} value={type}>
                  {getHumanReadableProductType(type)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={clearSearchForm}
          style={{ margin: '4px' }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ margin: '4px' }}
        >
          Search
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '4px' }}
          href="/create-product"
        >
          Create Product (new page)
        </Button>
        <CreateProductDialog />
      </Box>
      {isSuccess &&
        (productData?.length > 0 ? (
          <Box>
            {productData.map(product => {
              const { _id: id, type, name, description, price, imageUrl } = product;
              const productDetailsColumnWidth = imageUrl ? 8 : 10;
              return (
                <Card style={{ margin: '8px 0px' }} key={id}>
                  <CardContent>
                    <Grid container direction="row" style={{ marginTop: '8px' }}>
                      {imageUrl && (
                        <Grid item xs={2} style={{ padding: '4px' }}>
                          {<img src={imageUrl} alt={name} width="100%" />}
                        </Grid>
                      )}
                      <Grid item xs={productDetailsColumnWidth}>
                        <Grid container direction="row" style={{ padding: '0px 16px' }}>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">
                              Name: <strong>{name}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">
                              Description: <strong>{description}</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">
                              Price: <strong>{price} Ft</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ padding: '4px' }}>
                            <Typography variant="body1">
                              Type: <strong>{getHumanReadableProductType(type)}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2} style={{ padding: '4px' }}>
                        <Edit
                          onClick={() => {
                            console.log('onClick id: ', { id });
                            history.push(`products/id=${id}`);
                            //return routes.product.createUrl(id);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        ) : (
          <Alert severity="warning">
            <AlertTitle>Products</AlertTitle>
            <Typography>There is no product to display.</Typography>
          </Alert>
        ))}
    </>
  );
}

export default Products;
