import React from 'react';
import * as PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';

import { DATE_TIME_FORMAT } from '../../../../utils/formatters';
import * as Types from '../../../../utils/propTypes';
import FormikFieldWithError from '../FormikFieldWithError';
import { useFormikContext } from 'formik';
import { addHours } from 'date-fns';

function FormikDateTimePicker({ name, responseErrors = {}, placeholder, variant, ...rest }) {

  const {
    values: { from },
    touched,
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    if (name === "from") {
      const fromAsDate = new Date(from);
      if (from && touched.from) {
        setFieldValue('until', addHours(fromAsDate, 1));
      }
    }
  }, [from, touched.from, setFieldValue, name]);

  return (
    <FormikFieldWithError name={name} responseErrors={responseErrors}>
      {({ field, form, hasError, errorMessage }) => (
        <DateTimePicker
          {...field}
          onChange={e => form.setFieldValue(name, e)}
          error={hasError}
          helperText={errorMessage}
          placeholder={placeholder}
          ampm={false}
          inputVariant={variant}
          format={DATE_TIME_FORMAT}
          {...rest}
        />
      )}
    </FormikFieldWithError>
  );
}

FormikDateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  responseErrors: Types.responseErrors,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
};

export default FormikDateTimePicker;
