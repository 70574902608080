import React from 'react';
import PropTypes from 'prop-types';

import { Main } from '..';

function Document({ toolbar, infoCard, inlineTable, table, secondTable }) {
  return (
    <Main
      toolbar={toolbar}
      primary={infoCard}
      secondary={inlineTable}
      tertiary={table}
      quaternary={secondTable}
    />
  );
}

Document.propTypes = {
  toolbar: PropTypes.node,
  infoCard: PropTypes.node,
  inlineTable: PropTypes.node,
  table: PropTypes.node,
  secondTable: PropTypes.node,
};

export default Document;
