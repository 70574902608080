import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Breadcrumbs as MBreadcrumbs, Typography } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { last, initial } from 'lodash';

import routes, { matchDocumentLabel, findRouteByKey } from '../../../../utils/routes';
import Link from '../Link';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
}));

function createBreadcrumbs(fullUrl) {
  // Handle edge case: /event-checking URL with event ID and name
  if (fullUrl.includes(routes.eventChecking.path)) {
    const urlParams = new URLSearchParams(window.location.search);
    const eventName = urlParams.get('eventName');
    const eventId = urlParams.get('eventId');
    // Different label for event checking and checking statistics
    const lastBreadcrumbLabel = fullUrl.includes(routes.eventCheckingStatistics.path) ? routes.eventCheckingStatistics.label : routes.eventChecking.label;
    return [
      { url: routes.events.path, label: 'Events' },
      { url: `${routes.events.path}/id=${eventId}&${eventName}`, label: eventName },
      { url: null, label: lastBreadcrumbLabel },
    ]

  }
  const [, ...splits] = fullUrl.split('/');
  return splits.map((s, i) => {
    /**
     * Extract possible URLs from URL
     * e.g.: "/customers/5d6934418ac4cc4fd4754f7b" -> ["/customers", "/customers/5d6934418ac4cc4fd4754f7b"]
     */
    const url = `${splits
      .slice(0, i)
      .map(prevSplit => `/${prevSplit}`)
      .join('')}/${s}`;

    const matchedLabel = matchDocumentLabel(s);
    if (matchedLabel) {
      return {
        url,
        label: matchedLabel,
      };
    }
    const route = findRouteByKey(s);
    return {
      url,
      label: route ? route.label : '',
    };
  });
}

function Breadcrumbs({ match: { url } }) {
  const classes = useStyles();

  if (url === routes.notFoundA.path) return null;

  const breadcrumbs = createBreadcrumbs(url);
  return (
    <Paper elevation={0} className={classes.paper}>
      <MBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {initial(breadcrumbs).map(({ label, url: to }) => (
          <Link key={to} color="inherit" to={to}>
            {decodeURIComponent(label)}
          </Link>
        ))}
        <Typography color="textPrimary">{decodeURIComponent(last(breadcrumbs).label)}</Typography>
      </MBreadcrumbs>
    </Paper>
  );
}

Breadcrumbs.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Breadcrumbs;
