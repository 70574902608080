import { useApolloClient } from '@apollo/react-hooks';

function useLogout() {
  const client = useApolloClient();

  function logout() {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    client.clearStore();
    // Apollo change doesn't rerender the application for some reason
    window.location.reload();
  }

  return logout;
}

export default useLogout;
