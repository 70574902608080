import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';

import { InfoCardRow } from '../../../../common/components';
import OrderProductChangeCalculator from '../OrderProductChangeCalculator';
import ProductOrderTimer from '../ProductOrderTimer';

function OrderProductItemsInfoCard({
  order,
  paidAmounts,
  setPaidAmounts,
  terminal,
  setTerminal,
  paymentMode,
  setPaymentMode,
}) {
  const { products, expiresAt } = order;
  const tickets = products.filter(product => product.type === 'ticket');
  const elbCards = products.filter(product => product.type === 'elb-card');

  const rows = [
    {
      label: 'Number of Ticket products',
      value: tickets.length,
    },
    {
      label: 'Number of ELB Card products',
      value: elbCards.length,
    },
  ];

  return (
    <Card>
      <CardContent>
        <ProductOrderTimer expiresAt={expiresAt} />

        <OrderProductChangeCalculator
          order={order}
          paidAmounts={paidAmounts}
          setPaidAmounts={setPaidAmounts}
          terminal={terminal}
          setTerminal={setTerminal}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
        />

        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
          {rows.map(({ label, value }) => (
            <Grid item key={label}>
              <InfoCardRow label={label} value={value} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default OrderProductItemsInfoCard;
