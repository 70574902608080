import React from 'react';
import * as PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

function CollectionTable({ children }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>{children}</div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions} />
    </Card>
  );
}

CollectionTable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CollectionTable;
