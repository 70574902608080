import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import * as Types from '../../../../../../utils/propTypes';
import { useGetElbCardTypes } from '../../../../../elb-card-types/hooks';
import TableEntityAutoCompleteFilter from '../TableEntityAutoCompleteFilter';

function TableELBCardTypeAutocompleteFilter({ column, filters, index, onChange }) {
  const [fieldText, setFieldText] = useState();
  const debouncedSetFieldText = debounce(setFieldText, 300);
  const onInputChange = useCallback(
    (_, value) => {
      debouncedSetFieldText(value);
    },
    [debouncedSetFieldText],
  );
  const { loading, data } = useGetElbCardTypes({
    variables: {
      options: {
        ...(fieldText ? { filters: [{ field: 'name', type: 'Text', values: [fieldText] }] } : null),
        pagination: { limit: 10 },
      },
    },
  });
  const elbCardTypes = data ? data.elbCardTypes.entries : [];
  const getOptionLabel = useCallback(({ name }) => name, []);

  return (
    <TableEntityAutoCompleteFilter
      column={column}
      filters={filters}
      index={index}
      onChange={onChange}
      label="Type"
      property="name"
      options={elbCardTypes}
      loading={loading}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
    />
  );
}

TableELBCardTypeAutocompleteFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(PropTypes.number).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TableELBCardTypeAutocompleteFilter;
