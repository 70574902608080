import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { FormikFieldWithError } from '..';

function FormikCheckbox({ name, label = '', onChange, ...rest }) {
  return (
    <FormikFieldWithError name={name}>
      {({ field }) => (
        <FormControlLabel
          {...rest}
          control={
            <Checkbox {...field} onChange={onChange || field.onChange} checked={field.value} />
          }
          label={label}
        />
      )}
    </FormikFieldWithError>
  );
}

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default FormikCheckbox;
