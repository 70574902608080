import { useMutation } from '@apollo/react-hooks';

import { ADD_PRODUCT_TO_ORDER } from '../graphql';
import { useCallback } from 'react';

export default function useAddProductToOrder(handleCompletedMutation) {
    const [create, result] = useMutation(ADD_PRODUCT_TO_ORDER, {
        onCompleted: handleCompletedMutation
    });

    const simpleCreate = useCallback(
        async data =>
            create({
                variables: { data },
            }),
        [create],
    );

    return [simpleCreate, result];
}
