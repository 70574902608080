import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import Link from '../Link';

function ButtonLink({ to, children, ...rest }) {
  return (
    <Button component={Link} to={to} {...rest}>
      {children}
    </Button>
  );
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ButtonLink;
