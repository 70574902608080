import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_ELB_CARD } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import { ELBCardToolbar, ELBCardInfoCard } from '..';

function ELBCard({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_ELB_CARD}>
      {elbCard => (
        <Document
          toolbar={<ELBCardToolbar elbCard={elbCard} />}
          infoCard={<ELBCardInfoCard elbCard={elbCard} />}
        />
      )}
    </WithDocumentQuery>
  );
}

ELBCard.propTypes = {
  match: Types.idMatch.isRequired,
};

export default ELBCard;
