import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';

import resolvers from './resolvers';
import { printErrors } from './errors';
import FETCH_POLICY from './fetchPolicy';
import { transformVariables } from './helpers';

const {
  REACT_APP_SERVER_URL: SERVER_URL,
  REACT_APP_GRAPHQL_ENDPOINT: GRAPHQL_ENDPOINT,
} = process.env;

const uri = `${SERVER_URL}${GRAPHQL_ENDPOINT}`;

export default function setupApolloClient() {
  const variablesLink = new ApolloLink((operation, forward) => {
    /**
     * `variables` field has to be reassigned,
     * because if a new `operation` object is returned the link breaks for some reason.
     */
    // eslint-disable-next-line no-param-reassign
    operation.variables = transformVariables(operation.variables);
    return forward(operation);
  });

  // Setup error handling
  const errorLink = onError(error => printErrors(error));

  // Setup authorization header
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // Setup GraphQL server's URI
  const httpLink = createHttpLink({
    uri,
  });

  // Setup cache
  const cache = new InMemoryCache({
    dataIdFromObject: object => object.id,
  });

  return new ApolloClient({
    link: ApolloLink.from([variablesLink, errorLink, authLink, httpLink]),
    cache,
    resolvers,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: FETCH_POLICY.NetworkOnly,
      },
      query: {
        fetchPolicy: FETCH_POLICY.NetworkOnly,
      },
      mutate: {
        fetchPolicy: FETCH_POLICY.NetworkOnly,
      },
    },
  });
}
