import gql from 'graphql-tag';

import { USER_FRAGMENT } from '../users/graphql';
import { ACCOUNT_FRAGMENT } from '../accounts/graphql';

export const EXPORT_TABLE = gql`
  mutation ExportTable($spreadsheetName: String, $table: [[Cell]]) {
    exportTable(spreadsheetName: $spreadsheetName, table: $table)
  }
`;

export const EXPORT_COLLECTION = gql`
  mutation ExportCollection($spreadsheetName: String, $collectionName: String) {
    exportCollection(spreadsheetName: $spreadsheetName, collectionName: $collectionName)
  }
`;

export const GET_TABLE_DATA = gql`
  query GetTableData($collectionName: String, $options: OptionsInput) {
    getTableData(collectionName: $collectionName, options: $options)
  }
`;

export const GET_USERS_AND_ACCOUNTS = gql`
  query GetUsersAndAccounts {
    users(options: {}) {
      entries {
        ...UserFragment
      }
    }

    accounts(options: {}) {
      entries {
        ...AccountFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
`;

export const GET_VERSION = gql`
  query GetVersion {
    version {
      apiVersion
      googleCloudRunRevision
    }
  }
`;
