import { GET_SETTINGS } from '../../settings/graphql';

import { useToggleMutation } from '../../lib/hooks';

import { INACTIVATE_BILLING_ACCOUNT, GET_BILLING_ACCOUNT } from '../graphql';

export default function useInactivateBillingAccount(id) {
  return useToggleMutation(INACTIVATE_BILLING_ACCOUNT, id, {
    refetchQueries: [{ query: GET_SETTINGS }, { query: GET_BILLING_ACCOUNT, variables: { id } }],
  });
}
