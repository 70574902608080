import React from 'react';

import {
  Fields,
  FormikTextField as FTextField,
  FormikFormError as FFormError,
  FormikSwitch,
  FormikCheckbox,
} from '../../../lib/components';
import { FormikCustomerAutocomplete as FCustomerAutocomplete } from '../../../customers/components';
import { FormikELBCardTypeAutocomplete as FELBCardTypeAutocomplete } from '../../../elb-card-types/components';
import InputAdornment from '@material-ui/core/InputAdornment';

function ELBCardFields({ isNew = false }) {

  return (
    <Fields>
      <FELBCardTypeAutocomplete
        name="elbCardType"
        InputProps={{
          required: true,
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />

      <FCustomerAutocomplete
        name="customer"
        InputProps={{
          required: true,
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />

      <FTextField
        name="elbCardNumber"
        label="ELB Card number"
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start">ELB</InputAdornment>,
        }}
        fullWidth
        margin="normal"
        //validate={validateELBCardNumberFormat}
      />

      {isNew ? (
        <FormikSwitch name="isWithoutPhysicalCardToggle" label="Without physical card" />
      ) : null}
      <br />
      {isNew ? (
        <FormikCheckbox
          name="isAddPhysicalCardToOnlineCard"
          label="Are you sure you want to add a physical card to already existing online card?"
        />
      ) : null}

      <FFormError />
    </Fields>
  );
}

export default ELBCardFields;
