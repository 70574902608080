import React from 'react';
import PropTypes from 'prop-types';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { useCacheFirstQuery } from '../../../lib/hooks';

import { MutateOrderItem } from '..';

function UpdateOrderItem({ id, query }) {
  const { loading, data } = useCacheFirstQuery(query, { variables: { id } });
  if (loading)
    return (
      <IconButton>
        <EditIcon />
      </IconButton>
    );
  return <MutateOrderItem data={data} />;
}

UpdateOrderItem.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
};

export default UpdateOrderItem;
