import React from 'react';
import * as PropTypes from 'prop-types';

import { GET_ELB_CARD_TYPE } from '../../graphql';

import { WithDocumentQuery, Document } from '../../../common/components';
import { ELBCardTypeToolbar, ELBCardTypeInfoCard } from '..';

function ELBCardType({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_ELB_CARD_TYPE}>
      {elbCardType => (
        <Document
          toolbar={<ELBCardTypeToolbar elbCardType={elbCardType} />}
          infoCard={<ELBCardTypeInfoCard elbCardType={elbCardType} />}
        />
      )}
    </WithDocumentQuery>
  );
}

ELBCardType.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default ELBCardType;
