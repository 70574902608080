import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  paper: {
    border: [1, 'solid', '#EEE'],
    borderRadius: 4,
    padding: theme.spacing(8),
  },
}));

const Minimal = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
      <Paper className={classes.paper}>{children}</Paper>
    </Grid>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
