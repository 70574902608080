import React from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

import { GET_ELB_CARDS } from '../../graphql';

import AutocompleteTypes from '../../../common/components/Table/AutocompleteTypes';
import { Table, CollectionTable } from '../../../common/components';
import ProductStatus from './ProductStatus';
import CreationType from './CreationType';

const columns = [
  {
    name: 'cardNumber',
    label: 'Number',
    options: {
      filterType: 'textField',
      renderCell: (value, { id }) => {
        console.log(value);
        console.log(id);
        return routes.elbCard.createLink(id, value);
      },
    },
  },
  {
    name: 'type.name',
    label: 'Type',
    autocompleteType: AutocompleteTypes.ELB_CARD_TYPE,
    options: {
      renderCell: (value, { type }) => routes.elbCardType.createLink(type.id, value),
      filterType: 'autocomplete',
    },
  },
  {
    name: 'type.price',
    label: 'Price',
    type: 'currency',
    options: {
      display: false,
    },
  },
  {
    name: 'customer.fullName',
    label: 'Customer',
    options: {
      renderCell: (value, { customer }) => routes.customer.createLink(customer.id, value),
      filterType: 'textField',
      display: false,
    },
  },
  {
    name: 'customer.email',
    label: 'Customer Email',
    options: {
      renderCell: (value, { customer }) =>
        routes.customer.createLink(customer.id, customer.fullName, value),
      filterType: 'textField',
    },
  },
  {
    name: 'createdAt',
    label: 'Created at',
    type: 'dateTime',
    options: {},
  },
  {
    name: 'soldBy.fullName',
    label: 'Sold By',
    options: {
      renderCell: (value, { soldBy }) => (soldBy ? routes.user.createLink(soldBy.id, value) : ''),
      filterType: 'textField',
    },
  },
  {
    name: 'expirationDate',
    label: 'Expiration Date',
    type: 'dateTime',
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filterOptions: Object.values(ProductStatus),
      filterType: 'dropdown',
    },
  },
  {
    name: 'creationType',
    label: 'Creation Type',
    options: {
      filterOptions: Object.values(CreationType),
      filterType: 'dropdown',
    },
  },
];

function ELBCardsTable({ elbCards, inline = false }) {
  console.log(elbCards);
  return (
    <CollectionTable>
      <Table
        title="ELB Cards List"
        collectionName="elbCards"
        query={elbCards ? undefined : GET_ELB_CARDS}
        data={elbCards}
        columns={columns}
        inline={inline}
      />
    </CollectionTable>
  );
}

ELBCardsTable.propTypes = {
  elbCards: Types.elbCards,
  inline: PropTypes.bool,
};

export default ELBCardsTable;
