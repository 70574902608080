import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';
import { FormikCardAccountAutocomplete } from '../../../accounts/components';
import { Typography } from '@material-ui/core';

function TerminalFields({ edit = false }) {
  return (
    <div style={{ minWidth: '300px' }}>
      {!edit && (
        <FTextField
          name="name"
          required
          type="text"
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}
      <FormikCardAccountAutocomplete
        name="account"
        InputProps={{
          label: 'Account (with Card type)',
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />
      <Typography variant="caption">You have to select an account with type: Card.</Typography>
      <FFormError />
    </div>
  );
}

TerminalFields.propTypes = {};

export default TerminalFields;
