import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GlobalELBCardForm } from '..';

function GlobalELBCard({ settings }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          ELB Card
        </Typography>

        <GlobalELBCardForm settings={settings} />
      </CardContent>
    </Card>
  );
}

GlobalELBCard.propTypes = {
  settings: Types.settings.isRequired,
};

export default GlobalELBCard;
