import React from 'react';
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { GET_EVENT_FOR_PRODUCT } from '../../../events/graphql';
import { format } from 'date-fns';
import routes from '../../../../utils/routes';

function buildQueryOptions(id) {
  return {
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  };
}

function EventSummary({ eventId, isEditing = false }) {
  const { data, loading, error } = useQuery(GET_EVENT_FOR_PRODUCT, buildQueryOptions(eventId));
  const { name, location, from, until } = data?.event || {};
  console.log({ data, error });
  const align = isEditing ? 'center' : 'flex-start';
  const maxWidth = isEditing ? '50%' : '100%';
  return (
    <Grid container justify={align} alignItems={align}>
      {error && (
        <>
          <strong>Something went wrong! Could not load event data</strong>
        </>
      )}
      {loading && (
        <Grid container justify={align} alignItems={align}>
          <Typography>Loading Event data</Typography>
          <CircularProgress style={{ marginLeft: '5px' }} size={20} thickness={4} color="primary" />
        </Grid>
      )}
      {data && !loading && (
        <TableContainer
          style={{
            maxWidth: maxWidth,
            marginTop: '8px',
          }}
        >
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{routes.event.createLink(eventId, name)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>{location}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>{format(new Date(from), 'yyyy-MM-dd HH:mm')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Until</TableCell>
                <TableCell>{format(new Date(until), 'yyyy-MM-dd HH:mm')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
}

export default EventSummary;
