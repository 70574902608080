import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Content } from '../../../common/components';

import CheckElbCardToolBar from '../CheckElbCardToolBar/CheckElbCardToolBar';
import CheckElbCardInfoCard from '../CheckElbCardInfoCard/CheckElbCardInfoCard';
import {  useReadElbCard } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';

import { isAfter } from 'date-fns';
import { useDebounce } from '../../../common/hooks';

function CheckElbCard() {
  const [cardNumber, setCardNumber] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [approvedIcon, setApprovedIcon] = useState(false);
  const [lastApiRequestTimestamp, setLastApiRequestTimestamp] = useState(null);
  const [lastInputChangeTimestamp, setInputChangeTimestamp] = useState(null);
  const debouncedCardNumber = useDebounce(cardNumber, 500);
  const { isError, isLoading, isSuccess, data, mutate } = useReadElbCard(debouncedCardNumber,setShowButtons,setApprovedIcon);

  console.log('data: ', { data: data, isSuccess: isSuccess });

  useEffect(() => {
    setInputChangeTimestamp(new Date());
  }, [cardNumber]);

  useEffect(() => {
    if (isSuccess && data) {
      setLastApiRequestTimestamp(new Date());
    }
  }, [isSuccess, data]);

  const isApiResponseOutdated = isAfter(lastInputChangeTimestamp, lastApiRequestTimestamp);

  return (
    <Content
      toolbar={
        <>
          <CheckElbCardToolBar
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            mutate={mutate}
          />
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isSuccess &&
            (data?.length > 0 ? (
              <CheckElbCardInfoCard
                data={data[0]}
                isError={isError}
                isLoading={isLoading}
                showButtons={showButtons}
                setShowButtons={setShowButtons}
                approvedIcon={approvedIcon}
                setApprovedIcon={setApprovedIcon}
              />
            ) : (
              !isApiResponseOutdated && (
                <Alert severity="info">
                  <AlertTitle>ELB Card checking</AlertTitle>
                  <p style={{ fontSize: '16px' }}>
                    There is <strong>no active</strong> ELB card with the search text:{' '}
                    <strong>{cardNumber}</strong>!
                  </p>
                </Alert>
              )
            ))}
        </Grid>
      </Grid>
    </Content>
  );
}

export default CheckElbCard;
