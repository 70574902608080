import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
  Fields,
} from '../../../lib/components';
import { FormikBillingAccountSelect as FBillingAccountSelect } from '../../../billing/components';
import { FormikFormAutocomplete as FFormAutocomplete } from '../../../events/components';

function GlobalELBCardFields() {
  return (
    <Fields>
      <FBillingAccountSelect
        name="billingAccount"
        required
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FTextField
        name="defaultExpirationTime"
        type="number"
        label="Default expiration time (months)"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FFormAutocomplete
        name="formId"
        InputProps={{
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />

      <FFormError />
    </Fields>
  );
}

export default GlobalELBCardFields;
