import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import * as Types from '../../../../../../utils/propTypes';
import { useGetUsers } from '../../../../../users/hooks';
import TableEntityAutoCompleteFilter from '../TableEntityAutoCompleteFilter';

function TableUserAutocompleteFilter({ column, filters, index, onChange }) {
  const [fieldText, setFieldText] = useState();
  const debouncedSetFieldText = debounce(setFieldText, 300);
  const onInputChange = useCallback(
    (_, value) => {
      debouncedSetFieldText(value);
    },
    [debouncedSetFieldText],
  );
  const { loading, data } = useGetUsers({
    variables: {
      options: {
        ...(fieldText
          ? { filters: [{ field: 'fullName', type: 'Text', values: [fieldText] }] }
          : null),
        pagination: { limit: 10 },
      },
    },
  });
  const users = data ? data.users.entries : [];
  const getOptionLabel = useCallback(({ fullName, email }) => `${fullName} (${email})`, []);
  return (
    <TableEntityAutoCompleteFilter
      column={column}
      filters={filters}
      index={index}
      onChange={onChange}
      label={column.label}
      property="fullName"
      options={users}
      loading={loading}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
    />
  );
}

TableUserAutocompleteFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(PropTypes.number).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TableUserAutocompleteFilter;
