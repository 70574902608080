import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { FileCopyOutlined as CopyIcon } from '@material-ui/icons';
import * as Types from '../../../../utils/propTypes';

import { useSnackbar, useEffectOnSuccess } from '../../../lib/hooks';
import { useGetTableData } from '../../hooks';

async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

function ExportButton({ collectionName, options }) {
  const [getTableData, result] = useGetTableData(collectionName, options);
  const { enqueueSnackbar } = useSnackbar();
  const { data } = result;

  useEffectOnSuccess(result, () => {
    copyTextToClipboard(data.getTableData);
    enqueueSnackbar('Copied!', 'success');
  });

  return (
    <Tooltip title="Copy to clipboard">
      <IconButton onClick={getTableData}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
}

ExportButton.propTypes = {
  collectionName: PropTypes.string,
  options: Types.queryOptions,
};

export default ExportButton;
