import { useQuery } from '@tanstack/react-query';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../products.constants';

export default function useGetProducts(values) {
  return useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const token = localStorage.getItem('token');
      const { searchText, type, eventId } = values;
      console.log('useGetProducts values:', values);
      const apiUrl = `/${PRODUCTS_API_ENDPOINTS.PRODUCTS_CONTROLLER_BASE_URL}`;
      const typeSearchQuery = `filter-by-type=${type}`;
      const eventIdSearchQuery = `eventId=${eventId}`;
      const searchTextSearchQuery = `search-keyword=${searchText}`
      let getProductsUrl = `${apiUrl}`;

      let parameters = 0;
      Object.keys(values).forEach((_) => { parameters = parameters + 1 });
      console.log('useGetProducts parameters:', parameters);

      let requestUrl = getProductsUrl;
      if (parameters === 0) {
        console.log('useGetProducts parameters === 0, API URL is ready to use');
        // nothing to do
      }
      else if (parameters === 1) {
        console.log('useGetProducts parameters === 1, API URL contains a single search key-value pair');
        if (type) {
          requestUrl = `${requestUrl}?${typeSearchQuery}`;
          console.log('useGetProducts parameters === 1, added type parameter to query:', requestUrl);
        }
        else if (eventId) {
          requestUrl = `${requestUrl}?${eventIdSearchQuery}`;
          console.log('useGetProducts parameters === 1, added eventId parameter to query:', requestUrl);
        }
        else if (searchText) {
          requestUrl = `${requestUrl}?${searchTextSearchQuery}`;
          console.log('useGetProducts parameters === 1, added searchText parameter to query:', requestUrl);
        }
      }
      else if (parameters > 1) {
        console.log('useGetProducts parameters > 1, use AND condition with multiple parameters');
        const andConditions = [];
        if (type) {
          andConditions.push(typeSearchQuery);
          console.log(`useGetProducts parameters > 1, added ${typeSearchQuery} to andConditions array:`, andConditions);
        }
        if (eventId) {
          andConditions.push(eventIdSearchQuery);
          console.log(`useGetProducts parameters > 1, added ${eventIdSearchQuery} to andConditions array:`, andConditions);
        }
        if (searchText) {
          andConditions.push(searchTextSearchQuery);
          console.log(`useGetProducts parameters > 1, added ${searchTextSearchQuery} to andConditions array:`, andConditions);
        }

        requestUrl = `${requestUrl}?${andConditions.join('&')}`;
      }

      console.log('useGetProducts final URL:', requestUrl);
      const { data } = await PRODUCTS_API_AXIOS_INSTANCE.get(requestUrl, { headers: { Authorization: token } })
      return data;
    },
    refetchOnWindowFocus: false,
    retry: 1
  });
}
