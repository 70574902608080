import { useRef, useEffect } from 'react';
import { isNil } from 'lodash';

export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  const hasChanged = !isNil(ref.current) && ref.current !== value;
  return [ref.current, hasChanged];
}
