import { useLazyQuery } from '@apollo/react-hooks';

import FETCH_POLICY from '../../../apollo/fetchPolicy';

export default function useManualQuery(query, options = {}) {
  const [refetch, result] = useLazyQuery(query, { fetchPolicy: FETCH_POLICY.CacheAndNetwork, ...options });
  const { loading, data } = result;
  return {
    ...result,
    loading: loading && !data,
    isLoading: loading,
    refetch,
  };
}
