import React, { useMemo, useCallback } from 'react';
import { isNumber } from 'lodash';
import { compose } from 'lodash/fp';
import { parse, isBefore, isAfter } from 'date-fns';
import clsx from 'clsx';

import {
  formatDateTime,
  formatDate,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  formatForint,
} from '../../../../../utils/formatters';
import TableNumberRangeFilter from '../components/TableNumberRangeFilter';
import TableDateTimeRangeFilter from '../components/TableDateTimeRangeFilter';

import ColumnTypes from '../ColumnTypes';
import FilterTypes from '../FilterTypes';

/**
 * Use this hidden object to get around this issue:
 * @see https://github.com/gregnb/mui-datatables/issues/940
 */
const hiddenIdColumn = {
  name: 'id',
  options: {
    display: 'excluded',
    filter: false,
    sort: false,
    empty: true,
  },
};

export default function useClientSideColumns(
  columns,
  data,
  normalizedData,
  classes,
  sort,
  filters,
  displayedColumns,
) {
  /**
   * Prevent this issue: @see https://github.com/gregnb/mui-datatables/issues/598
   * Filter values and row data are both rendered with the same `customBodyRender` inside `MUIDataTable`.
   * The filter values are rendered on the first `customBodyRender` call,
   * when unfortunately only the column's values are passed as `rowData`.
   * On subsequent renders, the `rowData` contains all of the columns as stated in the docs.
   * To support extensible row render with custom filter values and prevent crashes,
   * a custom `renderFilterValue` method needs to be passed which can't use the whole `rowData`.
   */
  const wrapCustomBodyRender = useCallback(
    (renderCell, renderFilterValue = renderCell) => {
      return function customBodyRender(value, tableMeta, updateValue) {
        const { rowIndex, rowData } = tableMeta;
        // Render rows, when `rowData` is an array containing the actual row data...
        if (Array.isArray(rowData)) {
          // Get object ID from hidden ID column
          const [id] = rowData;
          const object = normalizedData[id];
          return renderCell(value, object, tableMeta, updateValue);
        }
        /**
         * Filter render has no access to `rowData`,
         * but we can reference the `rowIndex` with the original `data` array to get the object ID,
         * then the object can be looked up in `normalizedData`.
         */
        const { id } = data[rowIndex];
        const object = normalizedData[id];
        return renderFilterValue(value, object, tableMeta, updateValue);
      };
    },
    [data, normalizedData],
  );

  const applyNumberRangeFilter = useCallback(column => {
    return {
      ...column,
      options: {
        ...column.options,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v) {
              if (v[0] && v[1]) {
                return `Min ${column.label}: ${v[0]}, Max ${column.label}: ${v[1]}`;
              }
              if (v[0]) {
                return `Min ${column.label}: ${v[0]}`;
              }
              if (v[1]) {
                return `Max ${column.label}: ${v[1]}`;
              }
            }
            return false;
          },
        },
        filterOptions: {
          ...column.filterOptions,
          logic: (value, filterList) => {
            if (filterList[0] && filterList[1]) {
              return value < filterList[0] || value > filterList[1];
            }
            if (filterList[0]) {
              return value < filterList[0];
            }
            if (filterList[1]) {
              return value > filterList[1];
            }
            return false;
          },
          display: (filterList, onChange, index, col) => (
            <TableNumberRangeFilter
              filters={filterList[index]}
              onChange={onChange}
              index={index}
              column={col}
            />
          ),
        },
      },
    };
  }, []);

  const applyTimestampRangeFilter = useCallback((column, formatter, format) => {
    return {
      ...column,
      options: {
        ...column.options,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            // Random hack, something sets the whole array to null, if one of the item is null
            if (v) {
              if (v[0] && v[1]) {
                return `${column.label} after ${formatter(v[0])} and before ${formatter(v[1])}`;
              }
              if (v[0]) {
                return `${column.label} after ${formatter(v[0])}`;
              }
              if (v[1]) {
                return `${column.label} before ${formatter(v[1])}`;
              }
            }
            return false;
          },
        },
        filterOptions: {
          ...column.filterOptions,
          logic: (value, filterList) => {
            const parsedValue = parse(value, format, new Date());
            if (filterList[0] && filterList[1]) {
              return !(isAfter(parsedValue, filterList[0]) && isBefore(parsedValue, filterList[1]));
            }
            if (filterList[0]) {
              return !isAfter(parsedValue, filterList[0]);
            }
            if (filterList[1]) {
              return !isBefore(parsedValue, filterList[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, col) => (
            <TableDateTimeRangeFilter
              format={format}
              filters={filterList[index]}
              onChange={onChange}
              index={index}
              column={col}
            />
          ),
        },
      },
    };
  }, []);

  const applyDateTimeRangeFilter = useCallback(
    column => {
      return applyTimestampRangeFilter(column, formatDateTime, DATE_TIME_FORMAT);
    },
    [applyTimestampRangeFilter],
  );

  /**
   * @todo Time is still implicitly compared, it should use the 00:00 for starting date and 23:59 for before date
   */
  const applyDateRangeFilter = useCallback(
    column => {
      return applyTimestampRangeFilter(column, formatDate, DATE_FORMAT);
    },
    [applyTimestampRangeFilter],
  );

  const applyLongTextType = useCallback(
    column => {
      return {
        ...column,
        options: {
          ...column.options,
          setCellProps: value => ({ title: value, className: clsx(classes.longText) }),
        },
      };
    },
    [classes.longText],
  );

  const applyCurrencyType = useCallback(column => {
    return {
      ...column,
      options: {
        ...column.options,
        renderCell: value => (isNumber(value) ? <span>{formatForint(value)}</span> : null),
        filterType: 'numberRange',
      },
    };
  }, []);

  const applyNumberType = useCallback(column => {
    return {
      ...column,
      options: {
        ...column.options,
        filterType: 'numberRange',
      },
    };
  }, []);

  const applyDateTimeType = useCallback(column => {
    return {
      ...column,
      options: {
        ...column.options,
        renderCell: date => (date ? formatDateTime(date) : null),
        filterType: 'dateTimeRange',
      },
    };
  }, []);

  const applyType = useCallback(
    column => {
      const { type } = column;
      if (!type) return column;
      switch (type) {
        default:
          return column;

        case ColumnTypes.CURRENCY:
          return applyCurrencyType(column);

        case ColumnTypes.NUMBER:
          return applyNumberType(column);

        case ColumnTypes.LONG_TEXT:
          return applyLongTextType(column, classes);

        case ColumnTypes.DATE_TIME:
          return applyDateTimeType(column);
      }
    },
    [applyCurrencyType, applyNumberType, applyLongTextType, applyDateTimeType, classes],
  );

  const applyFilter = useCallback(
    column => {
      const filter = filters[column.name];
      return {
        ...column,
        options: {
          ...column.options,
          filterList: filter,
        },
      };
    },
    [filters],
  );

  const applyCustomBodyRender = useCallback(
    column => {
      const { options } = column;
      if (!options) return column;
      const { renderCell: rc, renderFilterValue: rfv } = options;
      return {
        ...column,
        options: {
          ...options,
          customBodyRender: rc ? wrapCustomBodyRender(rc, rfv) : undefined,
        },
      };
    },
    [wrapCustomBodyRender],
  );

  const applyCustomFilter = useCallback(
    column => {
      const { options } = column;
      if (!options) return column;
      switch (options.filterType) {
        default:
          return column;

        case FilterTypes.NUMBER_RANGE:
          return applyNumberRangeFilter(column);

        case FilterTypes.DATE_RANGE:
          return applyDateRangeFilter(column);

        case FilterTypes.DATE_TIME_RANGE:
          return applyDateTimeRangeFilter(column);
      }
    },
    [applyNumberRangeFilter, applyDateRangeFilter, applyDateTimeRangeFilter],
  );

  const applyDisplay = useCallback(
    column => ({
      ...column,
      options: {
        ...column.options,
        display: displayedColumns.includes(column.name),
      },
    }),
    [displayedColumns],
  );

  return useMemo(() => {
    const assembledColumns = columns.map(
      compose(
        applyDisplay,
        applyFilter,
        applyCustomFilter,
        applyCustomBodyRender,
        applyType,
      ),
    );
    return [hiddenIdColumn, ...assembledColumns];
  }, [columns, applyCustomBodyRender, applyCustomFilter, applyType, applyFilter, applyDisplay]);
}
