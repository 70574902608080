import React from 'react';
import { isEmpty } from 'lodash';

import * as Types from '../../../../utils/propTypes';
import {
  updateUser as updateSchema,
  createUser as createSchema,
} from '../../../../utils/validationSchemas';

import { useUpdateUser, useCreateUser } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { UserFormFields } from '..';

function MutateUser({ user = {}, refetchQueries }) {
  const isNew = isEmpty(user);
  const { id, firstName, lastName, role } = user;

  const dialogProps = {
    title: `${isNew ? 'Add' : 'Edit'} User`,
    submitButtonText: `${isNew ? 'Add' : 'Edit'} User`,
    fields: <UserFormFields edit={!isNew} />,
  };

  const [create] = useCreateUser(refetchQueries ? { refetchQueries } : undefined);
  const [update] = useUpdateUser(id);

  /**
   * The default value for an input is an empty string.
   * Some fields are set to `undefined` to remove them from form state if they are not needed.
   */
  const initialValues = {
    firstName: isNew ? undefined : firstName,
    lastName: isNew ? undefined : lastName,
    email: isNew ? '' : undefined,
    role: isNew ? '' : role.id,
  };
  const formikProps = {
    initialValues,
    validationSchema: isNew ? createSchema : updateSchema,
    onSubmit: isNew ? create : update,
  };

  const snackbarProps = {
    successText: isNew
      ? 'Registration link has been sent to the new user!'
      : 'User has been updated!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={`${isNew ? 'Add' : 'Edit'} User`}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

MutateUser.propTypes = {
  user: Types.user,
  refetchQueries: Types.refetchQueries,
};

export default MutateUser;
