import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

export default function useToggleMutation(mutation, defaultId, options = {}) {
  const [mutate, result] = useMutation(mutation, {
    ...(defaultId && { variables: { id: defaultId } }),
    ...options,
  });
  const simpleMutate = useCallback(
    async callbackId => (callbackId ? mutate({ variables: { id: callbackId } }) : mutate()),
    [mutate],
  );
  return [simpleMutate, result];
}
