import React from 'react';
import * as PropTypes from 'prop-types';

import { updateElbCardType as elbCardTypeSchema } from '../../../../utils/validationSchemas';

import { useUpdateElbCardType } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { ELBCardTypeFormFields } from '..';

function UpdateELBCardType({ id, name }) {
  const dialogProps = {
    title: 'Edit ELB Card Type',
    submitButtonText: 'Edit ELB Card Type',
    fields: <ELBCardTypeFormFields edit />,
  };

  const [updateCustomer] = useUpdateElbCardType(id);
  const initialValues = {
    name,
  };
  const formikProps = {
    initialValues,
    validationSchema: elbCardTypeSchema,
    onSubmit: updateCustomer,
  };

  const snackbarProps = {
    successText: 'ELB Card type was successfully edited!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Edit ELB Card Type"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

UpdateELBCardType.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default UpdateELBCardType;
