import React from 'react';
import * as PropTypes from 'prop-types';

import { MutateTicketType } from '..';

function AddTicketType({ eventId }) {
  return <MutateTicketType eventId={eventId} />;
}

AddTicketType.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default AddTicketType;
