import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { UpdateELBCard, ActivateELBCard, InactivateELBCard } from '..';

function ELBCardToolbar({ elbCard }) {
  const access = useCheckPrivilege(Privileges.UPDATE_ELB_CARD);
  if (!access) return null;

  const {
    id,
    customer: { fullName },
    status,
    expirationDate,
    physicalCardNumber,
    isDelivered
  } = elbCard;
  return (
    <Toolbar>
      {status === 'Active'
        ? [
            <InactivateELBCard key="InactivateELBCard" id={id} fullName={fullName} />,
            <UpdateELBCard
              key="UpdateELBCard"
              id={id}
              expirationDate={expirationDate}
              physicalCardNumber={physicalCardNumber}
              isDelivered={isDelivered}
              fullName={fullName}
            />,
          ]
        : null}

      {status === 'Inactive' ? <ActivateELBCard id={id} expirationDate={expirationDate} /> : null}
    </Toolbar>
  );
}

ELBCardToolbar.propTypes = {
  elbCard: Types.elbCard.isRequired,
};

export default ELBCardToolbar;
