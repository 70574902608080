import { Box, Button, Grid, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { useEventBrowser } from '../../hooks';
import { format } from 'date-fns';
import { Alert } from '@material-ui/lab';
import { FillerSpinner } from '../../../lib/components';
import { TabPanel } from '../../../common/components';
import EventsMobileCard from '../EventsMobileCard';
import ClearIcon from '@material-ui/icons/Clear';

const TABS = {
  TAB_PAST_EVENTS: "TAB_PAST_EVENTS",
  TAB_UPCOMING_EVENTS: "TAB_UPCOMING_EVENTS"
}

function EventsMobile() {
  const [eventName, setEventName] = useState('');
  const [afterDate, setAfterDate] = useState(null);
  const [beforeDate, setBeforeDate] = useState(null);
  const [searchParams, setSearchParams] = useState([]);
  const [tabValue, setTabValue] = useState(TABS.TAB_UPCOMING_EVENTS);

  const { isError, error, data, isSuccess, isLoading } = useEventBrowser(searchParams);

  console.log({ data, eventName, fromDate: afterDate, untilDate: beforeDate, searchParams });

  const handleEventNameChange = event => {
    setEventName(event.target.value);
  };
  const handleAfterDateChange = date => {
    setAfterDate(date);
  };
  const handleBeforeDateChange = date => {
    setBeforeDate(date);
  };

  const handleResetForm = () => {
    setEventName('');
    setAfterDate(null);
    setBeforeDate(null);
  };

  const handleSearchButtonClick = () => {
    setSearchParams({
      searchName: eventName,
      searchFromDate: afterDate ? format(new Date(afterDate), 'yyyy-MM-dd') : undefined,
      searchUntilDate: beforeDate ? format(new Date(beforeDate), 'yyyy-MM-dd') : undefined,
    });
  };
  const handleShowMoreButtonClick = () => {
    setSearchParams({
      searchName: eventName,
      searchFromDate: afterDate,
      searchUntilDate: beforeDate,
      searchLimit: data?.data.length + 10,
    });
  };

  const handleTabChange = (event, newValue) => {
    console.log('handleTabChange', newValue);
    setTabValue(newValue);
  };

  return (
    <>
      {isError && (
        <Alert severity="error">An error occured during data loading: {error.message}</Alert>
      )}
      {isLoading && <FillerSpinner />}
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <TextField
            id="standard-basic"
            label="Event Name"
            value={eventName}
            onChange={handleEventNameChange}
            style={{ margin: '3px', minWidth: "250px" }}
          />
          <ClearIcon
            style={{ alignSelf: 'end', cursor: 'pointer', marginBottom: '7px' }}
            onClick={() => {
              setEventName('');
            }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <KeyboardDatePicker
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            label="After date"
            placeholder="YYYY-MM-DD"
            value={afterDate}
            onChange={handleAfterDateChange}
            style={{ margin: '3px', minWidth: "250px" }}
            autoOk={true}
          />
          <ClearIcon
            style={{ alignSelf: 'end', cursor: 'pointer', marginBottom: '7px' }}
            onClick={() => {
              setAfterDate(null);
            }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <KeyboardDatePicker
            variant="inline"
            format="yyyy-MM-dd"
            margin="normal"
            label="Before date"
            placeholder="YYYY-MM-DD"
            value={beforeDate}
            onChange={handleBeforeDateChange}
            style={{ margin: '3px', minWidth: "250px" }}
            autoOk={true}
          />
          <ClearIcon
            style={{ alignSelf: 'end', cursor: 'pointer', marginBottom: '7px' }}
            onClick={() => {
              setBeforeDate(null);
            }}
          />
        </div>
        <br />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchButtonClick}
            style={{ margin: '3px', marginTop: '12px' }}
          >
            Search
          </Button>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleResetForm}
            style={{ margin: '3px', marginTop: '12px' }}
          >
            Clear form
          </Button>
        </div>
      </form>
      <Typography variant="h2" style={{ margin: '8px' }}>
        Events
      </Typography>
      {data?.totalEvents > 0 ? (
        <Typography variant="body1" style={{ margin: '8px' }}>
          Search results: <strong>{data?.totalEvents} events</strong>
        </Typography>
      ) : (
        <Typography variant="body1" style={{ margin: '8px' }}>
          No results for your search
        </Typography>
      )}
      {isSuccess && (
        <Box>
          <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Past Events" value={TABS.TAB_PAST_EVENTS} />
              <Tab label="Upcoming Events" value={TABS.TAB_UPCOMING_EVENTS} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={TABS.TAB_PAST_EVENTS}>
            {data?.pastEvents?.length > 0 ? (
              data?.pastEvents.map(event => <EventsMobileCard key={event.id} event={event}></EventsMobileCard>)
            ) : (
              'No past events'
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={TABS.TAB_UPCOMING_EVENTS}>
            {data?.upcomingEvents?.length > 0 ? (
              data?.upcomingEvents.map(event => <EventsMobileCard key={event.id} event={event}></EventsMobileCard>)
            ) : (
              'No upcoming events'
            )}
          </TabPanel>
          <Grid container justify="center" direction="column">
            {data?.hasMore === true ? (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleShowMoreButtonClick}
                  style={{ margin: '3px', marginTop: '12px' }}
                >
                  Load more events
                </Button>
              </div>
            ) : (
              <Typography variant="body1" style={{ margin: '8px' }}>
                End of results
              </Typography>
            )}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                style={{ margin: '3px', marginTop: '12px' }}
              >
                Go to top of the page
              </Button>
            </div>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default EventsMobile;
