import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';

import Privileges from '../../../../../../utils/access-control/privileges.json';
import { useCheckPrivilege } from '../../../../../lib/hooks';
import * as TableTypes from '../../propTypes';
import Actions from '../../Actions';
import { useDisplayedColumns, useSearch, useExport } from '../../hooks';

import ExportButton from '../../../ExportButton';
import ExportDialog from '../../../ExportDialog';
import TableServerSide from '../TableServerSide';
import TableClientSide from '../TableClientSide';

function TableContainer({
  title = 'Table',

  columns,

  data,

  query,
  clipboardOptions,
  collectionName,

  inline = false,

  options: {
    rowsPerPageOptions = inline ? [5, 10, 15] : [10, 20, 40, 60, 80, 100],
    rowsPerPage: rowsPerPageProp = rowsPerPageOptions[0],

    enableExport = true,
    serverSide = Boolean(query),

    responsive = 'standard',
    selectableRows = 'none',
    enableNestedDataAccess = '.',

    enableSort = true,
    enableFilter = true,
    enableSearch = true,

    ...restOptions
  } = {},

  sortModel,
  onSortModelChange,
}) {

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageProp);
  const onChangeRowsPerPage = useCallback(numberOfRows => setRowsPerPage(numberOfRows), []);

  const [search, setSearch] = useSearch();
  const onSearchChange = useCallback(searchTextInput => setSearch(searchTextInput), [setSearch]);

  const [displayedColumns, showColumn, hideColumn] = useDisplayedColumns(columns);
  const onViewColumnsChange = useCallback(
    (changedColumn, action) =>
      action === 'add' ? showColumn(changedColumn) : hideColumn(changedColumn),
    [hideColumn, showColumn],
  );

  const access = useCheckPrivilege(Privileges.EXPORT, collectionName);
  const [exportOptions, modal, { isExporting, handleSubmit, result }] = useExport(
    access,
    collectionName,
    serverSide,
  );

  const onTableChange = useCallback(
    (action, muiTableState) => {
      if (action === Actions.CHANGE_PAGE) {
        setPage(muiTableState.page);
      }
    },
    [setPage],
  );

  const state = useMemo(
    () => ({
      title,
      columns,
      data,

      page,
      rowsPerPage,

      search,

      displayedColumns,
    }),
    [columns, data, displayedColumns, page, rowsPerPage, search, title],
  );

  const setters = useMemo(
    () => ({
      setPage,

      setRowsPerPage,

      setSearch,

      showColumn,
      hideColumn,
    }),
    [hideColumn, setSearch, showColumn],
  );

  const handlers = useMemo(
    () => ({
      onChangeRowsPerPage,

      onSearchChange,

      onViewColumnsChange,
      onTableChange,
    }),
    [onChangeRowsPerPage, onViewColumnsChange, onSearchChange, onTableChange],
  );

  /** Build options object for mui-datatables */
  const options = useMemo(
    () => ({
      page,
      rowsPerPage,
      rowsPerPageOptions,
      responsive,
      enableNestedDataAccess,
      selectableRows,
      download: enableExport,
      sort: enableSort,
      filter: enableFilter,
      search: enableSearch,
      customToolbar: () => {
        if (enableExport && access) {
          return <ExportButton collectionName={collectionName} options={clipboardOptions} />;
        }

        return null;
      },
      serverSide,
      ...exportOptions,
      ...handlers,
      ...restOptions,
    }),
    [
      enableNestedDataAccess,
      enableExport,
      enableFilter,
      enableSearch,
      enableSort,
      exportOptions,
      handlers,
      page,
      responsive,
      restOptions,
      rowsPerPage,
      rowsPerPageOptions,
      selectableRows,
      serverSide,
      collectionName,
      clipboardOptions,
      access,
    ],
  );

  return (
    <>
      {serverSide ? (
        <TableServerSide state={state} setters={setters} options={options} query={query} sortModel={sortModel} onSortModelChange={onSortModelChange} />
      ) : (
        <TableClientSide state={state} setters={setters} options={options} />
      )}
      {enableExport && access ? (
        <ExportDialog
          title={title}
          isExporting={isExporting}
          result={result}
          onSubmit={handleSubmit}
          modal={modal}
        />
      ) : null}
    </>
  );
}

TableContainer.propTypes = {
  title: PropTypes.node,
  data: isRequiredIf(TableTypes.data, props => !props.query),
  columns: TableTypes.columns,
  options: TableTypes.containerOptions,
  inline: PropTypes.bool,
  query: isRequiredIf(PropTypes.object, props => !props.data),
  clipboardOptions: isRequiredIf(PropTypes.object, props => props.data),
  collectionName: isRequiredIf(PropTypes.string, props => props.query),
};

export default TableContainer;
