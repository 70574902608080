import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export default function useNewElbCardReading(entryData, setShowButtons, setApprovedIcon) {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useMutation({
    mutationKey: ['new-elb-card-entry', entryData],
    mutationFn: async entryData => {
      console.log('entryData: ', entryData);
      const response = await axios.post(`${REACT_APP_SERVER_URL}checking/new-entry`, entryData);
      const data = await response.data;
      return data;
    },
    onSuccess: () => {
      setShowButtons(true);
      setApprovedIcon(false);
    },
    refetchOnWindowFocus: false,
  });
}
