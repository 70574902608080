import React from 'react';

import { Collection } from '../../../common/components';
import { TicketsTable } from '..';

function Tickets() {
  return <Collection table={<TicketsTable />} />;
}

export default Tickets;
