import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';
import routes from '../../../../utils/routes';

import { GET_ORDERS } from '../../graphql';

import AutocompleteTypes from '../../../common/components/Table/AutocompleteTypes';
import { Table, CollectionTable } from '../../../common/components';
import OrderStatus from './OrderStatus';
import PaymentChannel from './PaymentChannel';

const columns = [
  {
    name: 'orderSeqId',
    label: 'ID',
    options: {
      renderCell: (value, { id, orderSeqId }) => {
        return orderSeqId ? routes.order.createLink(id, `OR${orderSeqId}`) : null;
      },
      filterType: 'textField',
    },
  },
  {
    name: 'createdAt',
    label: 'Created at',
    type: 'dateTime',
    options: {},
  },
  {
    name: 'billingCustomer.fullName',
    label: 'Customer',
    options: {
      renderCell: (value, order) =>
        order.billingCustomer ? routes.customer.createLink(order.billingCustomer.id, value) : '',
      filterType: 'textField',
    },
  },
  {
    name: 'billingCustomer.email',
    label: 'Customer Email',
    options: {
      renderCell: (value, order) =>
        order.billingCustomer
          ? routes.customer.createLink(
              order.billingCustomer.id,
              order.billingCustomer.fullName,
              value,
            )
          : '',
      filterType: 'textField',
    },
  },
  {
    name: 'createdBy.fullName',
    label: 'Employee',
    autocompleteType: AutocompleteTypes.USER,
    options: {
      renderCell: (value, order) => routes.user.createLink(order.createdBy, value),
      filterType: 'autocomplete',
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filterOptions: Object.values(OrderStatus),
      filterType: 'dropdown',
    },
  },
  {
    name: 'payment',
    label: 'Payment',
    options: {
      renderCell: value => {
        if (value.cashSubtotal === null && value.cardSubtotal === null) {
          return 'Cash';
        } else if (
          (value.cashSubtotal && value.cardSubtotal === null) ||
          (value.cashSubtotal && value.cardSubtotal === 0)
        ) {
          return 'Cash';
        } else if (
          (value.cardSubtotal && value.cashSubtotal === null) ||
          (value.cardSubtotal && value.cashSubtotal === 0)
        ) {
          return 'Card';
        } else if (value.cashSubtotal && value.cardSubtotal) {
          return 'Mixed';
        } else {
          return 'Unknown';
        }
      },
    },
  },
  {
    name: 'amount',
    label: 'Amount',
    type: 'currency',
  },
  {
    name: 'items',
    label: 'Number of Items',
    type: 'number',
  },
  {
    name: 'paymentChannel',
    label: 'Payment Channel',
    options: {
      filterOptions: Object.values(PaymentChannel),
      filterType: 'dropdown',
    },
  },
];

function OrdersTable({ orders, inline = false }) {
  const [sortModel, setSortModel] = useState({
    name: 'orderSeqId',
    sort: 'desc',
  });
  return (
    <>
      <CollectionTable>
        <Table
          title="Orders List"
          collectionName="orders"
          query={orders ? undefined : GET_ORDERS}
          data={orders}
          columns={columns}
          inline={inline}
          sortModel={sortModel}
          onSortModelChange={model => setSortModel(model)}
        />
      </CollectionTable>
    </>
  );
}

OrdersTable.propTypes = {
  orders: Types.orders,
  inline: PropTypes.bool,
};

export default OrdersTable;
