import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { isFunction } from 'lodash';

import { transformApiError } from '../../../../apollo/errors';

function createAsyncFormikSubmitHandler(onSubmit, onSuccess, onFailure) {
  return async function asyncSubmit(values, formikBag) {
    const { setSubmitting, setStatus, setErrors } = formikBag;
    try {
      setStatus(null);
      const res = await onSubmit(values, formikBag);
      setSubmitting(false);
      if (onSuccess) onSuccess(res);
    } catch (error) {
      let global = error.message;
      let fields;
      if (error.graphQLErrors) {
        const apiError = transformApiError(error);
        ({ global, fields } = apiError);
      }
      setSubmitting(false);
      if (fields) setErrors(fields);
      if (global) setStatus(global);
      if (onFailure) onFailure(error);
    }
  };
}

function FormikAsync({ onSubmit, onSuccess, onFailure, children, ...rest }) {
  const handleAsyncSubmit = createAsyncFormikSubmitHandler(onSubmit, onSuccess, onFailure);
  return (
    <Formik onSubmit={handleAsyncSubmit} {...rest}>
      {isFunction(children) ? formikBag => children(formikBag) : children}
    </Formik>
  );
}

FormikAsync.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default FormikAsync;
