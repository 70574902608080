import React from 'react';
import { Grid } from '@material-ui/core';

import { passwordResetRequest as passwordResetRequestSchema } from '../../../../utils/validationSchemas';

import { useSendPasswordResetEmail } from '../../hooks';

import {
  FormikAsyncWithSnackbar as Formik,
  FormikTextField as FTextField,
  SubmitButton,
  FormikFormWithError as FFormWithError,
} from '../../../lib/components';

const initialValues = {
  email: '',
};

function PasswordResetRequestForm() {
  const [sendResetPasswordMail] = useSendPasswordResetEmail();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={passwordResetRequestSchema}
      onSubmit={sendResetPasswordMail}
      successText="Password reset mail has been sent!"
    >
      <FFormWithError>
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item>
            <FTextField
              name="email"
              type="email"
              required
              label="Email address"
              variant="outlined"
              fullWidth
              margin="none"
            />
          </Grid>

          <Grid item>
            <SubmitButton fullWidth variant="contained" color="primary" type="submit">
              Request password reset
            </SubmitButton>
          </Grid>
        </Grid>
      </FFormWithError>
    </Formik>
  );
}

export default PasswordResetRequestForm;
