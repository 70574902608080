import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_ACCOUNTS } from '../../graphql';

import { Collection } from '../../../common/components';
import AccountsToolbar from '../AccountsToolbar';
import AccountsTable from '../AccountsTable';

function Accounts() {
  return (
    <Collection
      toolbar={<AccountsToolbar refetchQueries={[getOperationName(GET_ACCOUNTS)]} />}
      table={<AccountsTable />}
    />
  );
}

export default Accounts;
