import React, { useState } from 'react';

import { Card, CardContent, Typography, Grid, Box, Dialog, DialogContent } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format } from 'date-fns';

import '../../../../elb-card-reading/components/ElbCardReadingInfoCard/pulse.css';

import {
  CheckCircle as ApprovedPictureIcon,
  Cancel as DeclinedPictureIcon,
  Help as MissingApprovalIcon,
} from '@material-ui/icons';
import { FillerSpinner } from '../../../../lib/components';
import { ProfilePicAction } from '../../../../elb-card-reading/components';

function EventCheckingInfoCard({
  data,
  isError,
  isLoading,
  className,
  showButtons,
  setShowButtons,
  approvedClass,
  setApprovedIcon,
}) {
  const rows = [
    {
      label: 'Checking User',
      value: data?.userFullName,
    },
    {
      label: 'Check Timer',
      value: format(new Date(data.createdDate), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      label: 'Customer Email',
      value: data.customerEmail,
    },
    {
      label: 'Card Number',
      value: data.cardNumber,
    },
    {
      label: 'Online card number',
      value: data.onlineCardNumber ? data.onlineCardNumber : 'Not filled in yet',
    },
    {
      label: 'Expiration Date',
      value: data.expirationDate
        ? format(new Date(data.expirationDate), 'yyyy-MM-dd HH:mm:ss')
        : null,
    },
    {
      label: 'Status',
      value: data.status,
    },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const rows_profilePic = [
    {
      label: 'Customer',
      value: data.customerFullName,
    },
    {
      label: 'Picture',
      value: data?.profilePicture?.url ? (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box style={{ display: 'flex', justifyContent: 'center', margin: 8 }}>
            <img
              src={data?.profilePicture?.url}
              alt=""
              width="100px"
              onClick={handleOpen}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Grid
            item
            container
            style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
          >
            {approvedClass === 'approved' && className === 'specialClass' ? (
              <ApprovedPictureIcon htmlColor="green" />
            ) : approvedClass === 'declined' && className === 'specialClass' ? (
              <DeclinedPictureIcon htmlColor="red" />
            ) : !data?.profilePicture?.timeOfApproval ||
              (showButtons && !data?.profilePicture?.timeOfApproval) ? (
              <>
                <MissingApprovalIcon htmlColor="orange" />
                <Typography>Click on the picture to approve or decline it</Typography>
              </>
            ) : data?.profilePicture?.approved ? (
              <ApprovedPictureIcon htmlColor="green" />
            ) : (
              <DeclinedPictureIcon htmlColor="red" />
            )}
          </Grid>
          <Dialog open={open} onClose={handleClose} style={{ justifyContent: 'center' }}>
            <DialogContent style={{ fontSize: '20px', textAlign: 'center' }}>
              <p>
                <i>{data.customerFullName}</i>
              </p>
              <p>{data.onlineCardNumber}</p>
              <img src={data?.profilePicture?.url} alt="" width="100%" />
              <Grid
                item
                container
                style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
              >
                {approvedClass === 'approved' && className === 'specialClass' ? (
                  <ApprovedPictureIcon htmlColor="green" />
                ) : approvedClass === 'declined' && className === 'specialClass' ? (
                  <DeclinedPictureIcon htmlColor="red" />
                ) : !data?.profilePicture?.timeOfApproval ||
                  (showButtons && !data?.profilePicture?.timeOfApproval) ? (
                  <MissingApprovalIcon htmlColor="orange" />
                ) : data?.profilePicture?.approved ? (
                  <ApprovedPictureIcon htmlColor="green" />
                ) : (
                  <DeclinedPictureIcon htmlColor="red" />
                )}
              </Grid>
              {className === 'specialClass' && showButtons && (
                <ProfilePicAction
                  data={data}
                  setShowButtons={setShowButtons}
                  setApprovedIcon={setApprovedIcon}
                />
              )}
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <p>
          <strong>No picture </strong>
        </p>
      ),
    },
  ];

  let style = { margin: '8px 0px' };
  // if (data.userVoucherWasAlreadyUsed || data.wasReadInLast12Hours) {
  //   style = { ...style, backgroundColor: 'lightcoral' };
  // } else {
  //   style = { ...style, backgroundColor: 'palegreen' };
  // }

  return (
    <>
      {isLoading && <FillerSpinner />}
      {isError && (
        <>
          <strong>Failed checking ELB Card</strong>
        </>
      )}
      {className === 'specialClass' && data.timeBetweenChecks && (
        <Alert severity="info">
          <AlertTitle>Card Reading</AlertTitle>
          <p style={{ fontSize: '16px' }}>
            ELB Card with card number: {data.cardNumber} was already checked{' '}
            <strong>{data.timeBetweenChecks} minute(s) ago</strong>!
          </p>
        </Alert>
      )}

      <Card style={style} className={className}>
        <CardContent>
          <Typography variant="h3" component="h1" gutterBottom>
            {`ELB Card: ${data.cardNumber === 'ELB' ? data.onlineCardNumber : data.cardNumber}`}
          </Typography>

          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ padding: '8px' }}
            >
              {rows_profilePic.map(({ label, value }) => (
                <Grid item container key={label} style={{ justifyContent: 'center' }}>
                  <Grid item>
                    <Typography variant="body1">{value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ padding: '8px' }}
            >
              {rows.map(({ label, value }) => (
                <Grid item key={label}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1">{label}:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <strong>{value}</strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default EventCheckingInfoCard;
