import React from 'react';
import CreateOrEditProduct from '../CreateOrEditProduct';
import { useGetProductById, useUpdateProduct } from '../../hooks';

function Product({
  match: {
    params: { id },
  },
}) {
  const { data } = useGetProductById(id);
  const { isError, error, isLoading, mutate } = useUpdateProduct(id);
  console.log({ data });
  return (
    <CreateOrEditProduct
      error={error}
      isError={isError}
      isLoading={isLoading}
      mutate={mutate}
      isEditing={true}
      initialProductData={data}
    />
  );
}

export default Product;
