import React from 'react';
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { differenceInDays, format } from 'date-fns';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import routes from '../../../../utils/routes';
import { formatForint } from '../../../../utils/formatters';

function EventsMobileCard(event) {
  const { id, name, from, until, forSale, ticketLimit, ticketStats, totalSales } = event.event;
  //const linkName = routes.event.createLink(id, name);
  const urlName = routes.event.createUrl(id, name);
  const differenceBetweenFromAndUntil = differenceInDays(new Date(until), new Date(from));

  return (
    <Link href={urlName} style={{ textDecoration: 'none' }} component={'a'}>
      <Card style={{ padding: 0, marginBottom: '8px' }} key={id}>
        <CardContent>
          <List dense={true} disablePadding>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemText
                primary={
                  <>
                    {format(new Date(from), 'yyyy. MMMM dd. HH:mm')} -{' '}
                    {differenceBetweenFromAndUntil < 1
                      ? format(new Date(until), 'HH:mm')
                      : format(new Date(until), 'MMMM dd. HH:mm')}
                  </>
                }
                style={{ fontSize: '16px' }}
                primaryTypographyProps={{
                  style: {
                    fontSize: '16px',
                  },
                }}
              />
            </ListItem>
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemText
                primary={name}
                primaryTypographyProps={{
                  style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                  },
                }}
              />
            </ListItem>
            {ticketLimit && (
              <Box display="flex" alignItems="center" flexDirection={"column"}>
                <Box width={"100%"}>
                  <LinearProgress
                    variant="determinate"
                    value={(ticketStats?.active / ticketLimit) * 100}
                    style={{
                      height: "8px"
                    }}
                  />
                </Box>
                <Box width="100%" marginTop="4px">
                  <Typography variant="body1" color="textSecondary" align='center' style={{ fontSize: '18px' }}>
                    {ticketStats?.active} / {ticketLimit || '-'} ({`${(ticketStats?.active / ticketLimit) * 100}%`})
                  </Typography>
                </Box>
              </Box>
            )}
            <ListItem disableGutters style={{ padding: 0 }}>
              <ListItemText
                primary={<>Total Sales: {formatForint(totalSales)}</>}
                style={{ fontSize: '16px' }}
                primaryTypographyProps={{
                  style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                  },
                }}
              />
            </ListItem>
            <ListItem disableGutters style={{ padding: 0 }}>
              <Typography variant="body1" color="textSecondary" align='center' style={{ fontSize: '16px' }}>
                For Sale:
              </Typography>
              {forSale ? <CheckCircleIcon htmlColor="green" style={{ fontSize: 24 }} /> : <CancelIcon htmlColor="red" style={{ fontSize: 24 }} />}
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Link >
  );
}

export default EventsMobileCard;
