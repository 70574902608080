import React from 'react';

import { InfoCard } from '../../../common/components';
import * as Types from '../../../../utils/propTypes';
import { formatForint } from '../../../../utils/formatters';

function OrderPaymentInfoCard({ payment: { cashSubtotal, cardSubtotal } }) {
  const rows = [
    {
      label: 'Paid in cash',
      value: formatForint(cashSubtotal),
    },
    {
      label: 'Paid by card',
      value: formatForint(cardSubtotal),
    },
  ];
  return <InfoCard title="Payment details" rows={rows} />;
}

OrderPaymentInfoCard.propTypes = {
  payment: Types.payment.isRequired,
};

export default OrderPaymentInfoCard;
