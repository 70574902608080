import React from 'react';

import NewElbCardReading from '../NewElbCardReading';
import { useCheckPrivilege } from '../../../lib/hooks';

import Privileges from '../../../../utils/access-control/privileges.json';
import { Grid } from '@material-ui/core';

function ElbCardReadingToolBar({ cardNumber, setCardNumber, mutate, debouncedCardNumber }) {
  const access = useCheckPrivilege(Privileges.READ_ANY_ELB_CARD);

  if (!access) {
    return null;
  }

  return (
    <div>
      <Grid
        style={{ display: 'flex', alignItems: 'center' }}
        container
        direction="row"
        justify="center"
      >
        <NewElbCardReading
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          mutate={mutate}
          debouncedCardNumber={debouncedCardNumber}
        />
      </Grid>
    </div>
  );
}

export default ElbCardReadingToolBar;
