import React from 'react';
import PropTypes from 'prop-types';

import { RemoveItemFromOrder } from '..';

function RemoveTicketFromOrder({ id }) {
  return <RemoveItemFromOrder type="ticket" id={id} />;
}

RemoveTicketFromOrder.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RemoveTicketFromOrder;
