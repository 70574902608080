import React from 'react';
import * as PropTypes from 'prop-types';

function Debug({ children }) {
  return <pre>{JSON.stringify(children, null, 2)}</pre>;
}

Debug.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default Debug;
