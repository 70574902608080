import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const PAGINATION_FRAGMENT = gql`
  fragment PaginationFragment on Pagination {
    cursor
    nextCursor
    hasMore
  }
`;
