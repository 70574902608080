import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

/**
 * Different ELB Card fragment is needed when it's embedded in the Customer,
 * due to the circular structure of ELB Cards (Customer -> ELB Card -> Customer),
 * and the ELB Card's seller isn't included in the embedded data.
 */
export const ELB_CARD_OF_CUSTOMER_FRAGMENT = gql`
  fragment ElbCardOfCustomerFragment on ELBCard {
    id
    cardNumber
    onlineCardNumber
    createdAt
    expirationDate
    status
  }
`;

export const CUSTOMER_FIELDS_FRAGMENT = gql`
  fragment CustomerFieldsFragment on Customer {
    id
    customerSeqId
    firstName
    lastName
    fullName
    email
    profilePicture {
      url
      approved
      timeOfApproval
      originalUrl
      history
    }
    activeElbCard {
      ...ElbCardOfCustomerFragment
    }
    elbCards {
      ...ElbCardOfCustomerFragment
    }
    createdAt
    vid
    contactUrl
    deleted
  }
  ${ELB_CARD_OF_CUSTOMER_FRAGMENT}
`;

export const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    ...CustomerFieldsFragment
    totalSale
  }
  ${CUSTOMER_FIELDS_FRAGMENT}
`;

export const CUSTOMER_WITHOUT_STATS_FRAGMENT = gql`
  fragment CustomerWithoutStatsFragment on Customer {
    ...CustomerFieldsFragment
  }
  ${CUSTOMER_FIELDS_FRAGMENT}
`;

export const GET_CUSTOMERS_WITHOUT_STATS = gql`
  query GetCustomers($options: OptionsInput) {
    customers(withStats: false, options: $options) @connection(key: "customers") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...CustomerWithoutStatsFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${CUSTOMER_WITHOUT_STATS_FRAGMENT}
`;

export const SEARCH_CUSTOMERS = gql`
  query SearchCustomers($search: String, $withStats: Boolean) {
    searchCustomers(search: $search, withStats: $withStats) @connection(key: "customers") {
      entries {
        ...CustomerFragment
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers($options: OptionsInput) {
    customers(withStats: true, options: $options) @connection(key: "customers") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...CustomerFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($id: String!) {
    customer(id: $id) {
      ...CustomerFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($data: CreateCustomerInput!) {
    createCustomer(data: $data) {
      ...CustomerFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
`;
