import React from 'react';
import * as PropTypes from 'prop-types';

import { GET_ORDER } from '../../graphql';

import { Document, WithDocumentQuery } from '../../../common/components';
import { TicketsTable } from '../../../tickets/components';
import { ELBCardsTable } from '../../../elb-cards/components';
import { OrderInfoCard } from '..';
import TerminalInfoCard from '../../../terminals/components/TerminalInfoCard/TerminalInfoCard';
import OrderPaymentInfoCard from '../OrderItemsInfoCard/OrderPaymentInfoCard';
import { OrderDocumentWithProdcut, OrderProductsTable } from '../CreateProductOrder';
import { Grid } from '@material-ui/core';

function Order({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_ORDER}>
      {order =>
        order.products.length > 0 ? (
          <OrderDocumentWithProdcut
            rows={[
              <Grid container spacing={2}>
                <Grid item sm={4} xs={12}>
                  <OrderInfoCard order={order} />
                  {order.terminal && <TerminalInfoCard terminal={order.terminal} />}
                  {order.payment && <OrderPaymentInfoCard payment={order.payment} />}
                </Grid>
                <Grid item sm={8} xs={12}>
                  <OrderProductsTable products={order.products} />
                </Grid>
              </Grid>,
              <ELBCardsTable elbCards={order.elbCards} inline />,
              <TicketsTable tickets={order.tickets} />,
            ]}
          />
        ) : (
          <Document
            infoCard={
              <>
                <OrderInfoCard order={order} />
                {order.terminal && <TerminalInfoCard terminal={order.terminal} />}
                {order.payment && <OrderPaymentInfoCard payment={order.payment} />}
              </>
            }
            inlineTable={<ELBCardsTable elbCards={order.elbCards} inline />}
            table={<TicketsTable tickets={order.tickets} />}
          />
        )
      }
    </WithDocumentQuery>
  );
}

Order.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default Order;
