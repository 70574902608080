import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_AUDIT_LOG } from '../../graphql';

import { Document, WithDocumentQuery } from '../../../common/components';
import { AuditLogInfoCard, AuditLogToolbar } from '..';

function AuditLog({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_AUDIT_LOG}>
      {auditLog => (
        <Document
          toolbar={<AuditLogToolbar auditLog={auditLog} />}
          infoCard={<AuditLogInfoCard auditLog={auditLog} />}
        />
      )}
    </WithDocumentQuery>
  );
}

AuditLog.propTypes = {
  match: Types.idMatch.isRequired,
};

export default AuditLog;
