import { useQuery } from '@apollo/react-hooks';

import FETCH_POLICY from '../../../apollo/fetchPolicy';

export default function useCacheAndNetworkQuery(query, options = {}) {
  // TODO: should be change fetch policy to network only? --> FETCH_POLICY.NetworkOnly
  const result = useQuery(query, { fetchPolicy: FETCH_POLICY.CacheAndNetwork, ...options });
  const { loading, data } = result;
  return {
    ...result,
    loading: loading && !data,
    isLoading: loading,
  };
}
