import React from 'react';
import { isEmpty } from 'lodash';

import * as Types from '../../../../utils/propTypes';
import { billingAccount as billingAccountSchema } from '../../../../utils/validationSchemas';

import { useCreateBillingAccount, useUpdateBillingAccount } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import BillingAccountFields from '../BillingAccountFields';

function MutateBillingAccount({ billingAccount = {}, refetchQueries }) {
  const edit = !isEmpty(billingAccount);
  const { id, name, publicKey, privateKey } = billingAccount;

  const [create] = useCreateBillingAccount(refetchQueries ? { refetchQueries } : undefined);
  const [update] = useUpdateBillingAccount(id);

  const initialValues = {
    name: name || '',
    publicKey: publicKey || '',
    privateKey: privateKey || '',
  };
  const handleSubmit = edit ? async values => update(values) : async values => create(values);
  const formikProps = {
    initialValues,
    validationSchema: billingAccountSchema,
    onSubmit: handleSubmit,
  };

  const dialogProps = {
    title: edit ? 'Edit Billing Account' : 'Add Billing Account',
    submitButtonText: edit ? 'Edit Billing Account' : 'Add Billing Account',
    fields: <BillingAccountFields />,
  };

  const snackbarProps = {
    successText: edit ? 'Billing Account has been edited!' : 'Billing account has been created!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={edit ? 'Edit Billing Account' : 'Add Billing Account'}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

MutateBillingAccount.propTypes = {
  billingAccount: Types.billingAccount,
  refetchQueries: Types.refetchQueries,
};

export default MutateBillingAccount;
