import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { MethodWayEnum } from '../../../../elb-card-reading/components/NewElbCardReading/method-enum';

function CreateEventChecking({
  cardNumber,
  setCardNumber,
  mutate,
  /* debouncedCardNumber, */ eventId,
}) {
  const currentPath = window.location.pathname;
  const methodWay = useState(
    currentPath === '/event-checking' ? MethodWayEnum.EVENT_CHECKING_METHOD : '',
  );
  const userToken = window.localStorage.getItem('token');

  const values = {
    cardNumber: cardNumber,
    eventId: eventId,
    userToken: userToken,
    methodWay: methodWay[0],
  };

  console.log({ values });

  const handleSubmit = e => {
    e.preventDefault();
    mutate(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        required
        InputLabelProps={{
          shrink: true,
        }}
        name="cardNumber"
        label="ELB Card Number"
        type="text"
        value={cardNumber}
        onChange={e => setCardNumber(e.target.value)}
      />

      <TextField margin="none" required name="eventId" type="hidden" value={eventId} />
      <TextField margin="none" required name="userToken" type="hidden" value={userToken} />
      <TextField margin="none" required name="methodWay" type="hidden" value={methodWay} />

      <Button variant="contained" color="primary" type="submit">
        Read Card
      </Button>
    </form>
  );
}

export default CreateEventChecking;
