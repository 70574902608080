import React, { useState } from 'react';
import { Card, CardContent, Grid, Link, Typography, Dialog, DialogContent, Button } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';
import { formatForint, formatDateTime } from '../../../../utils/formatters';
import {
  CheckCircle as ApprovedPictureIcon,
  Cancel as DeclinedPictureIcon,
  Help as MissingApprovalIcon,
} from '@material-ui/icons';
import CustomerInfoCardProfilePicAction from '../CustomerInfoCardProfilePicAction';
import { useMutation } from '@apollo/react-hooks';
import { EVALUATE_PROFILE_PICTURE } from '../../../online-elb-cards/graphql';
import { getOperationName } from '../../../../apollo/helpers';
import { useCheckPrivilege } from '../../../lib/hooks';
import Privileges from '../../../../utils/access-control/privileges.json';
import { GET_CUSTOMER } from '../../graphql';

function CustomerInfoCard({ customer }) {
  const {
    id,
    createdAt,
    email,
    firstName,
    lastName,
    totalSale,
    fullName,
    contactUrl,
    profilePicture,
  } = customer;
  const { approved, /* history, */ timeOfApproval, url } = profilePicture;
  const [showButtons, setShowButtons] = useState(true);
  const [approvedIcon, setApprovedIcon] = useState(false);
  // Profile picture modal logic
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [evaluateProfilePicture] = useMutation(EVALUATE_PROFILE_PICTURE, {
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(GET_CUSTOMER)],
  });
  const canOverrideProfilePictureApproval = useCheckPrivilege(Privileges.OVERRIDE_ACCEPTED_PROFILE_PICTURE);

  const rows = [
    {
      label: 'Created at',
      value: formatDateTime(createdAt),
    },
    {
      label: 'First name',
      value: firstName,
    },
    {
      label: 'Last name',
      value: lastName,
    },
    {
      label: 'E-mail',
      value: email,
    },
    {
      label: 'Total Sale',
      value: formatForint(totalSale),
    },
  ];
  if (contactUrl) {
    rows.push({
      label: 'Contact',
      value: (
        <Link href={contactUrl} target="_blank">
          Go to HubSpot
        </Link>
      ),
    });
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          {fullName}
        </Typography>

        <Grid
          container
          display="flex"
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={1}

        >

          {url && (
            <Grid item xs={3}>
              <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: "flex", justifyContent: 'center' }}>
                  <img
                    src={url}
                    alt=""
                    width={300}
                    onClick={handleOpen}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: 'center', marginTop: '8px' }}>
                  {!timeOfApproval && showButtons ? (
                    <Typography>Click on the picture to approve or decline it</Typography>
                  ) : approved || approvedIcon ? (
                    <Grid container direction='column'>
                      <Grid item justify='center' style={{ display: 'flex' }}>
                        <ApprovedPictureIcon htmlColor="green" />
                      </Grid>
                      {canOverrideProfilePictureApproval && (
                        <Grid item justify='center' style={{ display: 'flex' }}>
                          <Button
                            variant="contained"
                            startIcon={
                              <DeclinedPictureIcon
                                htmlColor="white"
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '8px',
                                }}
                              />
                            }
                            style={{
                              backgroundColor: '#d32f2f',
                              color: 'white',
                              marginTop: '8px'
                            }}
                            onClick={() => {
                              evaluateProfilePicture({
                                variables: {
                                  id: id,
                                  approved: false,
                                  overrideAccess: true,
                                },
                              });
                            }}
                          >
                            Override Decline
                          </Button>
                        </Grid>
                      )}
                    </ Grid>
                  ) : (
                    <DeclinedPictureIcon htmlColor="red" />
                  )}
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={url ? 9 : 12}>
            <Grid
              container
              direction="column"
            >
              <table>
                {rows.map(({ label, value }) => (
                  <tr>
                    <td>
                      <Typography variant="body1">{label}:</Typography>
                    </td>
                    <td>
                      <Typography variant="body1">{value}</Typography>
                    </td>
                  </tr>
                ))}
              </table>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {/* Profile picture modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ fontSize: '20px', textAlign: 'center' }}>
          <img src={url} alt="" width="100%" />
          <Grid
            item
            container
            style={{ justifyContent: 'center', padding: '8px', marginBottom: '4px' }}
          >
            {!timeOfApproval && showButtons ? (
              <MissingApprovalIcon htmlColor="orange" />
            ) : approved || approvedIcon ? (
              <ApprovedPictureIcon htmlColor="green" />
            ) : (
              <DeclinedPictureIcon htmlColor="red" />
            )}
          </Grid>
          {showButtons && (
            <CustomerInfoCardProfilePicAction
              data={customer}
              setShowButtons={setShowButtons}
              setApprovedIcon={setApprovedIcon}
            />
          )}
        </DialogContent>
      </Dialog>
    </Card >
  );
}

CustomerInfoCard.propTypes = {
  customer: Types.customer.isRequired,
};

export default CustomerInfoCard;
