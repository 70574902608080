import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Roles from '../../../../utils/access-control/roles.json';

import { FormikSelect as FSelect } from '../../../lib/components';
import { useGetCurrentUser } from '../../hooks';

function FormikRoleSelect({ name, label = 'Role', ...rest }) {
  const user = useGetCurrentUser().data?.currentUser;
  let options = useMemo(() => Object.values(Roles).map(role => ({ value: role, label: role })), []);
  if (user?.role?.name === 'Office Manager') {
    options = options.filter(option => option.value !== 'Admin');
  }
  return <FSelect name={name} options={options} label={label} {...rest} />;
}

FormikRoleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FormikRoleSelect;
