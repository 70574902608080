import React from 'react';
import { Typography } from '@material-ui/core';

import { version as uiVersion } from '../../../../../package.json';

import { useGetVersion } from '../../../common/hooks';

export default function Version() {
  const { loading, data } = useGetVersion();
  if (loading) return null;
  const { version: {
    apiVersion,
    googleCloudRunRevision
  } } = data;

  if (!googleCloudRunRevision) {
    console.log('MISSING_GOOGLE_CLOUD_RUN_REVISION_NUMBER!');
  }

  return (
    <Typography variant="caption">
      UI {uiVersion}, API {apiVersion} {googleCloudRunRevision && `, Google Cloud Run revision: ${googleCloudRunRevision}`}
    </Typography>
  );
}
