import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';

import { useActionWithSnackbar, useDialog, useEffectOnSuccess } from '../../../lib/hooks';

import ConfirmationDialog from '../ConfirmationDialog';

function ActionButtonWithConfirmation({
  action,
  result,
  title = 'Are you sure?',
  description = '',
  buttonText = 'Action',
  buttonProps = {},
  acceptText = buttonText,
  successMessage = 'Success!',
  errorMessage = 'Something has went wrong.',
  disabled = false,
}) {
  const { loading } = result;

  const [open, handleOpen, handleClose] = useDialog(false);
  const handleAction = useActionWithSnackbar(action, successMessage, errorMessage);

  useEffectOnSuccess(result, handleClose);

  return (
    <>
      <Button onClick={handleOpen} {...buttonProps} disabled={disabled}>
        {buttonText}
      </Button>
      <ConfirmationDialog
        open={open}
        onAccept={handleAction}
        onClose={handleClose}
        title={title}
        description={description}
        acceptText={acceptText}
        loading={loading}
      />
    </>
  );
}

ActionButtonWithConfirmation.propTypes = {
  action: PropTypes.func.isRequired,
  result: Types.result.isRequired,
  buttonText: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object,
  title: PropTypes.node,
  description: PropTypes.node,
  acceptText: PropTypes.node,
  successMessage: PropTypes.node,
  errorMessage: PropTypes.node,
  disabled: PropTypes.bool,
};

export default ActionButtonWithConfirmation;
