import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isBefore, parseISO } from 'date-fns';

import { useGetEvents } from '../../hooks';

import { FormikAutocomplete as FAutocomplete } from '../../../lib/components';

function FormikEventAutocomplete({ name, InputProps = {}, ...rest }) {
  const { loading, data } = useGetEvents({ variables: { options: {} } });
  const events = data ? data.events.entries : [];
  const options = useMemo(
    () =>
      events.filter(
        ({ forSale, until, ticketTypes }) =>
          forSale &&
          !isEmpty(ticketTypes) &&
          isBefore(new Date(), parseISO(until)) &&
          ticketTypes.some(({ forSale: ttOnSale }) => ttOnSale),
      ),
    [events],
  );
  const getOptionLabel = useCallback(({ name: eventName }) => eventName, []);

  return (
    <FAutocomplete
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      loading={loading}
      InputProps={{
        label: 'Event',
        ...InputProps,
      }}
      {...rest}
    />
  );
}

FormikEventAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  /* eslint-disable-next-line react/forbid-prop-types */
  InputProps: PropTypes.object,
};

export default FormikEventAutocomplete;
