import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import {
  TableFooter as MuiTableFooter,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TablePagination as MuiTablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: [[0, 24, 0, 24]],
  },
}));

function TableFooter({
  serverSide,
  hasMore,
  count,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  dataLength,
  changeRowsPerPage,
  changePage,
  loading,
}) {
  const classes = useStyles();

  const handleRowChange = useCallback(
    event => {
      changeRowsPerPage(event.target.value);
    },
    [changeRowsPerPage],
  );
  const handlePageChange = useCallback(
    (_, newPage) => {
      /**
       * Note: displays prop types warning `Warning: Failed prop type: Material-UI: the page prop of a TablePagination is out of range (0 to 0, but page is 2)` if `count` prop is `-1`, because `MuiTablePagination` component doesn't know how many pages are there.
       */
      changePage(newPage);
    },
    [changePage],
  );

  const maxPossibleNumOfEntries = rowsPerPage * (page + 1);
  const actualNumOfEntries = rowsPerPage * page + dataLength;
  const numOfEntries = Math.min(maxPossibleNumOfEntries, actualNumOfEntries);
  const labelDisplayedRows = useCallback(
    ({ from, to }) => {
      const prefix = `${from}-${to === -1 ? count : to} of `;
      let suffix = `${count !== -1 ? count : `more than ${to}`}`;
      if (!hasMore) {
        suffix = numOfEntries;
      }
      return prefix + suffix;
    },
    [count, hasMore, numOfEntries],
  );

  let backIconButtonProps = null;
  let nextIconButtonProps = null;
  if (serverSide) {
    backIconButtonProps = loading ? { disabled: true } : null;
    nextIconButtonProps = loading ? { disabled: true } : { disabled: !hasMore };
  }

  return (
    <MuiTableFooter>
      <MuiTableRow>
        <MuiTableCell className={classes.tableCell} colSpan={1000}>
          <MuiTablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowChange}
            backIconButtonProps={backIconButtonProps}
            nextIconButtonProps={nextIconButtonProps}
            labelDisplayedRows={serverSide ? labelDisplayedRows : undefined}
          />
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableFooter>
  );
}

TableFooter.propTypes = {
  serverSide: PropTypes.bool,
  hasMore: PropTypes.bool,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  dataLength: PropTypes.number.isRequired,
  changeRowsPerPage: PropTypes.func,
  changePage: PropTypes.func,
  loading: PropTypes.bool,
};

export default TableFooter;
