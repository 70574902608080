import React from 'react';
import PropTypes from 'prop-types';

import Privileges from '../../../../utils/access-control/privileges.json';
import * as Types from '../../../../utils/propTypes';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import {
  InactivateUserWithZeroBalance,
  InactivateUserWithBalance,
  SendActivationEmail,
  UpdateUser,
} from '..';
import { ReportBalance } from '../../../audit/components';

function UserToolBar({ user, auditable = false }) {
  const userUpdateAccess = useCheckPrivilege(Privileges.UPDATE_ANY_USER);

  if (!userUpdateAccess && !auditable) return null;

  const {
    fullName,
    isActive,
    email,
    id,
    account: { balance },
  } = user;

  const InactivateButton =
    balance === 0 ? (
      <InactivateUserWithZeroBalance id={id} fullName={fullName} />
    ) : (
      <InactivateUserWithBalance />
    );
  let ToggleActivationButton = isActive ? (
    InactivateButton
  ) : (
    <SendActivationEmail email={email} />
  );

  const ReportBalanceButton = auditable ? (
    <ReportBalance id={id} own={false} />
  ) : (
    null
  );

  const UpdateUserButton = userUpdateAccess ? (
    <UpdateUser user={user} />
  ) : (
    null
  );

  if (!userUpdateAccess) {
    ToggleActivationButton = null;
  }

  return (
    <Toolbar>
      {ReportBalanceButton}
      {ToggleActivationButton}
      {UpdateUserButton}
    </Toolbar>
  );
}

UserToolBar.propTypes = {
  user: Types.user.isRequired,
  auditable: PropTypes.bool,
};

export default UserToolBar;
