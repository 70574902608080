import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import * as PropTypes from 'prop-types';

import { InfoCardRow } from '..';

function InfoCard({ title, rows }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          {title}
        </Typography>

        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
          {rows.map(({ label, value }) => (
            <Grid item key={label}>
              <InfoCardRow label={label} value={value} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.node,
    }).isRequired,
  ),
};

export default InfoCard;
