import axios from 'axios';

export const PRODUCTS_API_BASE_URL = process.env.REACT_APP_PRODUCTS_API_BASE_URL;

export const PRODUCTS_API_ENDPOINTS = {
  PRODUCTS_CONTROLLER_BASE_URL: 'products',
  UPLOAD_PRODUCT_IMAGE_ENDPOINT: `products/image`,
};

export const PRODUCTS_API_AXIOS_INSTANCE = axios.create({
  baseURL: PRODUCTS_API_BASE_URL,
});
