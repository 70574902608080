import { useLazyQuery } from '@apollo/react-hooks';

import { GET_TICKET_DOWNLOAD_TOKEN } from '../graphql';

export default function useDownloadTicket(id) {
  return useLazyQuery(GET_TICKET_DOWNLOAD_TOKEN, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });
}
