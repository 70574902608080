import React from 'react';
import * as PropTypes from 'prop-types';

import { useAcceptAuditLog } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function AcceptAuditLog({ id }) {
  const [acceptAuditLog, result] = useAcceptAuditLog(id);
  return (
    <ActionButtonWithConfirmation
      action={acceptAuditLog}
      result={result}
      title="Are you sure you want to accept this audit log?"
      successMessage="Audit log has been accepted!"
      buttonText="Accept"
      buttonProps={{
        variant: 'contained',
        color: 'primary',
      }}
    />
  );
}

AcceptAuditLog.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AcceptAuditLog;
