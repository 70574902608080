import React from 'react';
import PropTypes from 'prop-types';

import { Content, Tiles } from '..';

function Main({ toolbar, primary, secondary, tertiary, quaternary }) {
  return (
    <Content toolbar={toolbar}>
      <Tiles primary={primary} secondary={secondary} tertiary={tertiary} quaternary={quaternary} />
    </Content>
  );
}

Main.propTypes = {
  toolbar: PropTypes.node,
  primary: PropTypes.node,
  secondary: PropTypes.node,
  tertiary: PropTypes.node,
  quaternary: PropTypes.node,
};

export default Main;
