import React, { useState } from 'react';

import { useMutation } from '@apollo/react-hooks';
import {
  EVALUATE_PROFILE_PICTURE,
  GET_CUSTOMER_PROFILE_PICTURES,
} from '../../../online-elb-cards/graphql';
import { HowToReg as ApprovePictureIcon, Block as DeclinePictureIcon } from '@material-ui/icons';
import { getOperationName } from '../../../../apollo/helpers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

function ProfilePicAction({ data, setShowButtons, setApprovedIcon }) {
  const [evaluateProfilePicture] = useMutation(EVALUATE_PROFILE_PICTURE, {
    awaitRefetchQueries: true,
    onCompleted: data => {
      setShowButtons(false);
      console.log('mutation data ', { data });
    },
    refetchQueries: [getOperationName(GET_CUSTOMER_PROFILE_PICTURES)],
  });
  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };
  const handleOpenDecline = () => {
    setOpenDecline(true);
  };
  const handleCloseDecline = () => {
    setOpenDecline(false);
  };
  if (data?.profilePicture?.approved) {
    return null;
  }
  return (
    <>
      <Grid item container style={{ justifyContent: 'center', marginBottom: '8px' }}>
        <Grid item>
          <Button
            variant="contained"
            startIcon={
              <ApprovePictureIcon
                htmlColor="white"
                style={{
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
              />
            }
            onClick={handleOpenApprove}
            style={{
              backgroundColor: '#1b5e20',
              color: 'white',
            }}
          >
            Approve
          </Button>
          <Dialog
            open={openApprove}
            onClose={handleCloseApprove}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">APPROVE PICTURE</DialogTitle>
            <DialogContent>
              <p>
                Are you sure you want to <strong>APPROVE</strong> <i>{data.customerFullName}</i>'s (
                {data.cardNumber}) profile picture:{' '}
              </p>
              <br />
              {<img src={data?.profilePicture?.url} alt="approve_profile_pic" />}
            </DialogContent>
            <DialogActions>
              <Grid container direction="row" justify="space-between" width="100%">
                <Button onClick={() => handleCloseApprove()} variant="outlined">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#1b5e20',
                    color: 'white',
                  }}
                  startIcon={
                    <ApprovePictureIcon
                      htmlColor="white"
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    />
                  }
                  onClick={() => {
                    evaluateProfilePicture({
                      variables: {
                        id: data.customerId,
                        approved: true,
                      },
                    });
                    setApprovedIcon(true);
                    handleCloseApprove();
                  }}
                >
                  Approve
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Grid item container style={{ justifyContent: 'center' }}>
        <Grid item>
          <Button
            variant="contained"
            startIcon={
              <DeclinePictureIcon
                htmlColor="white"
                style={{
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
              />
            }
            onClick={handleOpenDecline}
            style={{
              backgroundColor: '#d32f2f',
              color: 'white',
            }}
          >
            Decline
          </Button>
          <Dialog
            open={openDecline}
            onClose={handleCloseDecline}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">DECLINE PICTURE</DialogTitle>
            <DialogContent>
              <p>
                Are you sure you want to <strong>DECLINE</strong> <i>{data.customerFullName}</i>'s (
                {data.cardNumber}) profile picture:{' '}
              </p>
              <br />
              {<img src={data?.profilePicture?.url} alt="decline_profile_pic" />}
            </DialogContent>
            <DialogActions>
              <Grid container direction="row" justify="space-between" width="100%">
                <Button onClick={() => handleCloseDecline()} variant="outlined">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  startIcon={
                    <DeclinePictureIcon
                      htmlColor="white"
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    />
                  }
                  style={{
                    backgroundColor: '#d32f2f',
                    color: 'white',
                  }}
                  onClick={() => {
                    evaluateProfilePicture({
                      variables: {
                        id: data.customerId,
                        approved: false,
                      },
                    });
                    handleCloseDecline();
                  }}
                >
                  Decline
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfilePicAction;
