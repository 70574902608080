import { useState } from 'react';

export default function useServerSideFilters(defaultFilter) {
  const [filters, setFilters] = useState(defaultFilter || []);

  function removeFilter(removeFilter) {
    setFilters(prevFilters => {
      if (removeFilter.field === "isInactive" && prevFilters.findIndex(filter => filter.field === "isActive") !== -1) {
        return prevFilters.filter(f => f.field !== "isActive");
      }
      return prevFilters.filter(f => f.field !== removeFilter.field);
    });
  }

  function addFilter(newFilter) {
    setFilters(prevFilters => [...prevFilters.filter(f => f.field !== newFilter.field), newFilter]);
  }
  function resetFilters() {
    setFilters([]);
  }

  return [filters, addFilter, resetFilters, removeFilter];
}
