import React from 'react';

import { Toolbar } from '../../../common/components';
import StartSaleForTicketType from '../StartSaleForTicketType';
import StopSaleForTicketType from '../StopSaleForTicketType';
import UpdateTicketType from '../UpdateTicketType';
import * as Types from '../../../../utils/propTypes';

function TicketTypeToolbar({ ticketType }) {
  return (
    <Toolbar>
      {ticketType.forSale ? (
        <StopSaleForTicketType id={ticketType.id} name={ticketType.name} />
      ) : (
        <StartSaleForTicketType id={ticketType.id} />
      )}
      <UpdateTicketType ticketType={ticketType} />
    </Toolbar>
  );
}

TicketTypeToolbar.propTypes = {
  ticketType: Types.ticketType.isRequired,
};

export default TicketTypeToolbar;
