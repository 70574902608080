import React from 'react';
import * as PropTypes from 'prop-types';

import { Content } from '..';

function Collection({ toolbar, table }) {
  return <Content toolbar={toolbar}>{table}</Content>;
}

Collection.propTypes = {
  toolbar: PropTypes.node,
  table: PropTypes.node.isRequired,
};

export default Collection;
