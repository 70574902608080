import React from 'react';
import { Link, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';
import routes from '../../../../utils/routes';
import { formatForint, formatDateTime } from '../../../../utils/formatters';

import { useCheckPrivileges, useSnackbar } from '../../../lib/hooks';
import { useDeleteEventForm } from '../../../events/hooks';

import { InfoCard } from '../../../common/components';

function EventInfoCard({
  event: {
    id,
    forSale,
    from,
    until,
    location,
    totalSales,
    name,
    ticketStats,
    ticketLimit,
    createdAt,
    createdBy,
    billingAccount,
    form,
    isExternal,
  },
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [billingAccountAccess, formSubmissionAccess, deleteFormAccess] = useCheckPrivileges(
    Privileges.READ_ANY_BILLING_ACCOUNT,
    Privileges.READ_ANY_FORM_SUBMISSION,
    Privileges.DELETE_EVENT_FORM,
  );

  const [deleteForm] = useDeleteEventForm({ id: id });
  const deleteEventForm = async () => {
    await deleteForm({ id: id });
    enqueueSnackbar('Form deleted!', 'success');
  };

  const rows = [
    {
      label: 'For Sale',
      value: forSale ? 'Yes' : 'No',
    },
    {
      label: 'From',
      value: formatDateTime(from),
    },
    {
      label: 'Until',
      value: formatDateTime(until),
    },
    {
      label: 'Location',
      value: location,
    },

    {
      label: 'Active Tickets',
      value: ticketStats.active,
    },
    {
      label: 'Reserved Tickets',
      value: ticketStats.reserved,
    },
    {
      label: 'Ticket Types Limit',
      value: ticketStats.limit,
    },
    {
      label: 'All Ticket Limit',
      value: ticketLimit || '∞',
    },
    {
      label: 'Sold Tickets',
      value: ticketStats.sold,
    },
    {
      label: 'Total Sales',
      value: formatForint(totalSales),
    },
    {
      label: 'External',
      value: isExternal ? 'Yes' : 'No',
    },
    {
      label: 'Created at',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Created by',
      value: routes.user.createLink(createdBy),
    },
  ];
  if (billingAccountAccess) {
    rows.push({
      label: 'Billing with',
      value: billingAccount
        ? routes.billingAccount.createLink(billingAccount.id, billingAccount.name)
        : 'Manually',
    });
  }
  if (formSubmissionAccess) {
    rows.push({
      label: 'Form',
      value: form ? (
        <List style={{ paddingTop: 0 }}>
          <ListItem style={{ padding: 0 }}>
            <Link href={form.submissionsUrl} target="_blank">
              Go to HubSpot
            </Link>
          </ListItem>
          {deleteFormAccess && (
            <ListItem style={{ padding: 0, marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={() => deleteEventForm(form)}>
                Delete form
              </Button>
            </ListItem>
          )}
        </List>
      ) : (
        'None'
      ),
    });
  }
  return <InfoCard title={name} rows={rows} />;
}

EventInfoCard.propTypes = {
  event: Types.event.isRequired,
};

export default EventInfoCard;
