import React from 'react';

import { Collection } from '../../../common/components';
import { ELBCardsTable } from '..';

function ELBCards() {
  return <Collection table={<ELBCardsTable />} />;
}

export default ELBCards;
