import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { AcceptAuditLog, RejectAuditLog } from '..';

function AuditLogToolbar({ auditLog: { id, status } }) {
  const access = useCheckPrivilege(Privileges.EVALUATE_AUDIT_LOG);

  if (!access || status !== 'Pending') return null;
  return (
    <Toolbar>
      <RejectAuditLog id={id} />
      <AcceptAuditLog id={id} />
    </Toolbar>
  );
}

AuditLogToolbar.propTypes = {
  auditLog: Types.auditLog.isRequired,
};

export default AuditLogToolbar;
