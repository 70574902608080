import React from 'react';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';

import { STOP_SALE_FOR_EVENT } from '../../graphql';

import { ActionButtonWithConfirmation } from '../../../common/components';

function StopSaleForEvent({ id, name }) {
  const [stopSaleForEvent, result] = useMutation(STOP_SALE_FOR_EVENT, {
    variables: { id },
  });
  return (
    <ActionButtonWithConfirmation
      action={stopSaleForEvent}
      result={result}
      title={`Are you sure you want to stop sales for ${name}?`}
      successMessage="Sales have been stopped for the event!"
      buttonText="Stop Sales"
    />
  );
}

StopSaleForEvent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default StopSaleForEvent;
