import React from 'react';
import PropTypes from 'prop-types';

import { useRememberMe } from '../../hooks';

import { FillerSpinner } from '../../../lib/components';

function RememberMe({ children }) {
  const { loading } = useRememberMe();
  if (loading) return <FillerSpinner />;
  return <>{children}</>;
}

RememberMe.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RememberMe;
