import React from 'react';
import * as PropTypes from 'prop-types';

import { useSendActivationEmail } from '../../hooks';

import { ActionButton } from '../../../common/components';

function SendActivationEmail({ email }) {
  const [sendActivationEmail, result] = useSendActivationEmail(email);
  return (
    <ActionButton
      action={sendActivationEmail}
      result={result}
      successMessage="Activation email has been sent!"
    >
      Send Activation Email
    </ActionButton>
  );
}

SendActivationEmail.propTypes = {
  email: PropTypes.string.isRequired,
};

export default SendActivationEmail;
