import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { noop } from 'lodash';

import chartjs from './utils/chartjs';
import setupApolloClient from './apollo/setupApolloClient';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/css/index.css';

import Routes from './Routes';
import { SnackbarProvider } from './modules/lib/components';
import { RememberMe } from './modules/auth/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Disable console in production
if (process.env.REACT_APP_ENV_CONTEXT === 'production') {
  /* eslint-disable no-console */
  console.log = noop;
  console.warn = noop;
  console.error = noop;
  /* eslint-enable */
}

const client = setupApolloClient();

const browserHistory = createBrowserHistory();

const queryClient = new QueryClient();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

function App() {
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <RememberMe>
                <Router history={browserHistory}>
                  <Routes />
                </Router>
              </RememberMe>
            </QueryClientProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
