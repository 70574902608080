import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useGetProducts } from '../../hooks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FillerSpinner } from '../../../lib/components';

function EventProducts({ eventId }) {
  const values = {
    eventId,
  };
  const { isError, error, isLoading, isSuccess, data } = useGetProducts(values);

  console.log({ eventId });
  return (
    <>
      {isLoading && <FillerSpinner />}
      {isError && (
        <>
          <strong>Failed loading products: {error?.message}</strong>
        </>
      )}
      {isSuccess &&
        (data?.length > 0 ? (
          <TableContainer>
            <Table style={{ backgroundColor: 'white' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="center">Image</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(eventProduct => {
                  const { id, name, description, price, imageUrl } = eventProduct;

                  return (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell align="right">{description}</TableCell>
                      <TableCell align="right">{price}</TableCell>
                      {imageUrl && (
                        <TableCell
                          style={{ maxWidth: '400px', borderBottom: '1px solid #eeeeee' }}
                          align="center"
                        >
                          <img src={imageUrl} alt={name} style={{ maxWidth: '60%' }} />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Alert severity="warn">
            <AlertTitle>Products</AlertTitle>
            <Typography>There is no product to display.</Typography>
          </Alert>
        ))}
    </>
  );
}

export default EventProducts;
