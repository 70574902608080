import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, Checkbox } from '@material-ui/core';
import { dropRightWhile } from 'lodash';

import * as Types from '../../../../../../utils/propTypes';

function TableBooleanFilter({ column, filters, index, onChange, label }) {
  const handleChange = useCallback(
    filterIndex => (_, value) => {
      const newFilters = [...filters];

      newFilters[filterIndex] = value;

      const sanitizedFilters = dropRightWhile(newFilters, filter => filter === undefined);
      onChange(sanitizedFilters, index, column);
    },
    [column, filters, index, onChange],
  );

  return (
    <div>
      <FormLabel>{label}</FormLabel>
      <Checkbox value={filters[0] || ''} onChange={handleChange(0)} />
    </div>
  );
}

TableBooleanFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(PropTypes.bool).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default TableBooleanFilter;
