import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { useCreateCustomer } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';

function CreateCustomer({ refetchQueries }) {
  const [, formikProps, dialogProps, snackbarProps] = useCreateCustomer(
    refetchQueries ? { refetchQueries } : undefined,
  );
  return (
    <ActionButtonWithDialogForm
      actionButtonText="Add Customer"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

CreateCustomer.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateCustomer;
