import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { ActivateTicket, InactivateTicket } from '..';

function TicketToolbar({ ticket: { id, status, ticketNumber } }) {
  const access = useCheckPrivilege(Privileges.UPDATE_TICKET);
  if (!access) return null;

  return (
    <Toolbar>
      {status === 'Active' ? <InactivateTicket id={id} number={ticketNumber} /> : ''}
      {status === 'Inactive' ? <ActivateTicket id={id} /> : ''}
    </Toolbar>
  );
}

TicketToolbar.propTypes = {
  ticket: Types.ticket.isRequired,
};

export default TicketToolbar;
