import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
  FormikSelect as FSelect,
} from '../../../lib/components';

export const AccountType = {
  Cash: 'Cash',
  Card: 'Card',
  Online: 'Online',
};

function AccountTypeSelectField() {
  const items = [
    { value: AccountType.Cash, label: 'Cash' },
    { value: AccountType.Card, label: 'Card' },
  ];
  return (
    <>
      <FSelect
        name="type"
        required
        options={items}
        fullWidth
        label="Account type"
        variant="outlined"
        margin="normal"
      />
    </>
  );
}

function AccountFormFields() {
  return (
    <>
      <FTextField
        fullWidth
        autoFocus
        margin="none"
        variant="outlined"
        required
        name="name"
        label="Account name"
        type="text"
      />
      <AccountTypeSelectField />

      <FFormError />
    </>
  );
}

export default AccountFormFields;
