import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {},
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
}));

function Toolbar({ children, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const buttons = Array.isArray(children) ? (
    React.Children.map(children, child => (
      <Grid item xs={downSm}>
        {child}
      </Grid>
    ))
  ) : (
    <Grid item xs={downSm}>
      {children}
    </Grid>
  );

  return (
    <div {...rest} className={clsx(classes.toolbar)}>
      <Grid
        className={classes.row}
        container
        direction="row"
        justify="flex-end"
        alignItems="baseline"
        spacing={1}
      >
        {buttons}
      </Grid>
    </div>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Toolbar;
