import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PAYMENTS_API_BASE_URL } from '../payments.constants';

export const useGetPaymentStatuses = () => {
    return useQuery({
        queryKey: ['payment-statuses'],
        queryFn: async () => {
            const response = await axios.get(
                `${PAYMENTS_API_BASE_URL}/payment/statuses`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
