import React from 'react';
import * as PropTypes from 'prop-types';

import { useStartSaleForElbCardType } from '../../hooks';

import { ActionButton } from '../../../common/components';

function StartSaleForELBCardType({ id }) {
  const [startSale, result] = useStartSaleForElbCardType(id);
  return (
    <ActionButton
      action={startSale}
      result={result}
      successMessage="Sale for ELB Card type has been started!"
    >
      Start Sale
    </ActionButton>
  );
}

StartSaleForELBCardType.propTypes = {
  id: PropTypes.string.isRequired,
};

export default StartSaleForELBCardType;
