import React, { useMemo, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, Divider, colors } from '@material-ui/core';
import uuid from 'uuid/v1';

import { routeItems } from '../../../../utils/routes';
import { checkPrivilege } from '../../../../utils/access-control/utils';
import { insertBy } from '../../../../utils/helpers';

import { useGetPrivileges } from '../../../lib/hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

function addDividersAfter(sidebarItems, keys) {
  let sidebarItemsWithDividers = sidebarItems;
  keys.forEach(key => {
    sidebarItemsWithDividers = insertBy(
      sidebarItemsWithDividers,
      'divider',
      item => item.key === key,
    );
  });
  return sidebarItemsWithDividers;
}

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

function SidebarNav({ className, ...rest }) {
  const classes = useStyles();

  const userPrivileges = useGetPrivileges();
  const sbcs = useMemo(() => {
    const sidebarItems = routeItems.filter(route => route.isInSidebar);
    const sidebarItemsForUser = sidebarItems.filter(({ privilege }) =>
      checkPrivilege(userPrivileges, privilege),
    );
    const sidebarItemsForUserWithDividers = addDividersAfter(sidebarItemsForUser, [
      'orders',
      'audit-logs',
      'tickets',
    ]);
    const sidebarComponents = sidebarItemsForUserWithDividers.map(item =>
      item === 'divider' ? (
        <Divider key={uuid()} />
      ) : (
        <ListItem key={item.key} className={classes.item} disableGutters>
          <Button
            component={CustomRouterLink}
            to={item.path}
            className={classes.button}
            activeClassName={classes.active}
          >
            <div className={classes.icon}>
              <item.icon />
            </div>
            {item.label}
          </Button>
        </ListItem>
      ),
    );
    return sidebarComponents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPrivileges]);

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {sbcs.map(component => component)}
    </List>
  );
}

SidebarNav.propTypes = {
  className: PropTypes.string,
};

export default SidebarNav;
