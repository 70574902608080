import React from 'react';

import Privileges from '../../../../utils/access-control/privileges.json';
import * as Types from '../../../../utils/propTypes';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { ActivateBillingAccount, InactivateBillingAccount, UpdateBillingAccount } from '..';

function BillingAccountToolbar({ billingAccount }) {
  const access = useCheckPrivilege(Privileges.UPDATE_BILLING_ACCOUNT);
  if (!access) return null;
  const { id, active, name } = billingAccount;
  return (
    <Toolbar>
      {active ? (
        <InactivateBillingAccount id={id} name={name} />
      ) : (
        <ActivateBillingAccount id={id} />
      )}
      <UpdateBillingAccount billingAccount={billingAccount} />
    </Toolbar>
  );
}

BillingAccountToolbar.propTypes = {
  billingAccount: Types.billingAccount.isRequired,
};

export default BillingAccountToolbar;
