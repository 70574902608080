import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { passwordReset as passwordResetSchema } from '../../../../utils/validationSchemas';

import { useResetPassword } from '../../hooks';

import {
  FormikAsync as Formik,
  FormikTextField as FTextField,
  SubmitButton,
  FormikFormWithError as FFormWithError,
} from '../../../lib/components';

const initialValues = {
  password: '',
  passwordAgain: '',
};

function ResetPasswordForm({ resetPasswordToken }) {
  const [resetPassword] = useResetPassword(resetPasswordToken);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={passwordResetSchema}
      onSubmit={resetPassword}
    >
      <FFormWithError>
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item>
            <FTextField
              name="password"
              type="password"
              required
              label="Password"
              variant="outlined"
              fullWidth
              margin="none"
            />
          </Grid>

          <Grid item>
            <FTextField
              name="passwordAgain"
              type="password"
              required
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="none"
            />
          </Grid>

          <Grid item>
            <SubmitButton fullWidth variant="contained" color="primary" type="submit">
              Reset password ang log in
            </SubmitButton>
          </Grid>
        </Grid>
      </FFormWithError>
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
