import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';
import { formatForint, formatDateTime } from '../../../../utils/formatters';

import { useIsMe } from '../../../auth/hooks';
import { useCheckPrivilege } from '../../../lib/hooks';

import { InfoCard } from '../../../common/components';

function UserInfoCard({ user: { id, role, email, fullName, account, createdAt, isActive } }) {
  const isMe = useIsMe(id);
  const access = useCheckPrivilege(Privileges.READ_ANY_USER_SALES_DATA);

  const rows = [
    {
      label: 'Role',
      value: role.name,
    },
    {
      label: 'Email',
      value: email,
    },
    {
      label: 'Registration Date',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Status',
      value: isActive ? 'Active' : 'Inactive',
    },
  ];
  if (isMe || access) {
    rows.push(
      ...[
        {
          label: 'Balance',
          value: formatForint(account.balance),
        },
        {
          label: 'Total Sale',
          value: formatForint(account.sales.total),
        },
        {
          label: 'Sales of Today',
          value: formatForint(account.sales.today),
        },
        {
          label: 'Total Error',
          value: 'TBD',
        },
      ],
    );
  }
  return <InfoCard title={fullName || email} rows={rows} />;
}

UserInfoCard.propTypes = {
  user: Types.user.isRequired,
};

export default UserInfoCard;
