import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import {
  FormikTextField,
  FormikDatePicker,
  FormikFormError,
  FormikSwitch,
} from '../../../lib/components';

function TicketTypeFormFields({
  create = false,
  inEventLimit,
  setInEventLimit,
  ticketTypeLimit,
  setTicketTypeLimit,
}) {
  const handleChange = event => setInEventLimit(event.target.checked);
  const handleLimitChange = event => setTicketTypeLimit(event.target.value);
  return (
    <>
      <FormikTextField
        name="name"
        type="text"
        required
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FormikDatePicker
        name="expirationDate"
        disablePast
        clearable
        label="Expiration Date"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FormControlLabel
        control={
          <Switch checked={inEventLimit} onChange={handleChange} name="includeInEventLimit" />
        }
        label="Include ticket type limit in event limit?"
      />

      {inEventLimit === true && (
        <FormikTextField
          name="limit"
          type="number"
          label="Limit"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ticketTypeLimit}
          onChange={handleLimitChange}
        />
      )}

      <FormikTextField
        name="normalPrice"
        type="number"
        label="Price"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FormikTextField
        name="elbCardPrice"
        type="number"
        label="Price with ELB Card"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      {create ? <FormikSwitch name="startSales" label="Start Sales" /> : null}

      <FormikFormError />
    </>
  );
}

export default TicketTypeFormFields;
