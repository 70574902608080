import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';

import { FormikSwitch as FSwitch } from '../../../lib/components';
import { formatDate } from '../../../../utils/formatters';

function CustomerAutocompleteInfo({ customer = undefined, toggleElbCard = false }) {
  const elbCard = useMemo(() => (customer ? customer.activeElbCard : undefined), [customer]);
  console.log('customerautocompleteinfo elbcard:', elbCard);
  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
      <Grid container item>
        <Grid item xs={4}>
          <Typography>Name:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{customer && customer.fullName}</Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={4}>
          <Typography>Email:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{customer && customer.email}</Typography>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid container item justify="flex-start" alignItems="center" xs={4}>
          <Typography>ELB Card:</Typography>
        </Grid>
        <Grid container item justify="flex-start" alignItems="center" xs={4}>
          <Typography>
            {(() => {
              if (customer) {
                if (elbCard) {
                  return elbCard.cardNumber;
                }
                return 'None';
              }
              return '';
            })()}
          </Typography>
        </Grid>
        <Grid container item justify="flex-start" alignItems="center" xs={4}>
          {toggleElbCard && elbCard && <FSwitch name="withELBCard" label="With ELB Card?" />}
        </Grid>
      </Grid>
      {/* //TODO: correctly set expiration date for only Add ELB Card method/button */}
      {customer && elbCard && (
        <Grid container item>
          <Grid container item justify="flex-start" alignItems="center" xs={4}>
            <Typography>Expiration Date:</Typography>
          </Grid>
          <Grid container item justify="flex-start" alignItems="center" xs={4}>
            <Typography>
              {(() => {
                if (customer) {
                  if (elbCard) {
                    return formatDate(elbCard.expirationDate);
                  }
                  return 'None';
                }
                return '';
              })()}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

CustomerAutocompleteInfo.propTypes = {
  customer: Types.customer,
  toggleElbCard: PropTypes.bool,
};

export default CustomerAutocompleteInfo;
