import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { activation as activationSchema } from '../../../../utils/validationSchemas';

import { useActivateUser } from '../../hooks';

import {
  FormikTextField as FTextField,
  SubmitButton,
  FormikAsync as Formik,
  FormikFormWithError as FFormWithError,
} from '../../../lib/components';

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  passwordAgain: '',
};

function ActivationForm({ activationToken }) {
  const [activateUser] = useActivateUser(activationToken);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={activationSchema}
      onSubmit={activateUser}
    >
      <FFormWithError>
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <FTextField
                fullWidth
                margin="none"
                variant="outlined"
                required
                name="firstName"
                label="First Name"
                type="text"
              />
            </Grid>

            <Grid item xs={6}>
              <FTextField
                fullWidth
                margin="none"
                variant="outlined"
                required
                name="lastName"
                label="Last Name"
                type="text"
              />
            </Grid>
          </Grid>

          <Grid item>
            <FTextField
              fullWidth
              margin="none"
              variant="outlined"
              required
              name="password"
              label="Password"
              type="password"
            />
          </Grid>

          <Grid item>
            <FTextField
              fullWidth
              margin="none"
              variant="outlined"
              required
              name="passwordAgain"
              label="Confirm Password"
              type="password"
            />
          </Grid>

          <Grid item>
            <SubmitButton fullWidth variant="contained" color="primary" type="submit">
              Activate and log in
            </SubmitButton>
          </Grid>
        </Grid>
      </FFormWithError>
    </Formik>
  );
}

ActivationForm.propTypes = {
  activationToken: PropTypes.string.isRequired,
};

export default ActivationForm;
