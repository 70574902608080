import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { GET_BILLING_ACCOUNT } from '../../graphql';
import { Document, WithDocumentQuery } from '../../../common/components';
import { EventsTable } from '../../../events/components';
import { BillingAccountToolbar, BillingAccountInfoCard } from '..';

function BillingAccount({
  match: {
    params: { id },
  },
}) {
  return (
    <WithDocumentQuery id={id} query={GET_BILLING_ACCOUNT}>
      {(billingAccount, events) => (
        <Document
          toolbar={<BillingAccountToolbar billingAccount={billingAccount} />}
          infoCard={<BillingAccountInfoCard billingAccount={billingAccount} />}
          table={<EventsTable events={events.entries} />}
        />
      )}
    </WithDocumentQuery>
  );
}

BillingAccount.propTypes = {
  match: Types.idMatch.isRequired,
};

export default BillingAccount;
