import React from 'react';

import Privileges from '../../../../utils/access-control/privileges.json';
import * as Types from '../../../../utils/propTypes';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { ReportBalance } from '../../../audit/components';
import { UpdateProfile } from '..';

function ProfileToolbar({ user }) {
  const access = useCheckPrivilege(Privileges.UPDATE_OWN_USER);
  if (!access) return null;

  const { firstName, lastName, id } = user;
  return (
    <Toolbar>
      <ReportBalance id={id} />
      <UpdateProfile id={id} firstName={firstName} lastName={lastName} />
    </Toolbar>
  );
}

ProfileToolbar.propTypes = {
  user: Types.user.isRequired,
};

export default ProfileToolbar;
