import React from 'react';
import * as PropTypes from 'prop-types';

import { useInactivateElbCard } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function InactivateELBCard({ id, fullName }) {
  const [inactivate, result] = useInactivateElbCard(id);
  return (
    <ActionButtonWithConfirmation
      action={inactivate}
      result={result}
      title={`Are you sure you want to inactivate ${fullName}'s ELB Card?`}
      successMessage="ELB Card has been inactivated!"
      buttonText="Inactivate"
    />
  );
}

InactivateELBCard.propTypes = {
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default InactivateELBCard;
