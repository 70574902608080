import React from 'react';

import { getOperationName } from '../../../../apollo/helpers';
import { GET_ELB_CARD_TYPES } from '../../graphql';

import { Collection } from '../../../common/components';
import { ELBCardTypesToolbar, ELBCardTypesTable } from '..';

function Customers() {
  return (
    <Collection
      toolbar={<ELBCardTypesToolbar refetchQueries={[getOperationName(GET_ELB_CARD_TYPES)]} />}
      table={<ELBCardTypesTable />}
    />
  );
}

export default Customers;
