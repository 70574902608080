import React from 'react';
import * as PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Typography, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useOptions } from '../../hooks';
import * as TableTypes from '../../propTypes';
import { formatForint } from '../../../../../../utils/formatters';
import { getHumanReadableProductType } from '../../../../../products/components/Products/products.utils';
/**
 * @see https://github.com/gregnb/mui-datatables
 */
function MuiDataTable({ state, options: optionsProp }) {
  const { title, columns, data, hasMore, loading } = state;
  const options = useOptions(optionsProp, hasMore, data.length, loading);

  const findOrderById = (orders, id) => {
    return orders.filter(order => order.id === id);
  };

  const mapTicketDataToDisplay = ticket => {
    return {
      id: ticket?.ticketNumber2ndGen || ticket.ticketNumber,
      name: `Ticket for event: ${ticket.event.name}`,
      type: ticket.ticketType.name,
      amount: formatForint(ticket.price),
      customerName: ticket.customer.fullName,
      customerEmail: ticket.customer.email,
    };
  };

  const mapCardDataToDisplay = card => {
    return {
      id: card.cardNumber,
      name: 'Erasmus Card',
      type: card.type.name,
      amount: formatForint(card.type.price),
      customerName: card.customer.fullName,
      customerEmail: card.customer.email,
    };
  };

  const mapProductDataToDisplay = product => {
    console.log({ product });
    return {
      name: product.name,
      type: getHumanReadableProductType(product.type),
      amount: formatForint(product.price),
      customerName: product?.customer?.fullName || "",
      customerEmail: product?.customer?.email || "",
    };
  };

  const renderOrderDetailsForExpandedRow = rowData => {
    const expandedOrder = findOrderById(state.data, rowData[0]);
    const tickets = expandedOrder[0].tickets.map(mapTicketDataToDisplay);
    const cards = expandedOrder[0].elbCards.map(mapCardDataToDisplay);
    const products = expandedOrder[0].products.map(mapProductDataToDisplay);
    const items = [...tickets, ...cards];
    const colSpan = rowData.length + 1;

    const displayItemsTable = items.length > 0 && products.length === 0;
    const displayProductsTable = products.length > 0 || (products.length > 0 && items.length > 0);

    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          {displayItemsTable && (
            <>
              <TableContainer component={Paper} style={{ margin: '10px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item name</TableCell>
                      <TableCell>Item type</TableCell>
                      <TableCell>Ticket or ELB card ID</TableCell>
                      <TableCell>Customer name</TableCell>
                      <TableCell>Customer email</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map(item => {
                      return (
                        <TableRow>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>{item.id}</TableCell>
                          <TableCell>{item.customerName}</TableCell>
                          <TableCell>{item.customerEmail}</TableCell>
                          <TableCell>{item.amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {displayProductsTable && (
            <>
              <TableContainer component={Paper} style={{ margin: '10px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product type</TableCell>
                      <TableCell>Product name</TableCell>
                      <TableCell>Customer name</TableCell>
                      <TableCell>Customer email</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map(product => {
                      return (
                        <TableRow>
                          <TableCell>{product.type}</TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>{product.customerName}</TableCell>
                          <TableCell>{product.customerEmail}</TableCell>
                          <TableCell>{product.amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleCustomOrderTableOptions = {
    rowsExpanded: state.title === 'Orders List' ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] : [],
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return renderOrderDetailsForExpandedRow(rowData, rowMeta);
    },
  };

  const mapOptionsToTable = (options, tableTitle) => {
    switch (tableTitle) {
      case 'Orders List':
        return { ...options, ...handleCustomOrderTableOptions };
      case 'Payment List':
        return { ...options, ...handleCustomOrderTableOptions };
      default:
        return options;
    }
  };

  return (
    <MUIDataTable
      title={
        <Typography variant="h6">
          {title}
          {loading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
          )}
        </Typography>
      }
      columns={columns}
      data={data}
      options={mapOptionsToTable(options, state.title)}
    />
  );
}

MuiDataTable.propTypes = {
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  options: TableTypes.muiDataTableOptions.isRequired,
};

export default MuiDataTable;
