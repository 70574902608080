import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormLabel } from '@material-ui/core';
import { DatePicker, DateTimePicker, TimePicker } from '@material-ui/pickers';
import { startOfDay, endOfDay } from 'date-fns';

import * as Types from '../../../../../../utils/propTypes';
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from '../../../../../../utils/formatters';

const components = {
  [DATE_TIME_FORMAT]: DateTimePicker,
  [DATE_FORMAT]: DatePicker,
  [TIME_FORMAT]: TimePicker,
};

function TableDateTimeRangeFilter({ column, filters, index, onChange, format = DATE_TIME_FORMAT }) {
  function handleChange(date, filterIndex) {
    let correctedDate = date;

    if (format === DATE_FORMAT) {
      // If `DatePicker` is used,
      // select the start of the day for the first filter value,
      // and end of the day for the second filter value
      correctedDate = filterIndex === 0 ? startOfDay(date) : endOfDay(date);
    }

    // eslint-disable-next-line no-param-reassign
    filters[filterIndex] = correctedDate;
    onChange(filters, index, column);
  }

  const commonProps = useMemo(
    () => ({
      autoOk: true,
      clearable: true,
      ampm: false,
      format,
    }),
    [format],
  );

  const Picker = components[format];

  return (
    <div>
      <FormLabel>{column.label}</FormLabel>
      <FormGroup row>
        <Picker
          {...commonProps}
          label="After"
          value={filters[0] || null}
          onChange={date => handleChange(date, 0)}
          style={{ width: '45%', marginRight: '5%' }}
        />
        <Picker
          {...commonProps}
          label="Before"
          value={filters[1] || null}
          onChange={date => handleChange(date, 1)}
          style={{ width: '45%' }}
        />
      </FormGroup>
    </div>
  );
}

TableDateTimeRangeFilter.propTypes = {
  column: Types.tableColumn.isRequired,
  filters: PropTypes.arrayOf(Types.date).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
};

export default TableDateTimeRangeFilter;
