import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ProductImageUploader from '../ProductImageUploader/ProductImageUploader.component';
import EventSummary from '../EventSummary';
import { PRODUCTS_API_AXIOS_INSTANCE, PRODUCTS_API_ENDPOINTS } from '../../products.constants';
import { useSnackbar } from '../../../lib/hooks';

function CreateOrEditProduct({
  error,
  isError,
  isLoading,
  mutate,
  eventId,
  initialType,
  isEditing,
  initialProductData,
}) {
  // Selected product image preview
  const [previewImage, setPreviewImage] = useState(null);
  // Store the selected file as base64 for preview and upload
  const [base64File, setBase64File] = useState(null);
  const [, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    name,
    description,
    price,
    type,
    isWebshopPurchasable,
    includedInOrderEmail,
    isMembershipGranted,
    membershipExpiresInDays,
    imageUrl,
    eventId: initialEventId,
  } = initialProductData || {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name ? name : '',
      description: description ? description : '',
      price: price ? price : '',
      type: initialType ? initialType : type ? type : '',
      isWebshopPurchasable: isWebshopPurchasable === true,
      includedInOrderEmail: includedInOrderEmail === true,
      isMembershipGranted: isMembershipGranted === true,
      membershipExpiresInDays: membershipExpiresInDays ? membershipExpiresInDays : undefined,
      imageUrl: imageUrl || undefined,
      eventId: eventId ? eventId : initialEventId ? initialEventId : undefined,
    },
    validationSchema: yup.object({
      name: yup.string('Enter the name of the product').required('Product name is required'),
      description: yup
        .string('Add a description for the product')
        .required('Product description is required'),
      price: yup
        .number('Enter the price of your product')
        .required('Product price is required')
        .positive(),
      type: yup.string().required('Select the type of the product'),
      isWebshopPurchasable: yup.bool().optional(),
      includedInOrderEmail: yup.bool().optional(),
      isMembershipGranted: yup.bool().optional(),
      membershipExpiresInDays: yup.number().when('isMembershipGranted', {
        is: true,
        then: yup
          .number()
          .required('When above checkbox is check you should also add expiration in days.')
          .positive(),
      }),
      imageUrl: yup
        .string()
        .url()
        .nullable()
        .optional(),
      eventId: yup
        .string()
        .nullable()
        .optional(),
    }),
    onSubmit: async (values, actions) => {
      console.log('values before mutation: ', { values });
      const uploadedImageUrl = await uploadImage();
      const latestFormikValues = formik.values;
      console.log('latestFormikValues after uploadImage(): ', { latestFormikValues });
      mutate({
        ...values,
        imageUrl: uploadedImageUrl,
      });
      actions.resetForm();
    },
  });

  const onImageUploaded = url => {
    console.log('onImageUploaded', { url });
    formik.setFieldValue('imageUrl', url);
    console.log('onImageUploaded', { values: formik.values });
  };

  const uploadImage = async () => {
    try {
      // Upload the image to the Products API
      setIsLoading(true);
      console.log({ base64File });
      const token = localStorage.getItem('token');
      const imageUploadResponse = await PRODUCTS_API_AXIOS_INSTANCE.post(
        `/${PRODUCTS_API_ENDPOINTS.UPLOAD_PRODUCT_IMAGE_ENDPOINT}`,
        {
          base64Image: base64File,
        },
        { headers: { Authorization: token } },
      );
      // Handle image upload response
      if (imageUploadResponse?.data?.url) {
        onImageUploaded(imageUploadResponse.data.url);
        console.log({
          formikImage: formik.values.imageUrl,
          uploadedImage: imageUploadResponse.data.url,
        });
        enqueueSnackbar('Image uploaded!', 'success', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });

        // Return the URL of the uploaded image
        return imageUploadResponse.data.url;
      }
    } catch (error) {
      console.error('uploadImage error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* {isLoading && <FillerSpinner />} */}
      {isError && (
        <>
          <strong>An error occured during creating the product: {error?.message}</strong>
        </>
      )}
      <Typography variant="h3">{isEditing ? 'Edit Product' : 'Create Product'}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box
              marginBottom={'8px'}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
            >
              {previewImage || imageUrl ? (
                <img src={previewImage || imageUrl} alt="New product" width={'75%'} />
              ) : (
                <Typography variant="body1">No product image</Typography>
              )}
            </Box>
            <Box>
              <ProductImageUploader
                onImagePreviewLoaded={image => setPreviewImage(image)}
                setBase64FileToSave={setBase64File}
              //onImageUploaded={url => formik.setFieldValue('imageUrl', url)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="name"
                  name="name"
                  label="Product Name*"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="description"
                  name="description"
                  label="Product Description*"
                  type="text"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ margin: '8px 0px', alignSelf: 'center' }}
                  id="price"
                  name="price"
                  label="Product Price*"
                  type="number"
                  min="0"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>
              <Grid item>
                <FormControl
                  variant="outlined"
                  style={{
                    width: '206px',
                    display: initialType ? 'none' : 'inline-flex',
                    alignSelf: 'center',
                    margin: '8px 0px',
                  }}
                >
                  <InputLabel>Product Type*</InputLabel>
                  <Select
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    <MenuItem value={'elb-card'}>ELB Card</MenuItem>
                    <MenuItem value={'ticket'}>Ticket</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ alignSelf: 'center', textAlign: 'left' }}
                  control={
                    <Checkbox
                      checked={formik.values.isWebshopPurchasable}
                      value={formik.values.isWebshopPurchasable}
                      onChange={formik.handleChange}
                      name="isWebshopPurchasable"
                      id="isWebshopPurchasable"
                      color="primary"
                    />
                  }
                  label="Is it purchasable in webshop?"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ alignSelf: 'center', textAlign: 'left' }}
                  control={
                    <Checkbox
                      checked={formik.values.includedInOrderEmail}
                      value={formik.values.includedInOrderEmail}
                      onChange={formik.handleChange}
                      name="includedInOrderEmail"
                      id="includedInOrderEmail"
                      color="primary"
                    />
                  }
                  label="Should it be included in order email?"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <FormControlLabel
                      style={{ alignSelf: 'center', textAlign: 'left' }}
                      control={
                        <Checkbox
                          checked={formik.values.isMembershipGranted}
                          value={formik.values.isMembershipGranted}
                          onChange={formik.handleChange}
                          name="isMembershipGranted"
                          id="isMembershipGranted"
                          color="primary"
                        />
                      }
                      label="Is membership granted with product?"
                    />
                  </Grid>
                  {formik.values.isMembershipGranted && (
                    <Grid item>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ margin: '8px', alignSelf: 'center' }}
                        id="membershipExpiresInDays"
                        name="membershipExpiresInDays"
                        label="Membership expiration in days*"
                        type="number"
                        min="0"
                        value={formik.values.membershipExpiresInDays}
                        onChange={formik.handleChange}
                        error={formik.touched.membershipExpiresInDays && Boolean(formik.errors.membershipExpiresInDays)}
                        helperText={formik.touched.membershipExpiresInDays && formik.errors.membershipExpiresInDays}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  id="eventId"
                  name="eventId"
                  type="hidden"
                  value={formik.values.eventId}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={(formik.isValid && !formik.dirty) || isLoading}
                  >
                    Save
                    {isLoading && <CircularProgress size={18} style={{ marginLeft: '8px' }} />}
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                {isEditing && initialEventId && (
                  <EventSummary eventId={initialEventId} isEditing={true} />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateOrEditProduct;
