import React from 'react';
import * as PropTypes from 'prop-types';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';

function ELBCardTypeFormFields({ edit = false }) {
  return (
    <>
      <FTextField
        fullWidth
        required
        autoFocus
        margin="normal"
        type="text"
        variant="outlined"
        name="name"
        label="Name"
      />

      {!edit && (
        <FTextField
          fullWidth
          margin="normal"
          variant="outlined"
          required
          inputProps={{ min: 0 }}
          name="price"
          label="Price"
          type="number"
        />
      )}

      <FFormError />
    </>
  );
}

ELBCardTypeFormFields.propTypes = {
  edit: PropTypes.bool,
};

export default ELBCardTypeFormFields;
