import React from 'react';
import { Button } from '@material-ui/core';

import { useSnackbar } from '../../../lib/hooks';

function InactiveUserWithBalance() {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      onClick={() => enqueueSnackbar("User with non-zero balance can't be inactivated!", 'error')}
    >
      Inactivate User
    </Button>
  );
}

export default InactiveUserWithBalance;
