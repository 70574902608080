import { useMutation } from '@apollo/react-hooks';

import Privileges from '../../../utils/access-control/privileges.json';

import { REPORT_BALANCE } from '../graphql';

import { useCheckPrivileges } from '../../lib/hooks';
import { GET_USER, GET_PROFILE } from '../../users/graphql';

export default function useReportBalance(id) {
  const [anyAuditLogAccess, anyOrderAccess] = useCheckPrivileges(
    Privileges.READ_ANY_AUDIT_LOG,
    Privileges.READ_ANY_ORDER,
  );
  const query = {
    query: anyAuditLogAccess && anyOrderAccess ? GET_USER : GET_PROFILE,
    variables: { id },
  };

  const [reportBalance, result] = useMutation(REPORT_BALANCE, {
    awaitRefetchQueries: true,
    refetchQueries: [query],
  });

  async function simpleReportBalance(values) {
    await reportBalance({ variables: { data: values } });
  }

  return [simpleReportBalance, result];
}
