import React from 'react';
import PropTypes from 'prop-types';

import { RemoveItemFromOrder } from '..';

function RemoveElbCardFromOrder({ id }) {
  return <RemoveItemFromOrder type="elbCard" id={id} />;
}

RemoveElbCardFromOrder.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RemoveElbCardFromOrder;
