import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { Toolbar } from '../../../common/components';
import { CreateAccount } from '..';

function AccountsToolbar({ refetchQueries }) {
  return (
    <Toolbar>
      <CreateAccount refetchQueries={refetchQueries} />
    </Toolbar>
  );
}

AccountsToolbar.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default AccountsToolbar;
