import React from 'react';

import {
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';

function ReportBalanceFields() {
  return (
    <>
      <FTextField
        name="amount"
        required
        type="number"
        fullWidth
        variant="outlined"
        label="Amount"
        margin="normal"
      />

      <FFormError />
    </>
  );
}

export default ReportBalanceFields;
