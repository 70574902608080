import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { CreateCustomer } from '..';

function CustomersToolbar({ refetchQueries }) {
  const access = useCheckPrivilege(Privileges.CREATE_CUSTOMER);
  if (!access) return null;
  return (
    <Toolbar>
      <CreateCustomer refetchQueries={refetchQueries} />
    </Toolbar>
  );
}

CustomersToolbar.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CustomersToolbar;
