import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { MutateEvent } from '..';

function UpdateEvent({ event }) {
  return <MutateEvent event={event} />;
}

UpdateEvent.propTypes = {
  event: Types.event.isRequired,
};

export default UpdateEvent;
