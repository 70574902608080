import React from 'react';

import CreateCardReading from '../CreateEventChecking';
import { Grid } from '@material-ui/core';

function EventCheckingToolbar({ cardNumber, setCardNumber, mutate, debouncedCardNumber, eventId }) {
  return (
    <div>
      <Grid
        style={{ display: 'flex', alignItems: 'center' }}
        container
        direction="row"
        justify="center"
      >
        <CreateCardReading
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          mutate={mutate}
          debouncedCardNumber={debouncedCardNumber}
          eventId={eventId}
        />
      </Grid>
    </div>
  );
}

export default EventCheckingToolbar;
