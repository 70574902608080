import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

function CustomDialog({ children, ...rest }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog fullScreen={fullScreen} {...rest}>
      {children}
    </Dialog>
  );
}

CustomDialog.propTypes = {
  children: PropTypes.node,
};

export default CustomDialog;
