import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { STORE_CURRENT_USER_ID, GET_CURRENT_USER } from '../graphql';
import useGetCurrentUser from './useGetCurrentUser';

export default function useStoreCurrentUser() {
  const { data } = useGetCurrentUser();
  const [store] = useMutation(STORE_CURRENT_USER_ID);

  const storeUser = useCallback(
    async user => {
      const { id, token } = user;
      localStorage.setItem('token', token);
      await store({
        variables: { id },
        refetchQueries: [{ query: GET_CURRENT_USER }],
        awaitRefetchQueries: true,
      });
    },
    [store],
  );

  return [storeUser, Boolean(data && data.currentUser)];
}
