import React from 'react';

import * as Types from '../../../../utils/propTypes';

import { GET_EVENT } from '../../graphql';

import { Document, WithDocumentQuery } from '../../../common/components';
import { TicketsTable } from '../../../tickets/components';
import { EventToolbar, EventInfoCard, TicketTypesTable } from '..';
import { EventProducts } from '../../../products/components';
import globalApplicationConfiguration from '../../../../config/config';

function Event({
  match: {
    params: { id },
  },
}) {
  const featureFlagProductsDisplayTicketProductsTableOnEventPage =
    globalApplicationConfiguration.featureFlags.products.displayTicketProductsTableOnEventPage;
  return (
    <WithDocumentQuery id={id} query={GET_EVENT}>
      {(event, tickets) => (
        <Document
          toolbar={<EventToolbar event={event} />}
          infoCard={<EventInfoCard event={event} />}
          inlineTable={<TicketTypesTable event={event} inline />}
          table={<TicketsTable tickets={tickets} event={event} />}
          secondTable={
            featureFlagProductsDisplayTicketProductsTableOnEventPage && (
              <EventProducts eventId={event.id} />
            )
          }
        />
      )}
    </WithDocumentQuery>
  );
}

Event.propTypes = {
  match: Types.idMatch.isRequired,
};

export default Event;
