import React from 'react';

import * as Types from '../../../../utils/propTypes';
import Privileges from '../../../../utils/access-control/privileges.json';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Toolbar } from '../../../common/components';
import { CreateUser } from '..';

function UsersToolbar({ refetchQueries }) {
  const access = useCheckPrivilege(Privileges.CREATE_ANY_USER);
  if (!access) return null;
  return (
    <Toolbar>
      <CreateUser refetchQueries={refetchQueries} />
    </Toolbar>
  );
}

UsersToolbar.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default UsersToolbar;
