import { useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { VERIFY_AUTHENTICATION_TOKEN } from '../graphql';

import { useEffectOnSuccess } from '../../lib/hooks';
import useStoreCurrentUser from './useStoreCurrentUser';

export default function useRememberMe() {
  const { current: token } = useRef(localStorage.getItem('token'));

  const [storeUser, isUserInCache] = useStoreCurrentUser();

  const [verify, result] = useLazyQuery(VERIFY_AUTHENTICATION_TOKEN, {
    variables: { token },
  });
  const { called, loading, data, error } = result;
  const success = Boolean(
    token && called && !loading && !error && data?.verifyAuthenticationToken?.verified,
  );
  const isVerificationLoading = Boolean(
    loading || (token && !called) || (success && !isUserInCache),
  );

  useEffect(() => {
    if (token && !called) {
      verify();
    }
  }, [token, called, verify]);

  useEffectOnSuccess(result, () => {
    const {
      verifyAuthenticationToken: { verified, user },
    } = data;

    if (verified) {
      storeUser(user);
    }
  });

  /**
   * Verification is loading if the request is loading or the request had been successful,
   * but the user hasn't been stored in the cache yet.
   */
  return {
    loading: isVerificationLoading,
    verified: Boolean(data && data.verified),
  };
}
