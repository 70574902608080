import React from 'react';

// import * as Types from '../../../../utils/propTypes';

import { Toolbar } from '../../../common/components';

function CustomerToolbar(/* { customer } */) {
  return <Toolbar>{/* */}</Toolbar>;
}

CustomerToolbar.propTypes = {
  // customer: Types.customer.isRequired,
};

export default CustomerToolbar;
