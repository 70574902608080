import React from 'react';

import * as Types from '../../../../utils/propTypes';
import { createElbCardType as customerSchema } from '../../../../utils/validationSchemas';

import { useCreateElbCardType } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import { ELBCardTypeFormFields } from '..';

function CreateELBCardType({ refetchQueries }) {
  const dialogProps = {
    title: 'Add ELB Card Type',
    submitButtonText: 'Add ELB Card Type',
    fields: <ELBCardTypeFormFields />,
  };

  const [create] = useCreateElbCardType(refetchQueries ? { refetchQueries } : undefined);
  const initialValues = {
    name: '',
    price: 0,
  };
  const formikProps = {
    initialValues,
    validationSchema: customerSchema,
    onSubmit: create,
  };

  const snackbarProps = {
    successText: 'ELB Card type was successfully created',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText="Add ELB Card Type"
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

CreateELBCardType.propTypes = {
  refetchQueries: Types.refetchQueries,
};

export default CreateELBCardType;
