import React from 'react';
import * as PropTypes from 'prop-types';

import { useInactivateAccount } from '../../hooks';

import { ActionButtonWithConfirmation } from '../../../common/components';

function InactivateAccountWithZeroBalance({ id, name }) {
  const [inactivate, result] = useInactivateAccount(id);
  return (
    <ActionButtonWithConfirmation
      action={inactivate}
      result={result}
      title={`Are you sure you want to inactivate "${name}"?`}
      description={`"${name}" won't be available for future transfers.`}
      successMessage="Account has been inactivated!"
      buttonText="Inactivate"
    />
  );
}

InactivateAccountWithZeroBalance.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default InactivateAccountWithZeroBalance;
