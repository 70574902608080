import { useMutation } from '@apollo/react-hooks';

import Privileges from '../../../utils/access-control/privileges.json';

import { REPORT_BALANCE_FOR_USER } from '../graphql';

import { useCheckPrivileges } from '../../lib/hooks';
import { GET_USER, GET_PROFILE } from '../../users/graphql';

export default function useReportBalanceForUser(id) {
  const [anyAuditLogAccess, anyOrderAccess] = useCheckPrivileges(
    Privileges.READ_ANY_AUDIT_LOG,
    Privileges.READ_ANY_ORDER,
  );
  const query = {
    query: anyAuditLogAccess && anyOrderAccess ? GET_USER : GET_PROFILE,
    variables: { id },
  };

  const [reportBalanceForUser, result] = useMutation(REPORT_BALANCE_FOR_USER, {
    awaitRefetchQueries: true,
    refetchQueries: [query],
  });

  async function simpleReportBalanceForUser(values, id) {
    await reportBalanceForUser({ variables: { data: {...values, id } } });
  }

  return [simpleReportBalanceForUser, result];
}