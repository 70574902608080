import React, { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import {
  Fields,
  FormikTextField as FTextField,
  FormikFormError as FFormError,
} from '../../../lib/components';
import { FormikCustomerAutocomplete as FCustomerAutocomplete } from '../../../customers/components';
import {
  FormikEventAutocomplete as FEventAutocomplete,
  FormikTicketTypeSelect as FTicketTypeSelect,
} from '../../../events/components';

function getId(obj) {
  return obj ? obj.id : '';
}

function TicketFields() {
  const {
    values: { event },
    setFieldValue,
  } = useFormikContext();

  const isExternal = useMemo(() => (event ? event.isExternal : false), [getId(event)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEventChange = useCallback(
    (_, newEvent, handleChange) => {
      setFieldValue('ticketType', '');
      if (!newEvent.isExternal) {
        setFieldValue('ticketNumber', '');
      }
      handleChange();
    },
    [setFieldValue],
  );

  const handleCustomerChange = useCallback(
    (_, newCustomer, handleChange) => {
      const activeElbCard = newCustomer && newCustomer.activeElbCard;
      if (!activeElbCard) {
        setFieldValue('withELBCard', false);
      }
      handleChange();
    },
    [setFieldValue],
  );

  return (
    <Fields>
      <FEventAutocomplete
        name="event"
        onChange={handleEventChange}
        InputProps={{
          required: true,
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />

      <FTicketTypeSelect
        event={event}
        name="ticketType"
        required
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FCustomerAutocomplete
        name="customer"
        onChange={handleCustomerChange}
        toggleElbCard
        InputProps={{
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
      />

      {isExternal && (
        <FTextField
          name="ticketNumber"
          label="Ticket Number"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}

      <FFormError />
    </Fields>
  );
}

export default TicketFields;
