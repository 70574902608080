import gql from 'graphql-tag';

import { PAGINATION_FRAGMENT } from '../lib/graphql';

export const ELB_CARD_TYPE_FRAGMENT = gql`
  fragment ELBCardTypeFragment on ELBCardType {
    id
    name
    price
    forSale
    createdAt
    soldAmount
    createdBy {
      id
      fullName
    }
  }
`;

export const GET_ELB_CARD_TYPES = gql`
  query GetELBCardTypes($options: OptionsInput) {
    elbCardTypes(options: $options) @connection(key: "elbCardTypes") {
      pagination {
        ...PaginationFragment
      }
      entries {
        ...ELBCardTypeFragment
      }
    }
  }
  ${PAGINATION_FRAGMENT}
  ${ELB_CARD_TYPE_FRAGMENT}
`;

export const GET_ELB_CARD_TYPE = gql`
  query GetELBCardType($id: String!) {
    elbCardType(id: $id) {
      ...ELBCardTypeFragment
    }
  }
  ${ELB_CARD_TYPE_FRAGMENT}
`;

export const CREATE_ELB_CARD_TYPE = gql`
  mutation CreateELBCardType($data: CreateELBCardTypeInput!) {
    createELBCardType(data: $data) {
      ...ELBCardTypeFragment
    }
  }
  ${ELB_CARD_TYPE_FRAGMENT}
`;

export const UPDATE_ELB_CARD_TYPE = gql`
  mutation UpdateELBCardType($data: UpdateELBCardTypeInput) {
    updateELBCardType(data: $data) {
      id
      name
    }
  }
`;

export const START_SALE_FOR_ELB_CARD_TYPE = gql`
  mutation StartSaleForELBCardType($id: String!) {
    startSaleForELBCardType(id: $id) {
      id
      forSale
    }
  }
`;

export const STOP_SALE_FOR_ELB_CARD_TYPE = gql`
  mutation StopSaleForELBCardType($id: String!) {
    stopSaleForELBCardType(id: $id) {
      id
      forSale
    }
  }
`;
