import React from 'react';
import { isEmpty } from 'lodash';

import * as Types from '../../../../utils/propTypes';
import {
  terminal as terminalSchema,
  updateTerminal as updateTerminalSchema,
} from '../../../../utils/validationSchemas';

import { useCreateTerminal, useUpdateTerminal } from '../../hooks';

import { ActionButtonWithDialogForm } from '../../../common/components';
import TerminalFields from '../TerminalFields/TerminalFields';

function MutateTerminal({ terminal = {}, refetchQueries }) {
  const edit = !isEmpty(terminal);
  const { id, name } = terminal;

  const [create] = useCreateTerminal(refetchQueries ? { refetchQueries } : undefined);
  const [update] = useUpdateTerminal(id);

  const initialValues = {
    name: name || '',
    account: terminal.account || '',
  };
  const handleSubmit = edit
    ? async values => {
        update({ account: values.account });
      }
    : async values => {
        create(values);
      };
  const formikProps = {
    initialValues,
    validationSchema: edit ? updateTerminalSchema : terminalSchema,
    onSubmit: async ({ account: { id: accountId }, ...otherValues }) => {
      handleSubmit({
        account: accountId,
        ...otherValues,
      });
    },
  };

  const dialogProps = {
    title: edit ? 'Edit Terminal' : 'Add Terminal',
    submitButtonText: edit ? 'Edit Terminal' : 'Add Terminal',
    fields: <TerminalFields edit={edit} />,
  };

  const snackbarProps = {
    successText: edit ? 'Terminal has been edited!' : 'Terminal has been created!',
  };

  return (
    <ActionButtonWithDialogForm
      actionButtonText={edit ? 'Edit Terminal' : 'Add Terminal'}
      dialogProps={dialogProps}
      formikProps={formikProps}
      snackbarProps={snackbarProps}
    />
  );
}

MutateTerminal.propTypes = {
  billingAccount: Types.billingAccount,
  refetchQueries: Types.refetchQueries,
};

export default MutateTerminal;
