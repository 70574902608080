import React from 'react';

import { FormikSelect as FSelect } from '../../../lib/components';

function GlobalBillingFields() {
  const items = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];
  return (
    <>
      <FSelect
        name="manualBilling"
        required
        options={items}
        fullWidth
        label="Manual Billing"
        variant="outlined"
        margin="normal"
      />
    </>
  );
}

export default GlobalBillingFields;
