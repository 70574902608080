import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStartPaymentRefund } from '../../hooks/useStartPaymentRefund';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSnackbar } from '../../../lib/hooks';

function PaymentRefundDialog({ publicPaymentId, open, setOpen, refetch }) {
  const { isError, isLoading, isSuccess, mutate } = useStartPaymentRefund(publicPaymentId);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Payment was successfully refunded!', 'success', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
      setOpen(false);
      refetch();
    }
  }, [isSuccess, enqueueSnackbar, setOpen, refetch]);
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        <Typography variant="h5">Refund payment</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Are you sure you want to start a refund process for the payment? By clicking the{' '}
            <b>CONFIRM</b> button you will start the refund for the customer! This process can{' '}
            <b>NOT</b> be reversed!
          </Typography>
          {isLoading && (
            <CircularProgress
              style={{ alignSelf: 'center' }}
              size={24}
              thickness={4}
              color="primary"
            />
          )}
          {isError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <Typography>An error occured during the refund process</Typography>
            </Alert>
          )}
        </DialogContentText>
        <DialogActions>
          <Button
            style={{
              backgroundColor: '#1b5e20',
              color: 'white',
            }}
            onClick={() => {
              setOpen(false);
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: '#d32f2f',
              color: 'white',
            }}
            onClick={() => {
              mutate(publicPaymentId);
            }}
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default PaymentRefundDialog;
