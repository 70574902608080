import React from 'react';

import { InfoCard } from '../../../common/components';
import * as Types from '../../../../utils/propTypes';
import { formatForint, formatDateTime } from '../../../../utils/formatters';
import routes from '../../../../utils/routes';

function BillingAccountInfoCard({
  billingAccount: { createdAt, createdBy, publicKey, privateKey, name, active },
}) {
  const rows = [
    {
      label: 'Status',
      value: active ? 'Active' : 'Inactive',
    },
    {
      label: 'Created by',
      value: routes.user.createLink(createdBy),
    },
    {
      label: 'Created at',
      value: formatDateTime(createdAt),
    },
    {
      label: 'Public Key',
      value: publicKey,
    },
    {
      label: 'Private Key',
      value: privateKey,
    },
    {
      label: 'TBD Billed Amount',
      value: formatForint(0),
    },
  ];
  return <InfoCard title={name} rows={rows} />;
}

BillingAccountInfoCard.propTypes = {
  billingAccount: Types.billingAccount.isRequired,
};

export default BillingAccountInfoCard;
