import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import * as Types from '../../../../utils/propTypes';

import { FormikSelect as FSelect } from '../../../lib/components';

function FormikTicketTypeSelect({ name, event = undefined, label = 'Ticket Type', ...rest }) {
  const options = useMemo(
    () =>
      event
        ? event.ticketTypes
            .filter(({ forSale }) => forSale)
            .map(({ id: value, name: ttName }) => ({ value, label: ttName }))
        : [],
    [event],
  );

  return <FSelect name={name} disabled={!event} options={options} label={label} {...rest} />;
}

FormikTicketTypeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  event: Types.event,
  label: PropTypes.string,
};

export default FormikTicketTypeSelect;
