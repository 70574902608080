import currentUser from './currentUser';
import openOrder from './openOrder';

export default {
  Query: {
    ...currentUser.Query,
    ...openOrder.Query,
  },
  Mutation: {
    ...currentUser.Mutation,
    ...openOrder.Mutation,
  },
};
