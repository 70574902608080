import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Debug } from '..';

function Fields({ children, values = false, errors = false, touched = false }) {
  const { values: valuesObject, errors: errorsObject, touched: touchedObject } = useFormikContext();

  const debug = {
    values: values ? valuesObject : undefined,
    errors: errors ? errorsObject : undefined,
    touched: touched ? touchedObject : undefined,
  };

  return (
    <>
      {(values || errors || touched) && <Debug>{debug}</Debug>}
      {children}
    </>
  );
}

Fields.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  values: PropTypes.bool,
  errors: PropTypes.bool,
  touched: PropTypes.bool,
};

export default Fields;
