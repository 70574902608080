import { COMPLETE__PRODUCT_ORDER, GET_OPEN_ORDER } from '../graphql';

import { useCreateMutation } from '../../lib/hooks';

export default function useCompleteProductOrder() {
    return useCreateMutation(COMPLETE__PRODUCT_ORDER, {
        refetchQueries: [{ query: GET_OPEN_ORDER }],
        awaitRefetchQueries: true,
    });
}

export function useCompleteProductOrderWithPaidAmounts(options) {
    return useCreateMutation(COMPLETE__PRODUCT_ORDER, { variables: options });
}
