import gql from 'graphql-tag';

export const SETTINGS_FRAGMENT = gql`
  fragment SettingsFragment on Settings {
    id
    elbCard {
      defaultExpirationTime
      billingAccount {
        id
        name
      }
      formId
    }
    billing {
      manualBilling
    }
  }
`;

export const GET_SETTINGS = gql`
  query GetSettings {
    settings {
      ...SettingsFragment
    }
  }
  ${SETTINGS_FRAGMENT}
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($data: UpdateSettingsInput) {
    updateSettings(data: $data) {
      ...SettingsFragment
    }
  }
  ${SETTINGS_FRAGMENT}
`;
