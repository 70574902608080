import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { isNil, merge } from 'lodash';

import { FormikFieldWithError } from '..';

function coerceValue(from, to = '') {
  return isNil(from) ? to : from;
}

function FormikAutocomplete({
  name,
  options,
  getOptionLabel = undefined,
  noOptionsText = undefined,
  filterOptions = undefined,
  onChange = undefined,
  groupBy = undefined,
  loading = false,
  isLoading = false,
  disabled = false,
  autoHighlight = true,
  disableOpenOnFocus = true,
  InputProps = {},
  ...rest
}) {
  const { setFieldValue, setFieldTouched, validateField } = useFormikContext();

  const setValue = useCallback(value => setFieldValue(name, value), [name, setFieldValue]);
  const handleChange = useCallback(
    (event, value) => {
      const coercedValue = coerceValue(value);
      if (onChange) {
        onChange(event, coercedValue, customValue =>
          setValue(coerceValue(customValue, coercedValue)),
        );
      } else {
        setValue(coercedValue);
      }
    },
    [onChange, setValue],
  );

  const handleClose = useCallback(() => {
    setFieldTouched(name, true, false);
    validateField(name);
  }, [name, setFieldTouched, validateField]);

  const wrappedGetOptionLabel = useCallback(
    getOptionLabel ? option => (option ? getOptionLabel(option) : '') : undefined,
    [getOptionLabel],
  );

  const renderInput = useCallback(
    (params, hasError, errorMessage) => {
      const mergedProps = merge(params, InputProps);
      return (
        <TextField
          {...mergedProps}
          InputProps={{
            ...mergedProps.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {mergedProps.InputProps.endAdornment}
              </>
            ),
          }}
          error={hasError}
          helperText={errorMessage}
        />
      );
    },
    [isLoading, InputProps],
  );

  return (
    <FormikFieldWithError name={name}>
      {({ field, hasError, errorMessage }) => (
        <Autocomplete
          name={field.name}
          value={field.value}
          options={options}
          groupBy={groupBy}
          filterOptions={filterOptions}
          getOptionLabel={wrappedGetOptionLabel}
          onChange={handleChange}
          onClose={handleClose}
          renderInput={params => renderInput(params, hasError, errorMessage)}
          loading={loading}
          loadingText="Loading..."
          noOptionsText={isLoading || loading ? "Loading..." : noOptionsText}
          disabled={disabled}
          autoHighlight={autoHighlight}
          disableOpenOnFocus={disableOpenOnFocus}
          {...rest}
        />
      )}
    </FormikFieldWithError>
  );
}

/* eslint-disable react/forbid-prop-types */
FormikAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  getOptionLabel: PropTypes.func,
  filterOptions: PropTypes.func,
  noOptionsText: PropTypes.node,
  onChange: PropTypes.func,
  groupBy: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  disableOpenOnFocus: PropTypes.bool,
  InputProps: PropTypes.object,
};
/* eslint-enable */

export default FormikAutocomplete;
